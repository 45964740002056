<div #toastContainer></div>
<app-modal *ngIf="showModal" [modalTitle]="cancelModalTitle" [modalContent]="cancelModalDescription"
  (closeModal)="handleCloseModal()" (confirmCancel)="handleConfirmCancel()">
  <p>{{ cancelModalDescription }}</p>
  <div modal-footer>
    <app-button [customClass]="'btn btn-secondary me-2'" [label]="'Go Back'" (click)="handleCloseModal()"></app-button>
    <app-button [customClass]="'btn btn-danger'" [label]="'Confirm'" (click)="handleConfirmCancel()"></app-button>
  </div>
</app-modal>

<form [formGroup]="siteSetupForm" (ngSubmit)="onSubmit()">
  <div class="row justify-content-center">
    <div class="site-form col-10 mt-4">
      <div class="d-flex flex-direction-column justify-content-between">
        <h3>{{ siteTitle }}</h3>
      </div>
      <!-- <app-spinner [isLoading]="isLoading"></app-spinner>*ngIf="!isLoading" -->
      <div class="col-12 row justify-content-center pt-6 pb-6 site-setup-form" >
        <div class="col-5">
          <app-select-dropdown formControlName="division" [label]="'Division'" [options]="divisionOptions"
            [placeholder]="'Select'" [formControl]="division" [tooltipContent]="division_tooltip"
            [validationMessages]="validationMessages.division" [id]="'division-dropdown'"></app-select-dropdown>
          <app-input-field formControlName="siteId" [label]="'Operating Unit (Site ID)'" [placeholder]="''"
            [formControl]="siteId" [validationPattern]="validationPatterns.siteId"
            [validationMessages]="validationMessages.siteId" [tooltipContent]="site_id_tooltip"
            (ngModelChange)="sanitizeInput($event, 'siteId')" [id]="'siteID-input'"></app-input-field>
          <app-input-field formControlName="siteName" [label]="'Description (Site Name)'" [placeholder]="''"
            [formControl]="siteName" [validationPattern]="validationPatterns.siteName"
            [validationMessages]="validationMessages.siteName" [tooltipContent]="site_name_tooltip"
            (ngModelChange)="sanitizeInput($event, 'siteName')" [id]="'siteName-input'"></app-input-field>
          <app-input-field formControlName="requesterId" [label]="'Requester ID'" [placeholder]="''"
            [formControl]="requesterId" [validationPattern]="validationPatterns.requesterId"
            [validationMessages]="validationMessages.requesterId" [tooltipContent]="requester_id_tooltip"
            (ngModelChange)="sanitizeInput($event, 'requesterId')" [id]="'requesterId-input'"></app-input-field>
          <app-select-dropdown formControlName="recountFrequency" [label]="'Inventory Mandatory Count Frequency(days)'"
            [options]="recountFrequency" [placeholder]="'Select'" [formControl]="recountFrequencyControl"
            [tooltipContent]="recount_frequency_tooltip" [validationMessages]="validationMessages.recountFrequency"
            aria-label="Default Select Input" [id]="'recountFrequency-dropdown'"></app-select-dropdown>
          <app-select-dropdown formControlName="status" [label]="'Status'" [options]="statusOptions"
            [placeholder]="'Select'" [formControl]="status" [tooltipContent]="site_status_tooltip"
            [validationMessages]="validationMessages.siteStatus" [id]="'status-dropdown'"></app-select-dropdown>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-start">
        <div class="col-3 mt-6">
          <div class="d-flex flex-direction-column justify-content-between">
            <div>
              <ng-container *ngIf="siteSetupForm.valid && !isLoading; else disabledButton">
                <app-button [customClass]="'btn btn-primary'" [label]="'Save Site'" (click)="onSubmit()"
                  [isDisabled]="submitting" [isLoading]="isLoading"></app-button>
              </ng-container>
              <ng-template #disabledButton>
                <app-button [customClass]="'btn btn-primary disabled'" [label]="'Save Site'"
                  [isDisabled]="true" [isLoading]="isLoading"></app-button>
              </ng-template>
            </div>
            <div>
              <app-button [customClass]="'btn btn-secondary'" [label]="'Cancel'" (click)="onCancelClick()"></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>