import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
// import { UserManagementService } from '../../services/api/user-management.service';
import { Observable, of } from 'rxjs';
import { User } from '../../model/user.model';
import { Router } from '@angular/router';
import { UserStatus } from '../../reusable/user-status.enum';
import { ListUserUtilService } from '../../services/list-user-util/list-user-util.service';

@Component({
  selector: 'app-user-management-table',
  templateUrl: './user-management-table.component.html',
  styleUrl: './user-management-table.component.css'
})
export class UserManagementTableComponent {
  usersDataObservable$!: Observable<any>;
  
  @Input() users: User[] = [];
  @Input() sortProperty: string = '';
  @Input() sortDirection: string = 'asc';
  @Output() sortChange = new EventEmitter<{ property: string; direction: string }>();
  @Output() userClicked = new EventEmitter<User>();
  totalItems = 0;
  pageSize = 15;
  currentPage = 1;
  usersForPagination: any[] = []; 

  property: string = '';
  direction: string = '';

  constructor(
    private router: Router,
    private listUserUtilService: ListUserUtilService
  ) {}

   ngOnInit() { 
    this.users = this.users || [];
      this.updatePagination();
  }
  ngOnChanges(changes: SimpleChanges) {
  if (changes['users'] && changes['users'].currentValue) {
    console.log('Users updated in UserTableComponent:', this.users);
    this.totalItems = this.users.length;
    this.updatePagination();
  } else {
    console.log('No users input or empty users array:', this.users);
  }

  if (changes['sortProperty'] || changes['sortDirection']) {
    this.sortUsers();
  }
}

updatePagination() {
  console.log('Before Pagination - Current Users:', this.users);
  this.usersForPagination = this.listUserUtilService.paginateUsers(this.users, this.pageSize, this.currentPage);
  console.log('Users for Current Page:', this.usersForPagination);
}

  sortUsers() {
  this.users = this.listUserUtilService.sortUsers(this.users, this.sortProperty, this.sortDirection);
  this.updatePagination();
}

  onSort(event: { property: string; direction: string }) {
    const { property, direction } = event;
    this.direction = event.direction;
    this.property = event.property;
    this.sortChange.emit({ property, direction });
    this.sortUsers();
  }
  

  onPageChanged(page: number) {
    this.currentPage = page;
    console.log('Current Page:', this.currentPage);
    this.updatePagination();
  }
  onUserClicked(user: User) {
  this.userClicked.emit(user);
}

//   updatePagination() {
//   this.usersForPagination = this.listUserUtilService.paginateUsers(this.users, this.pageSize, this.currentPage);
//   console.log('Users for Current Page:', this.usersForPagination);
// }


getRoleLabel(roleName: string | undefined): string {
  const roleMap: { [key: string]: string } = {
    USER: 'Site User',
    SUPERUSER: 'Super User',
    SITEMANAGER: 'Site Manager',
    DIRECTOR: 'Division Manager'
  };
  
  return roleName ? roleMap[roleName] || 'N/A' : 'N/A';
}
}
