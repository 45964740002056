import { Component, Inject, OnInit } from '@angular/core';
import { ReportService } from '../services/api/report.service';
import { Department, Site } from '../model/report.model';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, map, Observable, Subscription } from 'rxjs';
import { CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { AuthState } from '@okta/okta-auth-js';
import { DatePipe } from '@angular/common';
import { OpenporeportService } from '../services/openporeport/openporeport.service';
import { POReport } from '../reusable/po-report.enum';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage/token-storage.service';
@Component({
  selector: 'app-open-poreports',
  templateUrl: './open-poreports.component.html',
  styleUrl: './open-poreports.component.css',
  providers: [DatePipe]
})
export class OpenPOReportsComponent {
  sites: Site[] = [];
  departments: Department[] = []
  siteName: string = '';
  departmentName: string = '';
  email: string = "";
  public isAuthenticated$!: Observable<boolean>;
  authSubscription: Subscription | undefined;
  claims: { claim: string; value: CustomUserClaim | CustomUserClaim[]; }[] | undefined;
  loading: boolean = false;
  POReports: any[] = []
  isLoading!: boolean;
  isSiteSelected: boolean = false;
  sitesLoading!: boolean;
  isRouteChange: boolean = false;
  constructor(
    private router: Router,
    private TokenStorageService: TokenStorageService,
    private reportService: ReportService,
    private openporeportService: OpenporeportService,
    private oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private datePipe: DatePipe
  ) {

    this.isRouteChange = true;
    setTimeout(() => { this.isRouteChange = false; }, 1000)

  }

  async ngOnInit() {

    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    // this.getOpenPOReports()
    //this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
    // console.log('User authentication status:', isAuthenticated);
    //this.getFilters(this.email)
    let isAuthenticated = await this.oktaAuth.isAuthenticated();

    if (isAuthenticated) {
      const token = await this.oktaAuth.getAccessToken() || null;
      const userClaims = await this.oktaAuth.token.getUserInfo();
      this.claims = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
      this.email = userClaims['email'] || "";
      this.getFilters(this.email)
    } else {
      // User is not authenticated, perform a different action
      this.TokenStorageService.clearToken();
      this.TokenStorageService.clearUserData();
      this.router.navigate(['']);
    }
    //  });
  }

  getDepartmentsBySite(event: any) {
    this.siteName = event.code;
    let site = this.sites?.find((site: Site) => site.code === this.siteName);
    this.departments = site?.departments || [];
    this.isSiteSelected = false;
    this.departmentName = "";
    this.POReports = [];
  }



  getFilters(email: string) {
    console.log("reports-email", email);
    //email = "noman.mohammed@labcorp.com";
    this.sitesLoading = true;
    this.reportService.getReportFilterList(email).subscribe({
      next: (response) => {
        this.sitesLoading = false;
        console.log('report filter data:', response);
        this.sites = response?.siteAccess?.sites;
      },
      error: (error) => {
        this.sitesLoading = false;
      }
    });
  }

  convertToUTC(timestamp: string): string {
    const date = new Date(timestamp);
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss', 'UTC') ?? '';
  }

  getOpenPOReports() {
    // this.siteName = "CAH190"
    // this.departmentName = "1241";
    this.isSiteSelected = true;
    this.isLoading = true;
    this.POReports = [];
    this.openporeportService.getPOReports(this.siteName, this.departmentName)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          console.log('report table data:', response);
          if (response?.length > 0) {
            this.POReports = response.map((report: any) => {
              // let itemReceived = report?.items?.filter((line: any) =>
              //   line.status === POReport.FULLYRECEIVED || line.status === POReport.PARTIALLYRECEIVED)?.length;
              return {
                ...report,
                // itemReceived: itemReceived,
                // itemQuantity: report?.items?.length ?? 0,
                //modified: this.convertToUTC(report.modified)
                isExpand: false
              }
            });
          }
          else {
            this.POReports = []
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.POReports = []
        }
      });
  }


  onClear() {
    this.siteName = "";
    this.departmentName = "";
    this.departments = [];
    this.isSiteSelected = false;
  }

}
