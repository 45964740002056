<div class="container-xxl">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li
        class="breadcrumb-item"
        *ngFor="let breadcrumb of breadcrumbs; let last = last"
      >
        <ng-container *ngIf="!last">
          <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
        </ng-container>
        <ng-container *ngIf="last">
          <span>{{ breadcrumb.label }}</span>
        </ng-container>
      </li>
    </ol>
  </nav>
</div>
