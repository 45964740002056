import { Component, EventEmitter, Input, Output, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SiteSetupConstants } from '../../global/site-setup-constants';
import { FileValidationService } from '../../services/file-validation/file-validation.service';
import CSVFileValidator, { RowError, ValidatorConfig } from 'csv-file-validator';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() id!: string;
  @Input() controlName!: string;
  @Input() tooltipContent!: string;
  @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>();
  @Output() fileUploadStatus: EventEmitter<{ fileName: string, isValid: boolean, message: string }> = new EventEmitter();

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;

  fileControl!: FormControl;
  isTooltipOpen = false;
  selectedFile: File | null = null;

  toggleTooltip() {
    this.isTooltipOpen = !this.isTooltipOpen;
  }

  file_upload_tooltip = SiteSetupConstants.tooltipInfo.FILE_UPLOAD_TOOLTIP;
  fileUploadConstants = SiteSetupConstants.fileUpload;
  csvValidationErrors: RowError[] = [];

  constructor(private fb: FormBuilder, private fileValidationService: FileValidationService) {}

  ngOnInit(): void {
    this.fileControl = this.form.get(this.controlName) as FormControl;
    this.fileControl.setValidators([
      Validators.required,
      this.fileValidationService.validateFileType.bind(this.fileValidationService),
      this.fileValidationService.validateFileSize.bind(this.fileValidationService)
    ]);
    this.fileControl.updateValueAndValidity();
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.handleFileInput(file);
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    
    const dataTransfer = event.dataTransfer;
    if (dataTransfer) {
      const file: File = dataTransfer.files[0];
      this.handleFileInput(file);
    }
  }

  private resetState(): void {
    this.csvValidationErrors = [];
    this.fileControl.reset();
    this.selectedFile = null;
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  private handleFileInput(file: File): void {
    this.resetState();
    this.selectedFile = file;

    // Validate file type
    if (file.type !== 'text/csv') {
      this.fileUploadStatus.emit({
        fileName: file.name,
        isValid: false,
        message: 'Invalid file type. Only CSV files are allowed.'
      });
      this.fileControl.reset();
      this.selectedFile = null;
      return;
    }

    // Validate file size
    if (file.size > this.fileValidationService.maxFileSize) {
      this.fileUploadStatus.emit({
        fileName: file.name,
        isValid: false,
        message: 'File size exceeds 1MB'
      });
      this.fileControl.reset(); 
      this.selectedFile = null;
      return;
    }

    // Set form control value
    this.fileControl.setValue(file); 
    this.fileControl.updateValueAndValidity();
    this.fileSelected.emit(file);

    // Validate CSV file structure
    const requiredFields = ['Item ID', 'Description', 'Optimal Quantity', 'Ordering Quantity', 'UOM'];
    const optionalFields = ['Lot Tracked', 'Status', 'Image'];
    const config = {
      headersRowIndex: 1,
      headers: [
        ...requiredFields.map(field => ({
          name: field,
          inputName: field,
          required: true,
          requiredError: (headerName: string, rowNumber: number, columnNumber: number) => `${headerName} is required in row ${rowNumber}, column ${columnNumber}`,
        })),
        ...optionalFields.map(field => ({
          name: field,
          inputName: field,
          required: false,
        }))
      ],
    };

    const fileReader = new FileReader();
    fileReader.onload = () => {
      const csvData = fileReader.result as string;
      const rows = csvData.trim().split('\n');
      const headersRow = rows[config.headersRowIndex - 1].split(',').slice(0, config.headers.length).map(header => header.trim().toLowerCase());
      const validRows = rows.map((row, index) => {
        if (index === config.headersRowIndex - 1) {
          return headersRow;
        }
        const fields = row.split(',');
        return fields.slice(0, config.headers.length);
      });

      const headerMapping = new Map();
    for (const header of headersRow) {
      const lowerCaseHeader = header.toLowerCase();
      if (headerMapping.has(lowerCaseHeader)) {
        this.fileUploadStatus.emit({
          fileName: file.name,
          isValid: false,
          message: 'Invalid file structure: Duplicate column headers found'
        });
        this.fileControl.reset();
        this.selectedFile = null;
        return;
      }
    }
      const actualHeaders = validRows[0].join(',');
      const missingFields = requiredFields.filter(field => !headersRow.includes(field.toLowerCase()));
      console.log('Missing fields:', missingFields);

      if (missingFields.length > 0) {
        this.fileUploadStatus.emit({
          fileName: file.name,
          isValid: false,
          message: `Missing required fields: ${missingFields.join(', ')}`
        });

        this.fileControl.reset(); 
        this.selectedFile = null;
      } else {
        const validCsvData = validRows.map(row => row.join(',')).join('\n');
        const blob = new Blob([validCsvData], { type: 'text/csv' });
        const validFile = new File([blob], file.name, { type: 'text/csv' });

        CSVFileValidator(validFile, config)
          .then(() => {
            this.fileUploadStatus.emit({
              fileName: file.name,
              isValid: true,
              message: `${file.name} uploaded successfully`
              
            });
          })
          .catch(err => {
            console.error('Error validating CSV file:', err);
            this.fileUploadStatus.emit({
              fileName: file.name,
              isValid: false,
              message: 'File validation failed'
            });
          });
      }
    };
    fileReader.readAsText(file);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
