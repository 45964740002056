<header class="fixed-top shadow" *ngIf="showHeader">
    <app-layout-header></app-layout-header>
  </header>
  
  <section class="flex-fill pt-9 pb-6" role="main">
    <router-outlet></router-outlet>
  </section>
  
  <footer role="contentinfo">
    <app-layout-footer></app-layout-footer>
  </footer>