<div class="modal-backdrop fade-show d-flex" [style.z-index]="zIndex" aria-hidden="true">
  <div class="d-block modal fade show d-flex" tabindex="-1" aria-modal="true" role="dialog"
    aria-labelledby="modal-basic-title">
    <div class="d-flex flex-direction-column justify-content-center align-items-center">
      <div class="modal-dialog " role="document">
        <div class="modal-content" >
          <div class="modal-header">
            <h2 class="modal-title flex-fill text-center" id="modal-title">{{ modalTitle }}</h2>
            <button type="button" class="btn-close" (click)="onCloseModal()" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="max-height: 70vh; overflow-y: auto; scrollbar-width: thin;scrollbar-color: #888 #f1f1f1;">

            <ng-content></ng-content>
          </div>
          <div class="modal-footer">
            <ng-content select="[modal-footer]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>