import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabSetupService {
  private statusChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  statusChange$: Observable<string> = this.statusChangeSubject.asObservable();

  updateSiteStatus(status: string): void {
    this.statusChangeSubject.next(status);
    console.log('Site status updated to:', status);
  }

  getCurrentStatus(): Observable<string> {
    return this.statusChangeSubject.asObservable();
  }
}