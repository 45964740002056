<div #toastContainer></div>
<div class="container-xxl">
  <div class="row justify-content-center">
    <div class="col-10 mt-4">
      <div>
        <h2>Site Preview</h2>
        <app-divider></app-divider>
      </div>
      <div class="d-flex flex-direction-column justify-content-between mt-4">
        <h3>Site Information</h3>
        <div>
          <app-button [customClass]="'btn btn-secondary btn-sm'" [label]="'Edit'" (click)="openEditModal()">
            <span class="material-icons-round" (click)="openEditModal()">edit</span>
          </app-button>
        </div>
      </div>
      <app-spinner [isLoading]="isLoading"></app-spinner>
      <div class="card" *ngIf="!isLoading">
        <div class="preview-row">
          <div class="preview-col">
            <app-field-display label="Division" [value]="apiData ? apiData.division : ''"></app-field-display>
          </div>
          <div class="preview-col">
            <app-field-display label="Operating Unit" [value]="apiData ? apiData.siteId : ''"></app-field-display>
          </div>
        </div>

        <div class="preview-row">
          <div class="preview-col">
            <app-field-display label="Requester ID" [value]="apiData ? apiData.requesterId : ''"></app-field-display>
          </div>
          <div class="preview-col">
            <app-field-display label="Description" [value]="apiData ? apiData.siteName : ''"></app-field-display>
          </div>
        </div>

        <div class="preview-row">
          <div class="preview-col">
            <app-field-display label="Inventory Mandatory Count Frequency(days)"
              [value]="apiData ? apiData.recountFrequency : ''"></app-field-display>
          </div>

          <div class="preview-col">
            <app-field-display label="Business Unit" [value]="apiData ? apiData.businessUnit : ''"></app-field-display>
          </div>
                    
          <!-- <div class="preview-col">
            <app-field-display label="Status" [value]="apiData ? apiData.status : ''"></app-field-display>
          </div>  -->
        </div>


        <div class="preview-row">
           <!-- <div class="preview-col">
            <app-field-display label="Business Unit"
              [value]="apiData ? apiData.businessUnit : ''"></app-field-display>
          </div> -->
           
          <div class="preview-col">
            <app-field-display label="GL Unit" [value]="apiData ? apiData.glUnit : ''"></app-field-display>
          </div>

          <div class="preview-col">
            <app-field-display label="Status" [value]="apiData ? apiData.status : ''"></app-field-display>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="isVisible">
      <app-lab-setup-form></app-lab-setup-form>
    </div>
    <div class="col-10 d-flex justify-content-start mt-6" *ngIf="isVisible">
      <app-button [customClass]="'btn btn-primary'" [label]="'Done'" (click)="handleDone()"></app-button>
    </div>
  </div>

  <div *ngIf="showModal">
    <app-modal [modalTitle]="'Edit Site Information'" (closeModal)="handleCloseModal()">
      <ng-container modalContent>
        <form [formGroup]="siteSetupForm">
          <div class="site-form">
            <app-select-dropdown formControlName="division" [label]="'Division'" [options]="divisionOptions"
              [placeholder]="'Select'" [formControl]="division" [tooltipContent]="division_tooltip"
              [validationMessages]="validationMessages.division" [id]="'division-dropdown'"></app-select-dropdown>
            <app-input-field formControlName="siteId" [label]="'Operating Unit (Site ID)'" [placeholder]="''"
              [formControl]="siteId" [validationPattern]="validationPatterns.siteId"
              [validationMessages]="validationMessages.siteId" [tooltipContent]="site_id_tooltip"
              (ngModelChange)="sanitizeInput($event, 'siteId')" [id]="'siteID-input'"></app-input-field>
            <app-input-field formControlName="siteName" [label]="'Description (Site Name)'" [placeholder]="''"
              [formControl]="siteName" [validationPattern]="validationPatterns.siteName"
              [validationMessages]="validationMessages.siteName" [tooltipContent]="site_name_tooltip"
              (ngModelChange)="sanitizeInput($event, 'siteName')" [id]="'siteName-input'"></app-input-field>
            <app-input-field formControlName="requesterId" [label]="'Requester ID'" [placeholder]="''"
              [formControl]="requesterId" [validationPattern]="validationPatterns.requesterId"
              [validationMessages]="validationMessages.requesterId" [tooltipContent]="requester_id_tooltip"
              (ngModelChange)="sanitizeInput($event, 'requesterId')" [id]="'requesterId-input'"></app-input-field>
            <app-select-dropdown formControlName="recountFrequency"
              [label]="'Inventory Mandatory Count Frequency(days)'" [options]="recountFrequency"
              [placeholder]="'Select'" [formControl]="recountFrequencyControl"
              [tooltipContent]="recount_frequency_tooltip" [validationMessages]="validationMessages.recountFrequency"
              aria-label="Default Select Input" [id]="'recountFrequency-dropdown'"></app-select-dropdown>
              
            <app-input-field formControlName="businessUnit" [label]="'Business Unit '" [placeholder]="''"
            [formControl]="businessUnit" [validationPattern]="validationPatterns.businessUnit"
            [validationMessages]="validationMessages.businessUnit" [tooltipContent]="businessUnit_tooltip"
            (ngModelChange)="sanitizeInput($event, 'businessUnit')" [id]="'businessUnit-input'"></app-input-field>
            
          <app-input-field formControlName="glUnit" [label]="'GL Unit'" [placeholder]="''" [formControl]="glUnit"
            [validationPattern]="validationPatterns.glUnit" [validationMessages]="validationMessages.glUnit"
            [tooltipContent]="glUnit_tooltip"
            (ngModelChange)="sanitizeInput($event, 'glUnit')" [id]="'glUnit-input'"></app-input-field>
            <app-select-dropdown formControlName="status" [label]="'Status'" [options]="statusOptions"
              [placeholder]="'Select'" [formControl]="status" [tooltipContent]="site_status_tooltip"
              [validationMessages]="validationMessages.siteStatus" [id]="'status-dropdown'"></app-select-dropdown>
          </div>
        </form>
      </ng-container>

      <div modal-footer>
        <app-button [customClass]="'btn btn-secondary me-2'" [label]="'Cancel'" (click)="handleCancel()"></app-button>
        <app-button [customClass]="'btn btn-primary'" [label]="'Update'" (click)="handleUpdate()"
          [isDisabled]="isLoading" [isLoading]="isLoading"></app-button>
      </div>
    </app-modal>
  </div>
</div>