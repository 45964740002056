import { Component, OnInit, HostListener, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SiteSetupConstants } from '../../../global/site-setup-constants';
import { SanitizationService } from '../../../services/sanitization/sanitization.service';
import { ValidationService } from '../../../services/validation/validation.service';
import { FormOptionsService } from '../../../services/form-options/form-options.service';
import { Location } from '@angular/common';
import { FormDataService } from '../../../services/site-form-data/site-form-data.service';
import { FormInitializerService } from '../../../services/form-initializer/form-initializer.service';
import { Router } from '@angular/router';
import { SiteManagementService } from '../../../services/api/site-management.service';
import { response } from 'express';
import { DataStorageService } from '../../../services/dataStorage/data-storage.service';
import { SiteStatus } from '../../../reusable/site-status.enum';
import { SiteSetupFormValue } from '../../../model/site-setup-form.model';
import { OptionTextService } from '../../../services/option-text/option-text.service';
import { ToastService } from '../../../services/toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-site-setup-form',
  templateUrl: './site-setup-form.component.html',
  styleUrls: ['./site-setup-form.component.css']
})
export class SiteSetupFormComponent implements OnInit {

  siteSetupForm!: FormGroup;
  showModal = false;
  formData!: SiteSetupFormValue;
  private isSanitizing = false;
  submitting = false;
  private apiCallInProgress = false;
  @ViewChild('toastContainer', { read: ViewContainerRef }) toastContainer!: ViewContainerRef;


  siteTitle = SiteSetupConstants.siteInfo.siteTitle;
  siteSubTitle = SiteSetupConstants.siteInfo.siteSubTitle;
  site_id_tooltip = SiteSetupConstants.tooltipInfo.SITE_ID_TOOLTIP;
  site_name_tooltip = SiteSetupConstants.tooltipInfo.SITE_NAME_TOOLTIP;
  requester_id_tooltip = SiteSetupConstants.tooltipInfo.REQUESTER_ID_TOOLTIP;
  recount_frequency_tooltip = SiteSetupConstants.tooltipInfo.RECOUNT_FREQUENCY_TOOLTIP;
  site_status_tooltip = SiteSetupConstants.tooltipInfo.SITE_STATUS_TOOLTIP;
  division_tooltip = SiteSetupConstants.tooltipInfo.DIVISION;

  cancelModalTitle = SiteSetupConstants.cancelModal.CANCEL_MODAL_TITLE;
  cancelModalDescription = SiteSetupConstants.cancelModal.CANCEL_MODAL_DESCRIPTION;
  isLoading!: boolean;

  constructor(
    private fb: FormBuilder,
    private sanitizationService: SanitizationService,
    private validationService: ValidationService,
    private formOptionsService: FormOptionsService,
    private formDataService: FormDataService,
    private router: Router,
    private formInitializerService: FormInitializerService,
    private siteManagementService: SiteManagementService,
    private optionTextService: OptionTextService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.siteSetupForm = this.fb.group({
      division: ['', [Validators.required]],
      siteId: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.siteId), Validators.maxLength(6)]],
      siteName: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.siteName), Validators.maxLength(32)]],
      requesterId: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.requesterId)]],
      recountFrequency: ['', [Validators.required]],
      status: ['', [Validators.required]]
    });

    this.siteSetupForm = this.formInitializerService.initializeSiteSetupForm();
    this.siteSetupForm.statusChanges.subscribe(status => {
      if (this.siteSetupForm.dirty) {
        console.log('Form status:', status);
      }
    })

  }
  mapFormDataToPayload(formData: SiteSetupFormValue): any {
    const statusEnum = {
      'ACTIVE': SiteStatus.ACTIVE,
      'INACTIVE': SiteStatus.INACTIVE,
    };

    const status = statusEnum[formData.status.toUpperCase() as SiteStatus] || SiteStatus.ACTIVE

    return {
      division: formData.division,
      code: formData.siteId,
      name: formData.siteName,
      requestorId: formData.requesterId,
      countFrequency: formData.recountFrequency,
      status: status
    };
  }


  onSubmit(): void {
    if (this.siteSetupForm.valid && !this.apiCallInProgress) {
      this.apiCallInProgress = true;
      this.isLoading = true;
      console.log('Form values:', this.siteSetupForm.value);
      const sanitizedFormValues = this.sanitizeFormValues(this.siteSetupForm.value);
      console.log('Sanitized form values:', sanitizedFormValues);
      this.siteManagementService.createSite(this.mapFormDataToPayload(sanitizedFormValues))
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            console.log('Site created successfully:', response);
            // this.formDataService.setFormData(sanitizedFormValues);
            const siteCode = response.code;
            console.log('Site code:', siteCode)
            this.router.navigate(['sites', 'site-setup-preview', siteCode]);
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
            console.log(error.status)
            this.isLoading = false;
              console.log('Error creating site:', error);
              if (error.status === 409) {
                this.showFailureToast('A site with this code already exists. Please use a different site code.');
              }
              else {
              this.handleGenericError(error);
            }
            this.apiCallInProgress = false;
            console.error('Error creating site:', error);
          },
          complete: () => {
            this.isLoading = false;
            this.apiCallInProgress = false;
          }
        });
    } else {
      this.handleFormErrors();
    }
  }
  private handleGenericError(error: HttpErrorResponse): void {
    let errorMessage: string;
  
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `An error occurred: ${error.error}`;
    } else {
      // Server-side error
      switch (error.status) {
        case 400:
          errorMessage = 'Bad request. Please check the input form data.';
          break;
        case 404:
          errorMessage = 'Resource not found. Please check the request URL.';
          break;
        case 500:
          errorMessage = 'Internal server error. Please try again later.';
          break;
        default:
          errorMessage = `Unexpected error: ${error.error}`;
      }
    }
  
    this.showFailureToast(errorMessage);
  }

  onCancelClick(): void {
    this.showModal = true;
    console.log('showModal set to true:', this.showModal);
  }

  handleCloseModal(): void {
    this.showModal = false;
    console.log('Modal closed');
  }

  handleConfirmCancel(): void {
    this.showModal = false;
    console.log('Modal confirmed cancel');
    this.siteSetupForm.reset();
    this.siteSetupForm = this.formInitializerService.initializeSiteSetupForm();
    this.router.navigate(['sites']);

  }

  private handleFormErrors(): void {
    const errors = this.siteSetupForm.errors;
    if (errors) {
      console.error('Form errors:', errors);
    } else {
      const formControls = this.siteSetupForm.controls;
      for (const controlName in formControls) {
        const control = formControls[controlName];
        const controlErrors = control.errors;
        if (controlErrors) {
          console.error(`Errors for control ${controlName}:`, controlErrors);
        }
      }
    }
  }

  sanitizeFormValues(formValues: any): SiteSetupFormValue {
    return {
      division: this.optionTextService.getDivisionText(formValues.division),
      siteId: this.sanitizationService.sanitizeAlphanumeric(formValues.siteId),
      siteName: this.sanitizationService.sanitizeSiteDepartmentName(formValues.siteName),
      requesterId: this.sanitizationService.sanitizeAlphanumeric(formValues.requesterId),
      recountFrequency: this.optionTextService.getRecountFrequencyText(formValues.recountFrequency),
      status: this.optionTextService.getStatusText(formValues.status)
    };
  }

  sanitizeInput(event: any, controlName: string): void {
    if (this.isSanitizing) return;
    this.isSanitizing = true;

    const formControl = this.siteSetupForm.get(controlName);
    if (formControl) {
      let sanitizedValue = formControl.value;

      if (sanitizedValue !== formControl.value) {
        formControl.setValue(sanitizedValue, { emitEvent: false });
      }
    }

    this.isSanitizing = false;
  }

  showFailureToast(message?: string) {
    if (this.toastContainer) {
      const errorMessage = message || 'An error occurred while updating the site.';
      this.toastService.showToast(this.toastContainer, 'danger', errorMessage, true);
    } else {
      console.error('toastContainer is not defined');
    }
  }

  // Getters for form controls
  get division(): FormControl {
    return this.siteSetupForm.get('division') as FormControl;
  }

  get siteId(): FormControl {
    return this.siteSetupForm.get('siteId') as FormControl;
  }

  get siteName(): FormControl {
    return this.siteSetupForm.get('siteName') as FormControl;
  }

  get requesterId(): FormControl {
    return this.siteSetupForm.get('requesterId') as FormControl;
  }

  get recountFrequencyControl(): FormControl {
    return this.siteSetupForm.get('recountFrequency') as FormControl;
  }

  get status(): FormControl {
    return this.siteSetupForm.get('status') as FormControl;
  }

  get validationPatterns() {
    return this.validationService.validationPatterns;
  }

  get validationMessages() {
    return this.validationService.validationMessages;
  }

  get divisionOptions() {
    return this.formOptionsService.divisionOptions;
  }

  get statusOptions() {
    return this.formOptionsService.statusOptions;
  }

  get recountFrequency() {
    return this.formOptionsService.recountFrequency;
  }

  // logFormFields(formValues: SiteSetupFormValue): void {
  //   console.log('Form Fields:');
  //   console.log('Division:', formValues.division);
  //   console.log('Site ID:', formValues.siteId);
  //   console.log('Site Name:', formValues.siteName);
  //   console.log('Requester ID:', formValues.requesterId);
  //   console.log('Recount Frequency:', formValues.recountFrequency);
  //   console.log('Status:', formValues.status);
  // }
}
