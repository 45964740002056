import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormDataService } from '../../../services/site-form-data/site-form-data.service';
import { SiteSetupConstants } from '../../../global/site-setup-constants';
import { SanitizationService } from '../../../services/sanitization/sanitization.service';
import { ValidationService } from '../../../services/validation/validation.service';
import { FormOptionsService } from '../../../services/form-options/form-options.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { OptionTextService } from '../../../services/option-text/option-text.service';
import { BehaviorSubject } from 'rxjs';
import { LabSetupService } from '../../../services/lab-setup/lab-setup.service';
import { DataStorageService } from '../../../services/dataStorage/data-storage.service';
import { DepartmentService } from '../../../services/department/department.service';
import { LabSupplyDepartment } from '../../../model/lab-supply.model';
import { SiteManagementService } from '../../../services/api/site-management.service';
import { SiteStatus, StatusConversionUtil } from '../../../reusable/site-status.enum';
import { ToastService } from '../../../services/toast/toast.service';
import { ViewChild, ViewContainerRef } from '@angular/core';
import { SiteSetupFormValue } from '../../../model/site-setup-form.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, filter, map } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { TokenStorageService } from '../../../services/token-storage/token-storage.service';

@Component({
  selector: 'app-site-setup-preview',
  templateUrl: './site-setup-preview.component.html',
  styleUrls: ['./site-setup-preview.component.css']
})
export class SiteSetupPreviewComponent implements OnInit, OnDestroy {
  formData!: SiteSetupFormValue | null;
  private formDataSubscription!: Subscription;
  showModal = false;
  siteSetupForm!: FormGroup;
  divisionText!: string;
  requesterText!: string;
  recountFrequencyText!: string;
  statusText!: string;
  departments: LabSupplyDepartment[] = [];
  private disabledValues: { [key: string]: any } = {};
  siteStatusSubject = new BehaviorSubject<string>('');
  private isSanitizing = false;
  departmentData!: LabSupplyDepartment[];
  private departmentsSubscription!: Subscription;
  @ViewChild('toastContainer', { read: ViewContainerRef }) toastContainer!: ViewContainerRef;
  apiData!: SiteSetupFormValue;
  private apiCallInProgress = false;
  siteCode!: string;
  private initialSiteStatusSubject = new BehaviorSubject<string | null>(null);
  initialSiteStatus$ = this.initialSiteStatusSubject.asObservable();
  public isAuthenticated$!: Observable<boolean>;
  private authSubscription: Subscription | null = null;
  userProfile: any;
  userRole: string = '';

  siteTitle = SiteSetupConstants.siteInfo.siteTitle;
  siteSubTitle = SiteSetupConstants.siteInfo.siteSubTitle;
  site_id_tooltip = SiteSetupConstants.tooltipInfo.SITE_ID_TOOLTIP;
  site_name_tooltip = SiteSetupConstants.tooltipInfo.SITE_NAME_TOOLTIP;
  requester_id_tooltip = SiteSetupConstants.tooltipInfo.REQUESTER_ID_TOOLTIP;
  recount_frequency_tooltip = SiteSetupConstants.tooltipInfo.RECOUNT_FREQUENCY_TOOLTIP;
  site_status_tooltip = SiteSetupConstants.tooltipInfo.SITE_STATUS_TOOLTIP;
  division_tooltip = SiteSetupConstants.tooltipInfo.DIVISION;
  cancelModalTitle = SiteSetupConstants.cancelModal.CANCEL_MODAL_TITLE;
  cancelModalDescription = SiteSetupConstants.cancelModal.CANCEL_MODAL_DESCRIPTION;
  businessUnit_tooltip = SiteSetupConstants.tooltipInfo.BUSINESSUNIT_TOOLTIP;
  glUnit_tooltip = SiteSetupConstants.tooltipInfo.GLUNIT_TOOLTIP;
  isLoading!: boolean;
  isVisible!: boolean;



  constructor(
    private fb: FormBuilder,
    private sanitizationService: SanitizationService,
    private validationService: ValidationService,
    private formOptionsService: FormOptionsService,
    private location: Location,
    private formDataService: FormDataService,
    private optionTextService: OptionTextService,
    private labSetupService: LabSetupService,
    private dataStorageService: DataStorageService,
    private departmentService: DepartmentService,
    private router: Router,
    private siteManagementService: SiteManagementService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) { }

  ngOnInit() {

    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );

    this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
      console.log('User authentication status:', isAuthenticated);

      if (isAuthenticated) {
        // Initialize the form group first
        this.siteSetupForm = this.fb.group({
          division: [{ value: '', disabled: true }, [Validators.required]],
          siteId: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(this.validationService.validationPatterns.siteId)]],
          siteName: [{ value: '', disabled: this.isSiteManager() }, [Validators.required, Validators.pattern(this.validationService.validationPatterns.siteName)]],
          requesterId: [{ value: '', disabled: false }, [Validators.required, Validators.pattern(this.validationService.validationPatterns.requesterId)]],
          recountFrequency: [{ value: '', disabled: this.isSiteManager() }, [Validators.required]],
          businessUnit: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.businessUnit), Validators.maxLength(5)]],
          glUnit: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.glUnit), Validators.maxLength(5)]],
          status: [{ value: '', disabled: this.isSiteManager() }, [Validators.required]]
        });

        this.route.paramMap.subscribe(params => {
          this.siteCode = params.get('siteCode') || '';
          console.log('siteCode:', this.siteCode)
          if (this.siteCode) {
            this.fetchSiteData(this.siteCode)
          }
        })
        this.fetchUserProfile();

        // Subscribe to status changes after everything else is set up
        this.siteSetupForm.statusChanges.subscribe(status => {
          if (this.siteSetupForm.dirty && status === 'VALID') {
            this.handleStatusChange(this.siteSetupForm.get('status')?.value || '');
          }
        });

        // Fetch the initial site status and initialize department statuses
        this.labSetupService.statusChange$.subscribe(initialStatus => {
          this.departmentService.initializeDepartmentStatus(initialStatus);
        });

        this.departmentsSubscription = this.departmentService.getDepartments().subscribe(departments => {
          this.departmentData = departments;
        });
      } else {
        // User is not authenticated, perform a different action
        console.log('User is not authenticated');
        // Clear the stored token and user claims
        this.tokenStorage.clearToken();
        this.tokenStorage.clearUserData();
        this.router.navigate(['']);
      }
    });
  }
  fetchSiteData(siteCode: string) {
    this.isLoading = true;
    this.isVisible = false;
    this.siteManagementService.getSite(siteCode)
      .subscribe({
        next: (siteData) => {
          console.log('Site data:', siteData);
          this.isLoading = false;
          this.isVisible = true;
          this.apiData = this.mapApiDataToFormData(siteData);
          const initialStatusEnum = StatusConversionUtil.stringToStatus(this.apiData.status); // Convert from string to enum
          console.log('initialStatusEnum', initialStatusEnum)

          // Check if initialStatusEnum is defined
          if (initialStatusEnum !== undefined) {
            const initialStatusString = StatusConversionUtil.statusToString(initialStatusEnum);
            console.log('initialStatusString', initialStatusString)
            // Set the form data and the initial status
            this.formDataService.setFormData(this.apiData, initialStatusString);
          } else {
            // Handle the case where the status is undefined, if necessary
            console.warn('Initial status is undefined');
          }
          this.updateTextRepresentations();
          this.siteSetupForm.patchValue(this.apiData);
          this.handleStatusChange(this.apiData.status);
        },
        error: (error) => {
          this.isLoading = false;
          this.isVisible = false;
          console.error('Error fetching site data:', error);
          this.handleLoadError(error)
        }
      });
  }
  async fetchUserProfile() {
    const userClaims = await this.oktaAuth.getUser();
    const username = userClaims.email;

    if (username) {
      this.siteManagementService.getUserProfile(username).subscribe({
        next: (profile) => {
          // this.userProfile = profile;
          console.log('User profile:', profile);
          console.log('user rolename:', profile.roleName)
          this.userRole = profile.roleName;
          this.checkUserRole(this.userRole)
        },
        error: (error) => {
          console.error('Error fetching user profile:', error);
        }
      });
    } else {
      console.error('No username available to fetch user profile');
    }
  }

  checkUserRole(roleName: string) {
    if (roleName === "SITEMANAGER") {
      this.siteSetupForm.get('glUnit')?.disable();
      this.siteSetupForm.get('businessUnit')?.disable();
    }
    else {
      this.siteSetupForm.get('glUnit')?.enable();
      this.siteSetupForm.get('businessUnit')?.enable();
    }
  }


  private handleLoadError(error: HttpErrorResponse): void {
    let errorMessage: string;

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `An error occurred: ${error.error}`;
    } else {
      // Server-side error
      switch (error.status) {
        case 400:
          errorMessage = 'Bad request. Please check the input parameters.';
          break;
        case 401:
        case 403:
          errorMessage = 'Unauthorized access. Please check your permissions.';
          break;
        case 404:
          errorMessage = 'Data not found. Please check the request URL.';
          break;
        case 500:
          errorMessage = 'Internal server error. Please try again later.';
          break;
        default:
          errorMessage = `Unexpected error: ${error.error}`;
      }
    }

    this.showFailureToast(errorMessage);
  }




  private mapApiDataToFormData(apiData: any): SiteSetupFormValue {
    return {
      division: apiData.division,
      siteId: apiData.code,
      siteName: apiData.name,
      requesterId: apiData.requestorId,
      recountFrequency: apiData.countFrequency,
      businessUnit: apiData.businessUnit,
      glUnit: apiData.glUnit,
      status: this.optionTextService.getStatusText(apiData.status)
    };
  }


  handleStatusChange(newStatus: string) {
    if (this.formData) {
      const initialStatus = this.apiData.status;
      console.log(initialStatus)
      console.log(newStatus)

      // Check if the site was initially inactive and ensure departments stay inactive
      if (initialStatus === 'Inactive') {
        this.departmentService.updateDepartmentsStatus('Inactive');
      }

      this.updateTextRepresentations();
    }
  }

  updateTextRepresentations() {
    if (this.apiData) {
      this.divisionText = this.optionTextService.getDivisionText(this.apiData.division);
      this.recountFrequencyText = this.optionTextService.getRecountFrequencyText(this.apiData.recountFrequency);
      this.statusText = this.optionTextService.getStatusText(this.apiData.status);
    }
    else {
      this.divisionText = '';
      this.requesterText = '';
      this.recountFrequencyText = '';
      this.statusText = '';
    }
  }
  sanitizeInput(event: any, controlName: string): void {
    if (this.isSanitizing) return;
    this.isSanitizing = true;

    const formControl = this.siteSetupForm.get(controlName);
    if (formControl) {
      let sanitizedValue = formControl.value;

      if (sanitizedValue !== formControl.value) {
        formControl.setValue(sanitizedValue, { emitEvent: false });
      }
    }

    this.isSanitizing = false;
  }

  ngOnDestroy() {
    if (this.formDataSubscription) {
      this.formDataSubscription.unsubscribe();
    }
    this.formDataService.clearFormData();
    if (this.departmentsSubscription) {
      this.departmentsSubscription.unsubscribe();
    }
  }

  openEditModal() {
    if (this.apiData) {
      const initialStatus = this.apiData.status === SiteStatus.ACTIVE ? 'Active' : 'Inactive';
      console.log('initialStatus:', initialStatus)
      this.disabledValues = {
        division: this.apiData.division,
        siteId: this.apiData.siteId
      };

      // Patch the form without triggering status change
      this.siteSetupForm.patchValue({
        division: this.apiData.division,
        siteId: this.apiData.siteId,
        siteName: this.apiData.siteName,
        requesterId: this.apiData.requesterId,
        recountFrequency: this.apiData.recountFrequency,
        businessUnit: this.apiData.businessUnit, 
        glUnit: this.apiData.glUnit,  
        status: initialStatus
      }); // Disable emitting events to avoid triggering status change
      this.updateFormControlStates();
      this.showModal = true;
    }

  }
  private updateFormControlStates() {
    // These controls are always disabled
    this.siteSetupForm.get('division')?.disable({ emitEvent: false });
    this.siteSetupForm.get('siteId')?.disable({ emitEvent: false });

    if (this.isSiteManager()) {
      this.siteSetupForm.get('siteName')?.disable({ emitEvent: false });
      this.siteSetupForm.get('recountFrequency')?.disable({ emitEvent: false });
      this.siteSetupForm.get('status')?.disable({ emitEvent: false });
    } else {
      this.siteSetupForm.get('siteName')?.enable({ emitEvent: false });
      this.siteSetupForm.get('recountFrequency')?.enable({ emitEvent: false });
      this.siteSetupForm.get('status')?.enable({ emitEvent: false });
    }
  }

  handleCancel() {
    this.showModal = false;
    console.log('cancel button');
  }
  handleCloseModal() {
    this.showModal = false;
  }
  isSiteManager(): boolean {
    return this.userRole === 'SITEMANAGER';
  }

  handleUpdate() {
    if (this.siteSetupForm.valid && !this.apiCallInProgress) {
      this.apiCallInProgress = true;
      // Create an updated form data object with the current form values
      let updatedFormData: SiteSetupFormValue;

      if (this.isSiteManager()) {
        updatedFormData = {
          division: this.siteSetupForm.get('division')?.value ?? this.disabledValues['division'],
          siteId: this.siteSetupForm.get('siteId')?.value ?? this.disabledValues['siteId'],
          siteName: this.siteSetupForm.get('siteName')?.value ?? this.disabledValues['siteName'],
          requesterId: this.siteSetupForm.value.requesterId,
          recountFrequency: this.siteSetupForm.get('recountFrequency')?.value ?? this.disabledValues['recountFrequency'],
          businessUnit: this.siteSetupForm.get('businessUnit')?.value ?? this.disabledValues['businessUnit'],
          glUnit: this.siteSetupForm.get('glUnit')?.value ?? this.disabledValues['glUnit'],
          status: this.siteSetupForm.get('status')?.value ?? this.disabledValues['status']
        };
      } else {
        updatedFormData = {
          division: this.siteSetupForm.get('division')?.value ?? this.disabledValues['division'],
          siteId: this.siteSetupForm.get('siteId')?.value ?? this.disabledValues['siteId'],
          siteName: this.siteSetupForm.value.siteName,
          requesterId: this.siteSetupForm.value.requesterId,
          recountFrequency: this.siteSetupForm.value.recountFrequency, 
          businessUnit: this.siteSetupForm.value.businessUnit,
          glUnit: this.siteSetupForm.value.glUnit,
          status: this.siteSetupForm.value.status
        };
      }
      const sanitizedFormValues = this.sanitizeFormValues(updatedFormData);
      const payload = this.mapFormDataToPayload(sanitizedFormValues);
      console.log(payload);

      // Call the updateSite API
      this.isLoading = true;
      // this.showModal = false;
      this.siteManagementService.updateSite(payload.code, payload)
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            console.log('Site updated successfully:', response);
            const siteCode = payload.code
            console.log('siteCode:', siteCode)

            this.siteManagementService.getSite(siteCode)
              .subscribe({
                next: (updatedFormData) => {
                  console.log('updatedFormData:', updatedFormData);
                  this.apiData = this.mapApiDataToFormData(updatedFormData);
                  this.updateTextRepresentations();
                  this.siteSetupForm.patchValue(this.apiData);
                  this.handleStatusChange(this.apiData.status);
                  // Fetch updated departments after site update
                  this.fetchDepartments();
                },
                error: (error) => {
                  console.error('Error fetching site data:', error);
                  this.handleApiError(error, 'Error fetching updated site data');
                }
              });
            this.formDataService.updateFormData(sanitizedFormValues);
            this.departmentService.initializeDepartmentStatus(updatedFormData.status);
            this.showModal = false;
            this.showSuccessToast(sanitizedFormValues.siteId);
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error updating site:', error);
            this.handleApiError(error, 'Error fetching updated site data');
            this.apiCallInProgress = false;

          },
          complete: () => {
            this.isLoading = false;
            this.apiCallInProgress = false;
          }
        });
    } else {
      this.handleFormErrors();
    }
  }
  private handleApiError(error: HttpErrorResponse, context: string): void {
    let errorMessage: string;

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // Server-side error
      switch (error.status) {
        case 400:
          errorMessage = 'Bad request. Please check the input data.';
          break;
        case 401:
        case 403:
          errorMessage = 'Unauthorized access. Please check your permissions.';
          break;
        case 404:
          errorMessage = 'Resource not found. Please check the site ID.';
          break;
        case 409:
          errorMessage = 'Conflict. The site may have been modified by another user.';
          break;
        case 500:
          errorMessage = 'Internal server error. Please try again later.';
          break;
        default:
          errorMessage = `Unexpected error: ${error.message}`;
      }
    }

    this.showFailureToast(`${context}: ${errorMessage}`);
  }



  fetchDepartments() {
    this.siteManagementService.getDepartments(this.siteCode)
      .subscribe({
        next: (departments: LabSupplyDepartment[]) => {
          this.departments = departments.map((dept: LabSupplyDepartment) => {
            const departmentSiteCode = dept.departmentCode;
            const departmentCode = departmentSiteCode.substring(0, 4);
            console.log(departments)
            return {
              ...dept,
              departmentCode,
              departmentStatus: dept.status === SiteStatus.ACTIVE ? 'Active' : 'Inactive',
            };
          });

          this.departmentService.setDepartments(this.departments);
        },
        error: (error) => {
          console.error('Failed to fetch departments:', error);
        }
      });
  }

  sanitizeFormValues(formValues: any): SiteSetupFormValue {
    return {
      division: this.optionTextService.getDivisionText(formValues.division),
      siteId: this.sanitizationService.sanitizeAlphanumeric(formValues.siteId),
      siteName: this.sanitizationService.sanitizeSiteDepartmentName(formValues.siteName),
      requesterId: this.sanitizationService.sanitizeAlphanumeric(formValues.requesterId),
      recountFrequency: this.optionTextService.getRecountFrequencyText(formValues.recountFrequency),
      businessUnit: this.sanitizationService.sanitizeAlphanumeric(formValues.businessUnit),
      glUnit: this.sanitizationService.sanitizeAlphanumeric(formValues.glUnit),
      status: this.optionTextService.getStatusText(formValues.status)
    };
  }

  private handleFormErrors(): void {
    const errors = this.siteSetupForm.errors;
    if (errors) {
      console.error('Form errors:', errors);
    } else {
      const formControls = this.siteSetupForm.controls;
      for (const controlName in formControls) {
        const control = formControls[controlName];
        const controlErrors = control.errors;
        if (controlErrors) {
          console.error(`Errors for control ${controlName}:`, controlErrors);
        }
      }
    }
  }

  private mapFormDataToPayload(formData: SiteSetupFormValue): any {
    const statusEnum = {
      'ACTIVE': SiteStatus.ACTIVE,
      'INACTIVE': SiteStatus.INACTIVE,
    };

    const status = statusEnum[formData.status.toUpperCase() as SiteStatus] || SiteStatus.ACTIVE

    return {
      division: formData.division,
      code: formData.siteId,
      name: formData.siteName,
      requestorId: formData.requesterId,
      countFrequency: formData.recountFrequency,
      glUnit: formData.glUnit,
      businessUnit: formData.businessUnit,
      status: status
    };
  }
  handleDone(): void {
    // Store site information
    if (this.apiData) {
      this.dataStorageService.setSiteInformation(this.apiData);
      console.log(this.apiData);
    }

    // Store department information
    console.log(this.departmentData);
    this.dataStorageService.setDepartmentInformation(this.departmentData);

    this.router.navigate(['/sites']);
  }
  showSuccessToast(siteId: string) {
    if (this.toastContainer) {
      const message = `Site ${siteId} updated successfully!`;
      this.toastService.showToast(this.toastContainer, 'success', message, true);
    } else {
      console.error('toastContainer is not defined');
    }
  }

  showFailureToast(message?: string) {
    if (this.toastContainer) {
      const errorMessage = message || 'An error occurred while updating the site.';
      this.toastService.showToast(this.toastContainer, 'danger', errorMessage, true);
    } else {
      console.error('toastContainer is not defined');
    }
  }




  get division(): FormControl {
    return this.siteSetupForm.get('division') as FormControl;
  }

  get siteId(): FormControl {
    return this.siteSetupForm.get('siteId') as FormControl;
  }

  get siteName(): FormControl {
    return this.siteSetupForm.get('siteName') as FormControl;
  }

  get requesterId(): FormControl {
    return this.siteSetupForm.get('requesterId') as FormControl;
  }

  get recountFrequencyControl(): FormControl {
    return this.siteSetupForm.get('recountFrequency') as FormControl;
  }

  get businessUnit(): FormControl {
    return this.siteSetupForm.get('businessUnit') as FormControl;
  }

  get glUnit(): FormControl {
    return this.siteSetupForm.get('glUnit') as FormControl;
  }

  get status(): FormControl {
    return this.siteSetupForm.get('status') as FormControl;
  }
  get validationPatterns() {
    return this.validationService.validationPatterns;
  }

  get validationMessages() {
    return this.validationService.validationMessages;
  }

  get divisionOptions() {
    return this.formOptionsService.divisionOptions;
  }

  get statusOptions() {
    return this.formOptionsService.statusOptions;
  }

  get recountFrequency() {
    return this.formOptionsService.recountFrequency;
  }

}
