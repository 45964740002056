import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';


@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrl: './access-denied.component.css'
})
export class AccessDeniedComponent implements OnInit {
   message: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.message = params['message'] || 'You do not have access to this page. Please contact your Site Manager for support.';
    });
  }

  // goBack() {
  //   this.location.back();
  // }
  public async signOut(): Promise<void> {
    await this.oktaAuth.signOut();
  }
}
