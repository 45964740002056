import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../token-storage/token-storage.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
@Injectable({
  providedIn: 'root'
})
export class IdlelogoutService {

  private idleTimeout: any;
  private readonly timeoutDuration = 30 * 60 * 1000;

  constructor(private router: Router, private ngZone: NgZone,
    private tokenStorage: TokenStorageService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) { console.log('startMonitoring'); }

  startMonitoring(): void {
    console.log('startMonitoring');
    this.resetTimer();
    this.addEventListeners();
  }

  private addEventListeners(): void {
    ['mousemove', 'keypress', 'mousedown', 'touchstart'].forEach((event) => {
      if (typeof window !== 'undefined') {
        window.addEventListener(event, this.resetTimer.bind(this), true);
      }
    });
  }

  private resetTimer(): void {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
    }

    this.ngZone.runOutsideAngular(() => {
      this.idleTimeout = setTimeout(() => {
        this.ngZone.run(() => this.logout());
      }, this.timeoutDuration);
    });
  }

  private async logout(): Promise<void> {
    this.clearEventListeners();
    this.tokenStorage.clearToken();
    this.tokenStorage.clearUserData();
    localStorage.removeItem("isOktaLoggin");
    localStorage.removeItem("Username")
    console.log('User logged out due to inactivity.');
    await this.signOut();
  }

  public async signOut(): Promise<void> {
    console.log('okta signOut called');
    localStorage.removeItem("isOktaLoggin");
    localStorage.removeItem("Username")
    await this.oktaAuth.signOut();
  }

  private clearEventListeners(): void {
    ['mousemove', 'keypress', 'mousedown', 'touchstart'].forEach((event) => {
      if (typeof window !== 'undefined') {
        window.removeEventListener(event, this.resetTimer.bind(this), true);
      }
    });
  }
}
