<div class="table-responsive col-12">
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th class="sortable-header" (click)="onSort({ property: 'status' })">
          Status
          <app-sortdirection
            [property]="'status'"
            [direction]="sortProperty === 'status' ? sortDirection : ''"
          ></app-sortdirection>
        </th>
        <th class="sortable-header" (click)="onSort({ property: 'code' })">
          Operating Unit (Site ID)
          <app-sortdirection
            [property]="'code'"
            [direction]="sortProperty === 'code' ? sortDirection : ''"
          ></app-sortdirection>
        </th>
        <th class="sortable-header" (click)="onSort({ property: 'name' })">
          Description (Site Name)
          <app-sortdirection
            [property]="'name'"
            [direction]="sortProperty === 'name' ? sortDirection : ''"
          ></app-sortdirection>
        </th>
        <th class="sortable-header" (click)="onSort({ property: 'activeDeptCount' })">
          Active Departments Count
          <app-sortdirection
            [property]="'activeDeptCount'"
            [direction]="
              sortProperty === 'activeDeptCount' ? sortDirection : ''
            "
          ></app-sortdirection>
        </th>
        <th class="sortable-header" (click)="onSort({ property: 'requestorId' })">
          Requestor ID
          <app-sortdirection
            [property]="'requestorId'"
            [direction]="sortProperty === 'requestorId' ? sortDirection : ''"
          ></app-sortdirection>
        </th>
        <th class="sortable-header" (click)="onSort({ property: 'countFrequency' })">
          Inventory Mandatory Count Frequency (days)
          <app-sortdirection
            [property]="'countFrequency'"
            [direction]="sortProperty === 'countFrequency' ? sortDirection : ''"
          ></app-sortdirection>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let site of sitesForPagination">
        <td
          [ngStyle]="{
            color: site?.status === 'ACTIVE' ? '#0d820d' : '#c5203c'
          }"
        >
          {{ site.status }}
        </td>
        <td class="operating-unit">
          <a (click)="navigateToSiteSetupPreview(site.code)">{{
            site?.code
          }}</a>
        </td>
        <td>{{ site?.name }}</td>
        <td>{{ site?.activeDeptCount }}</td>
        <td>{{ site?.requestorId }}</td>
        <td>{{ site?.countFrequency }}</td>
      </tr>
    </tbody>
  </table>
  <div>
    <app-pagination
      [totalItems]="totalItems"
      [pageSize]="pageSize"
      [currentPage]="currentPage"
      (pageChanged)="onPageChanged($event)"
    ></app-pagination>
  </div>
</div>
