import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SiteSetupService } from '../site-setup.service';
import { Observable, Subscription, filter, map } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { TokenStorageService } from '../../services/token-storage/token-storage.service';

@Component({
  selector: 'app-site-setup',
  templateUrl: './site-setup.component.html',
  styleUrls: ['./site-setup.component.css']
})
export class SiteSetupComponent implements OnInit, OnDestroy{
  siteSetupForm: FormGroup;
  showForm = true;
  private routeSubscription!: Subscription;
  public isAuthenticated$!: Observable<boolean>;
  private authSubscription: Subscription | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private siteSetupService: SiteSetupService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this.siteSetupForm = this.formBuilder.group({
      // Define your form controls here
    });
  }



  ngOnInit(): void {
    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
  this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
    console.log('User authentication status:', isAuthenticated);

    if (isAuthenticated) {
      this.routeSubscription = this.router.events.subscribe(() => {
        const currentRoute = this.route.snapshot.firstChild?.routeConfig?.path;
        this.showForm = currentRoute !== 'site-setup-preview';
      });
      this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
  
    } else {
      // User is not authenticated, perform a different action
      console.log('User is not authenticated');
      // Clear the stored token and user claims
      this.tokenStorage.clearToken();
      this.tokenStorage.clearUserData();
      this.router.navigate(['']);
    }
  });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}