<div class="container-xxl mt-2">
  <div>
    <h2>Site Setup</h2>
    <app-divider></app-divider>
  </div>

  <div *ngIf="showForm">
    <app-site-setup-form></app-site-setup-form>
  </div>
  <router-outlet></router-outlet>
</div>
