import { ChangeDetectorRef, Component, ComponentRef, OnChanges, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, Form, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SiteSetupConstants } from '../../../global/site-setup-constants';
import { SanitizationService } from '../../../services/sanitization/sanitization.service';
import { ValidationService } from '../../../services/validation/validation.service';
import { FormOptionsService } from '../../../services/form-options/form-options.service';
import { LabSupplyDepartment } from '../../../model/lab-supply.model';
import { FileValidationService } from '../../../services/file-validation/file-validation.service';
import { ToastComponent } from '../../../reusable/toast/toast.component';
import { FormInitializerService } from '../../../services/form-initializer/form-initializer.service';
import { ToastService } from '../../../services/toast/toast.service';
import { LabSetupService } from '../../../services/lab-setup/lab-setup.service';
import { BehaviorSubject, Subscription, filter } from 'rxjs';
import { FormDataService } from '../../../services/site-form-data/site-form-data.service';
import { LabFormDataService } from '../../../services/lab-form-data/lab-form-data.service';
import { DepartmentService } from '../../../services/department/department.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SiteManagementService } from '../../../services/api/site-management.service';
import { SiteStatus } from '../../../reusable/site-status.enum';
import { StatusConversionUtil } from '../../../reusable/site-status.enum';
import { SiteSetupFormValue } from '../../../model/site-setup-form.model';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

// interface DepartmentParFileAssociation {
//   departmentCode: string;
//   parFile: File | null;
// }

@Component({
  selector: 'app-lab-setup-form',
  templateUrl: './lab-setup-form.component.html',
  styleUrls: ['./lab-setup-form.component.css']
})


export class LabSetupFormComponent implements OnInit {
  labSetupForm!: FormGroup;
  showModal = false;
  public departments: LabSupplyDepartment[] = [];
  public department: LabSupplyDepartment[] = [];
  @ViewChild('toastContainer', { read: ViewContainerRef }) toastContainer!: ViewContainerRef;
  uploadStatus: 'success' | 'failure' | null = null;
  uploadMessage: string | null = null;
  fileUploaded: boolean = false;
  uploadedFileName: string | null = null;
  fileUploadError: string | null = null;
  private departmentsSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private isSanitizing = false;
  currentStatus!: string;
  private formDataSubscription!: Subscription;
  siteId!: string;
  siteStatus!: string;
  editDepartment: LabSupplyDepartment | null = null;
  private destroy$: Subject<void> = new Subject<void>();
  private departmentsSubscriptionOther!: Subscription;
  private apiCallInProgress = false;
  // departmentParFileAssociations: DepartmentParFileAssociation[] = [];
  siteCode!: string;
  initialSiteStatus: string | null = null;
  updatedSiteStatus: string | null = null;
  siteStatuses = Object.values(SiteStatus)
  parFileStatus: { [departmentCode: string]: boolean } = {};
  deptStat!: string

  labTitle = SiteSetupConstants.labInfo.LabTitle;
  labSubTitle = SiteSetupConstants.labInfo.LabSubTitle;
  department_name_tooltip = SiteSetupConstants.tooltipInfo.DEPARTMENT_NAME_TOOLTIP;
  department_code_tooltip = SiteSetupConstants.tooltipInfo.DEPARTMENT_CODE_TOOLTIP;
  department_status_tooltip = SiteSetupConstants.tooltipInfo.DEPARTMENT_STATUS_TOOLTIP;
  department_users_tooltip = SiteSetupConstants.tooltipInfo.DEPARTMENT_USERS_TOOLTIP;
  file_upload_tooltip = SiteSetupConstants.tooltipInfo.FILE_UPLOAD_TOOLTIP;
  ship_to_code_tooltip = SiteSetupConstants.tooltipInfo.SHIP_TO_CODE_TOOLTIP;
  location_tooltip = SiteSetupConstants.tooltipInfo.LOCATION_TOOLTIP;


  /**
   * Loader related varaible declarations
  */
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private sanitizationService: SanitizationService,
    private validationService: ValidationService,
    private formOptionsService: FormOptionsService,
    private fileValidationService: FileValidationService,
    private formInitializerService: FormInitializerService,
    private toastService: ToastService,
    private labSetupService: LabSetupService,
    private formDataService: FormDataService,
    private labFormDataService: LabFormDataService,
    private departmentService: DepartmentService,
    private siteManagementService: SiteManagementService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.labSetupForm = this.fb.group({
      departmentName: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.departmentName)]],
      departmentCode: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.departmentCode)]],
      status: ['', [Validators.required]],
      shipToCode: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.shipToCode)]],
      location: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.location)]],
      parFile: [null, [this.optionalFileValidator()]] // Use a custom validator that checks if the file exists before validating
    });

    this.labSetupForm = this.formInitializerService.initializeLabSetupForm();
    console.log('Initial form values:', this.labSetupForm.value);
    this.isLoading = true;
    this.departmentsSubscription = this.departmentService.getDepartments().pipe(
      takeUntil(this.destroy$)
    ).subscribe(departments => {
      this.departments = departments;
      this.isLoading = false;
      // Check PAR file status for each department after initial fetch
      // this.checkParFileStatusesForDepartments(this.departments);
    }, error => {
      this.isLoading = false;
      console.log("error", error)

    });


    this.statusChangeSubscription = this.labSetupService.statusChange$.subscribe(status => {
      console.log('Site status changed:', status);
      this.departmentService.updateDepartmentsStatus(status);
    });

    // Fetch the initial site status and initialize department statuses
    this.labSetupService.getCurrentStatus().subscribe(initialStatus => {
      this.currentStatus = initialStatus;
      console.log('Current status:', initialStatus);
      this.departmentService.initializeDepartmentStatus(initialStatus);
    });

    this.formDataService.initialStatus$.subscribe(initialStatus => {
      this.initialSiteStatus = initialStatus;
      console.log('Initial Site Status in LabSetupForm:', this.initialSiteStatus);
    });

    this.route.paramMap.subscribe(params => {
      this.siteCode = params.get('siteCode') || '';
      console.log('siteCode:', this.siteCode);

      if (this.siteCode) {
        // Fetch departments data using the siteCode
        this.getDepartments(this.siteCode);
      }
    });
    this.formDataSubscription = this.formDataService.formData$.subscribe((formData: SiteSetupFormValue | null) => {
      if (formData) {
        // Update site ID and capture the updated status on subsequent changes
        this.siteId = formData.siteId;
        this.updatedSiteStatus = this.extractStatusFromValue(formData.status);
        console.log('Updated Site Status:', this.updatedSiteStatus);

        if (this.updatedSiteStatus === 'Inactive') {
          console.log('Updating department statuses to Inactive')
          console.log(this.departments)
          this.departments.forEach(department => {
            const departmentStatusEnum = StatusConversionUtil.stringToStatus(
              department.status
            );

            if (departmentStatusEnum === SiteStatus.ACTIVE) {
              console.log('Updating department:', department.departmentName);
              const updatedDepartmentData = {
                // ...department,
                status: SiteStatus.INACTIVE,
                code: department.departmentCode,
                name: department.departmentName,
                shipToCode: department.shipToCode,
                location: department.location

              };
              // Call the PUT API to update the department status
              console.log('Updating department:', department.status);
              this.siteManagementService
                .updateParLocation(this.siteCode, department.departmentCode, updatedDepartmentData)
                .subscribe({
                  next: (response) => {
                    console.log('Department updated successfully:', response);
                    this.getDepartments(this.siteCode);
                    // this.checkParFileStatus(this.siteCode, department.departmentCode);
                    this.showSuccessUpdateToast(department.departmentName);
                    this.labSetupForm = this.formInitializerService.initializeLabSetupForm();
                  },
                  error: (error) => {
                    console.error('Error updating department:', error);
                    this.showFailureToast();
                  }
                });
            }
          });
        }
        // this.checkParFileStatusesForDepartments(this.departments);
      }
    });
  }
  optionalFileValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        // If there's no file, treat the control as valid
        return null;
      }
      // Run your existing validators only if a file is present
      const fileTypeError = this.fileValidationService.validateFileType(control);
      const fileSizeError = this.fileValidationService.validateFileSize(control);

      // Combine errors if any
      return fileTypeError || fileSizeError || null;
    };
  }
  // private checkParFileStatusesForDepartments(departments: LabSupplyDepartment[]): void {
  //   departments.forEach(department => {
  //     this.checkParFileStatus(this.siteCode, department.departmentCode);
  //   });
  // }

  private checkParFileStatus(siteCode: string, departmentCode: string): void {
    console.log('Checking PAR file status for:', departmentCode);
    this.siteManagementService.getParFile(siteCode, departmentCode).subscribe({
      next: (response: HttpResponse<any>) => {
        // Check if the response status is 200
        if (response.status === 200) {
          console.log('PAR file exists for department:', departmentCode);
          this.parFileStatus[departmentCode] = true; // File exists
        } else {
          this.parFileStatus[departmentCode] = false; // File does not exist
        }
      },
      error: (error) => {
        console.error('Failed to fetch PAR file status:', error);
        this.parFileStatus[departmentCode] = false; // Assume file is not present on error
      }
    });
  }



  getDepartments(siteCode: string) {
    // Fetch departments data from the API
    this.siteManagementService.getDepartments(siteCode).subscribe({
      next: (departments: LabSupplyDepartment[]) => {
        console.log('Received departments:', departments);

        this.departments = departments.map((dept: LabSupplyDepartment) => {
          console.log(`Department Code: ${dept.departmentCode}, Status: ${dept.status}`);

          const departmentStatusEnum = StatusConversionUtil.stringToStatus(dept.status) || SiteStatus.INACTIVE;
          console.log('Department Status Enum:', departmentStatusEnum);

          const departmentSiteCode = dept.departmentCode;
          const departmentCode = departmentSiteCode.substring(0, 4);
          this.checkParFileStatus(siteCode, departmentCode)
          return {
            ...dept,
            departmentCode,
          };
        });

        // Log the final transformed departments
        console.log('Transformed departments:', this.departments);
      },
      error: (error) => {
        console.error('Failed to fetch departments:', error);
      }
    });
  }

  getDepartment(siteCode: string, departmentCode: string) {
    // Fetch a single department's data from the API
    this.siteManagementService.getDepartment(siteCode, departmentCode).subscribe({
      next: (department: LabSupplyDepartment) => {
        console.log('Received department:', department);

        const index = this.departments.findIndex(dept => dept.departmentCode === departmentCode);
        if (index !== -1) {
          this.departments[index] = {
            ...this.departments[index], // Keep existing data
            ...department, // Overwrite with new data
          };
        } else {
          // If the department wasn't found, add it to the list
          this.departments.push({
            ...department,
            departmentCode: department.departmentCode,
          });
        }

        // Log the updated departments
        console.log('Updated departments:', this.departments);
      },
      error: (error) => {
        console.error('Failed to fetch department:', error);
      }
    });
  }





  openEditModal(department: LabSupplyDepartment) {
    console.log(department)
    this.editDepartment = { ...department };
    console.log('Editing department:', this.editDepartment);
    console.log(this.editDepartment.status)
    const statusControl = this.labSetupForm.get('status');

    const departmentCode = department.departmentCode;
    //  const parFile = this.parFileStatus[departmentCode]?.file;

    // Convert the current department status string to SiteStatus enum
    const departmentStatusEnum = StatusConversionUtil.stringToStatus(department.status) ?? SiteStatus.INACTIVE;
    console.log('Department Status Enum:', departmentStatusEnum);
    console.log('Initial Site Status:', this.initialSiteStatus);

    const initialSiteStatusEnum = this.initialSiteStatus
      ? StatusConversionUtil.stringToStatus(this.initialSiteStatus)
      : SiteStatus.INACTIVE;

    // Safely convert the updated site status string to enum for consistency, handling null values
    const updatedSiteStatusEnum = this.updatedSiteStatus
      ? StatusConversionUtil.stringToStatus(this.updatedSiteStatus)
      : undefined;

    console.log('Updated Site Status:', updatedSiteStatusEnum);

    let statusDisplay: string;

    // Determine the status to display based on updated site status and department status
    if (updatedSiteStatusEnum === SiteStatus.INACTIVE) {
      // Set status to "Inactive" if the updated site status is inactive
      statusDisplay = StatusConversionUtil.statusToString(SiteStatus.INACTIVE);
      // this.labSetupForm.get('status')?.disable();
    } else if (updatedSiteStatusEnum === SiteStatus.ACTIVE) {
      // Set status to "Active" if the updated site status is active
      statusDisplay = StatusConversionUtil.statusToString(departmentStatusEnum);
    } else {
      // Fallback to department status if the updated site status is undefined
      statusDisplay = StatusConversionUtil.statusToString(departmentStatusEnum ?? SiteStatus.INACTIVE);
    }

    console.log('Status to display:', statusDisplay);
    console.log(department.shipToCode)

    // Disable controls before patching to avoid triggering status change
    this.labSetupForm.get('departmentCode')?.disable();
    console.log(this.updatedSiteStatus)

    if (this.updatedSiteStatus === 'INACTIVE' || this.updatedSiteStatus === 'Inactive') {
      console.log('Disabling status field due to INACTIVE site status');
      this.labSetupForm.get('status')?.disable(); // Disable the status field
    } else {
      console.log('Enabling status field because site status is ACTIVE');
      this.labSetupForm.get('status')?.enable(); // Enable the status field
    }
    // Patch the form with department values
    this.labSetupForm.patchValue({
      departmentName: department.departmentName,
      departmentCode: department.departmentCode,
      status: statusDisplay,
      shipToCode: department.shipToCode,
      location: department.location,

    });

    // Disable the departmentCode field to make it read-only
    this.labSetupForm.get('departmentCode')?.disable();
    this.showModal = true;
  }




  handleUpdate() {
    // const currentSiteStatusEnum = this.siteStatus === 'Inactive' 
    //   ? SiteStatus.INACTIVE 
    //   : SiteStatus.ACTIVE;

    // // Determine field disablement based on site status
    // if (currentSiteStatusEnum === SiteStatus.INACTIVE) {
    //   console.log('Disabling departmentStatus field due to INACTIVE site status during update');
    //   this.labSetupForm.get('status')?.disable(); // Disable the status field
    // } else {
    //   console.log('Enabling departmentStatus field because site status is ACTIVE during update');
    //   this.labSetupForm.get('status')?.enable(); // Enable the status field
    // }
    const updatedDepartment: LabSupplyDepartment = {
      departmentName: this.labSetupForm.get('departmentName')?.value,
      departmentCode: this.editDepartment?.departmentCode || '',
      status: this.extractStatusFromValue(this.labSetupForm.get('status')?.value),
      shipToCode: this.labSetupForm.get('shipToCode')?.value,
      location: this.labSetupForm.get('location')?.value,
      parFile: this.labSetupForm.get('parFile')?.value
    };
    console.log('Updated department:', updatedDepartment);


    const siteCode = this.siteCode;
    const departmentCode = this.editDepartment?.departmentCode || '';
    const updatedDepartmentData = this.prepareDepartmentData(updatedDepartment, siteCode);

    this.isLoading = true;
    //this.showModal = false;
    this.siteManagementService.updateParLocation(siteCode, departmentCode, updatedDepartmentData)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          console.log('Department updated successfully:', response);
          const index = this.departments.findIndex(dept => dept.departmentCode === updatedDepartment.departmentCode);
          if (index !== -1) {
            // Update the department in the array
            this.departments[index] = { ...this.departments[index], ...updatedDepartment };
            console.log('Updated departments:', this.departments);
          } else {
            console.error('Department not found in the array');
          }
          this.getDepartment(siteCode, departmentCode)
          this.showSuccessUpdateToast(updatedDepartment.departmentName);
          this.showModal = false;
          this.editDepartment = null;
          this.labSetupForm.reset();
          if (updatedDepartment.parFile) {
            this.uploadParFile(siteCode, departmentCode, updatedDepartment.parFile);
          }
          this.fileUploaded = false;
          this.fileUploadError = null;
          this.labSetupForm = this.formInitializerService.initializeLabSetupForm();
        },
        error: (error) => {
          this.isLoading = false;
          console.error('Error updating department:', error);
          this.showFailureToast();
        }
      });
  }


  handleCancel() {
    this.showModal = false;
    this.editDepartment = null;
    this.labSetupForm.reset();
    this.fileUploadError = null;
    this.fileUploaded = false;
    this.uploadedFileName = null;
    this.labSetupForm = this.formInitializerService.initializeLabSetupForm();
  }

  clearDepartments() {
    this.departments = [];
  }
  ngOnDestroy() {
    // if (this.departmentsSubscription) {
    //   this.departmentsSubscription.unsubscribe();
    // }
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
    if (this.formDataSubscription) {
      this.formDataSubscription.unsubscribe();
    }

    this.departmentsSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.departmentService.clearDepartmentsSubject();

    // Clear the lab setup form data
    this.labFormDataService.clearLabFormData()
    this.clearDepartments();
    // this.labSetupForm.get('parFile')?.reset();
  }


  onSubmit(): void {
    console.log('Form valid:', this.labSetupForm.valid);


    // // Check and log errors for each form control
    // Object.keys(this.labSetupForm.controls).forEach(key => {
    //   const control = this.labSetupForm.get(key);
    //   console.log(`Control ${key} - Valid:`, control?.valid);
    //   console.log(`Control ${key} - Value:`, control?.value);
    //   console.log(`Control ${key} - Errors:`, control?.errors);
    // });


    const departmentName = this.labSetupForm.get('departmentName');
    const departmentCode = this.labSetupForm.get('departmentCode');
    const status: string | null = this.labSetupForm.get('status')?.value;
    if (status) {
      console.log('Status control exists:', status);
    } else {
      console.log('Status control does not exist.');
    }
    const shipToCode = this.labSetupForm.get('shipToCode');
    const location = this.labSetupForm.get('location');
    const parFile = this.labSetupForm.get('parFile');

    // Log values and validity status of controls
    console.log('Form Controls:', departmentName, departmentCode, status, shipToCode, location, parFile);

    if (
      departmentName && departmentName.valid &&
      departmentCode && departmentCode.valid &&
      status &&
      shipToCode && shipToCode.valid &&
      location && location.valid
    ) {
      const newDepartmentCode = departmentCode.value;

      if (this.departments.some(dept => dept.departmentCode === newDepartmentCode)) {
        this.showFailureToast('Department code already exists. Please use a different code.');
        return;
      }

      if (this.fileUploaded) {
        this.createDepartmentWithFile();
        console.log("Creating department with file.");
      } else if (!parFile?.value) {
        this.createDepartmentWithoutFile();
        console.log("Creating department without file.");
      } else {
        this.createDepartmentWithoutFile();
        this.uploadStatus = 'failure';
        this.uploadMessage = 'Par file validation failed. Department added without file.';
        console.log("Par file validation failed, adding department without file.");
      }
    } else {
      this.handleFormErrors();
    }
  }
  private createDepartmentWithoutFile(): void {
    {

      const addDepartment: LabSupplyDepartment = {
        departmentName: this.labSetupForm.get('departmentName')?.value,
        departmentCode: this.labSetupForm.get('departmentCode')?.value,
        status: this.extractStatusFromValue(this.labSetupForm.get('status')?.value),
        shipToCode: this.labSetupForm.get('shipToCode')?.value,
        location: this.labSetupForm.get('location')?.value,
        parFile: this.labSetupForm.get('parFile')?.value
      };
      console.log('New department:', addDepartment);
      console.log('Creating department without file');

      if (!this.apiCallInProgress) {
        console.log('Creating department without file');
        this.apiCallInProgress = true;
        this.isLoading = true;
        // Ensure siteCode is correctly assigned
        const siteCode = this.siteCode;
        console.log('Creating department for site:', siteCode);

        const sanitizedFormValues = this.sanitizeFormValues(addDepartment);

        // Pass the siteCode to prepareDepartmentData
        // const updatedDepartmentData = this.prepareDepartmentData(updatedDepartment, siteCode);
        const departmentData = this.prepareDepartmentData(addDepartment, siteCode);
        console.log('Creating department with data:', departmentData);
      
        //this.handleCloseModal();
        this.siteManagementService.createParLocation(siteCode, departmentData)
          .subscribe({
            next: (response) => {
              this.isLoading = false;
              console.log('Department created successfully:', response);
              this.showModal = false;
              this.getDepartments(siteCode);
              this.showSuccessToast(sanitizedFormValues.departmentName);
              this.handleCloseModal();
              this.labSetupForm.reset();
              this.labSetupForm.get('parFile')?.reset();
              this.labSetupForm = this.formInitializerService.initializeLabSetupForm();
            },
            error: (error: HttpErrorResponse) => {
              console.error('Error creating department:', error);
              if (error.status === 409) {
                this.showFailureToast('A Department with this code already exists. Please use a different Department code.');
              }
              else {
              this.handleGenericError(error);
            }
              this.isLoading = false;
              this.apiCallInProgress = false;
            },
            complete: () => {
              this.isLoading = false;
              this.apiCallInProgress = false;
            }
          });
      }
    }
  }
  private handleGenericError(error: HttpErrorResponse): void {
    let errorMessage: string;
  
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `An error occurred: ${error.error}`;
    } else {
      // Server-side error
      switch (error.status) {
        case 400:
          errorMessage = 'Bad request. Please check the input form data.';
          break;
        case 404:
          errorMessage = 'Resource not found. Please check the request URL.';
          break;
        case 500:
          errorMessage = 'Internal server error. Please try again later.';
          break;
        default:
          errorMessage = `Unexpected error: ${error.error}`;
      }
    }
  
    this.showFailureToast(errorMessage);
  }

  private createDepartmentWithFile(): void {
    if (!this.apiCallInProgress) {
      this.apiCallInProgress = true;
      const siteCode = this.siteId;
      console.log('Creating department for site:', siteCode);
      const addDepartment: LabSupplyDepartment = {
        departmentName: this.labSetupForm.get('departmentName')?.value,
        departmentCode: this.labSetupForm.get('departmentCode')?.value,
        status: this.labSetupForm.get('status')?.value,
        shipToCode: this.labSetupForm.get('shipToCode')?.value,
        location: this.labSetupForm.get('location')?.value,
        parFile: this.labSetupForm.get('parFile')?.value
      };
      console.log('New department:', addDepartment);

      const sanitizedFormValues = this.sanitizeFormValues(this.labSetupForm.value);
      const departmentData = this.prepareDepartmentData(addDepartment, siteCode);
      console.log('Creating department with data:', departmentData);

      const parFile = this.labSetupForm.get('parFile')?.value;
      this.isLoading = true; 
      //this.handleCloseModal();
      this.siteManagementService.createParLocation(siteCode, departmentData)
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            console.log('Department created successfully:', response);
            const departmentSiteCode = response.code; // Assuming the response contains the departmentCode
            const departmentCode = departmentSiteCode.substring(0, 4);
            console.log('Department code:', departmentCode);
            this.getDepartments(siteCode);
            this.showSuccessToast(this.labSetupForm.get('departmentName')?.value);
            this.handleCloseModal();
            this.labSetupForm.reset();
            this.labSetupForm = this.formInitializerService.initializeLabSetupForm();
            this.fileUploaded = false;
            this.fileUploadError = null;

            // Upload the PAR file
            this.uploadParFile(siteCode, departmentCode, parFile);
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error creating department:', error);
            if (error.status === 409) {
              this.showFailureToast('A Department with this code already exists. Please use a different Department code.');
            }
            else {
            this.handleGenericError(error);
          }
            this.apiCallInProgress = false;
          },
          complete: () => {
            this.isLoading = false;
            this.apiCallInProgress = false;
          }
        });
    }
  }



  private uploadParFile(siteCode: string, departmentCode: string, file: File): void {
    console.log('File to upload:', file);

    // Convert the file to a Blob (if necessary) and upload it directly
    const parFileBlob = new Blob([file], { type: file.type });

    // API call using path parameters and parFile: Blob
    this.siteManagementService.uploadParFile(siteCode, departmentCode, parFileBlob)
      .subscribe({
        next: (response) => {
          console.log('PAR file uploaded successfully:', response);
          this.showSuccessFileToast(file.name);
          this.parFileStatus[departmentCode] = true;
          this.fileUploaded = false;
          this.fileUploadError = null;
          this.cd.detectChanges();
        },
        error: (error) => {
          console.error('Error uploading PAR file:', error);
          this.showFailureFileToast();
          this.cd.detectChanges();
          console.log('Error this is being logged')
          // this.showFailureToast();
          this.parFileStatus[departmentCode] = false;
          this.fileUploaded = false;
          this.fileUploadError = null;
          //  this.fileUploaded = false;
        },
        complete: () => {
          this.apiCallInProgress = false;
        }
      });
  }


  // Modify prepareDepartmentData to accept siteCode as a parameter
  private prepareDepartmentData(formData: LabSupplyDepartment, siteCode: string): any {
    const statusEnum = {
      'ACTIVE': SiteStatus.ACTIVE,
      'INACTIVE': SiteStatus.INACTIVE,
    };

    console.log('Form data:', formData);

    const status = statusEnum[formData.status?.toUpperCase() as SiteStatus] || SiteStatus.ACTIVE;

    return {
      name: formData.departmentName,
      code: formData.departmentCode,
      status: status,
      siteCode: siteCode,
      shipToCode: formData.shipToCode,
      location: formData.location,
    };
  }


  openModal() {
    this.resetFileUploadStatus();

    // Convert the initial site status string to SiteStatus enum
    const initialSiteStatusEnum = this.initialSiteStatus
      ? StatusConversionUtil.stringToStatus(this.initialSiteStatus)
      : SiteStatus.INACTIVE; // Default to INACTIVE if initial status is null
    console.log('Initial Site Status:', initialSiteStatusEnum);

    // Safely convert the updated site status string to enum for consistency, handling null values
    const updatedSiteStatusEnum = this.updatedSiteStatus
      ? StatusConversionUtil.stringToStatus(this.updatedSiteStatus)
      : undefined;
    console.log('Updated Site Status:', updatedSiteStatusEnum);

    let statusDisplay: string;

    // Determine the status to display based on updated site status and department status
    if (updatedSiteStatusEnum === SiteStatus.INACTIVE) {
      // Set status to "Inactive" if the updated site status is inactive
      statusDisplay = 'Inactive';
    } else if (updatedSiteStatusEnum === SiteStatus.ACTIVE) {
      // Set status to "Active" if the updated site status is active
      statusDisplay = '';
    } else {
      // Default to inactive if both are undefined or inactive
      statusDisplay = 'Inactive';
    }
    this.deptStat = statusDisplay

    // Disable controls before patching to avoid triggering status change
    if (statusDisplay === 'Inactive') {
      console.log('Disabling status field due to INACTIVE site status');
      this.labSetupForm.get('status')?.disable(); // Disable the status field
    } else {
      console.log('Enabling status field because site status is ACTIVE');
      this.labSetupForm.get('status')?.enable(); // Enable the status field
    }


    console.log('Status to display:', statusDisplay);
    // Patch the form with the appropriate status
    this.labSetupForm.patchValue({
      status: statusDisplay,
    });

    this.showModal = true;
  }

  isFormValid(): boolean {
    // console.log('deptStat:', this.deptStat);
    const departmentName = this.labSetupForm.get('departmentName');
    const departmentCode = this.labSetupForm.get('departmentCode');
    const status = this.labSetupForm.get('status');
    const shipToCode = this.labSetupForm.get('shipToCode');
    const location = this.labSetupForm.get('location');

    return (
      !!departmentName?.valid &&
      !!departmentCode?.valid &&
      (this.deptStat === 'Inactive' || !!status?.valid) &&
      !!shipToCode?.valid &&
      !!location?.valid
    );
  }

  handleCloseModal() {
    this.showModal = false;
    this.editDepartment = null;
    this.labSetupForm.reset();
    this.resetFileUploadStatus();
    this.labSetupForm.get('parFile')?.reset();
    this.labSetupForm = this.formInitializerService.initializeLabSetupForm();
  }

  handleConfirmCancel(): void {
    this.showModal = false;
    this.labSetupForm.reset();
    this.labSetupForm = this.formInitializerService.initializeLabSetupForm();
    this.handleCloseModal();
    this.labSetupForm.get('parFile')?.reset();
  }

  private handleFormErrors(): void {
    const errors = this.labSetupForm.errors;
    if (errors) {
      console.error('Form errors:', errors);
    } else {
      const formControls = this.labSetupForm.controls;
      for (const controlName in formControls) {
        const control = formControls[controlName];
        const controlErrors = control.errors;
        if (controlErrors) {
          console.error(`Errors for control ${controlName}:`, controlErrors);
        }
      }
    }
  }
  private extractStatusFromValue(value: string): string {
    return value.split(':').pop()?.trim() || value;
  }

  sanitizeFormValues(formValues: any): any {
    return {
      departmentName: this.sanitizationService.sanitizeSiteDepartmentName(formValues.departmentName),
      departmentCode: this.sanitizationService.sanitizeNumber(formValues.departmentCode),
      // status: this.extractStatusFromValue(formValues.status),
      shipToCode: this.sanitizationService.sanitizeInput(formValues.shipToCode),
      location: this.sanitizationService.sanitizeInput(formValues.location)
    };
  }


  sanitizeInput(event: any, controlName: string): void {
    if (this.isSanitizing) return;
    this.isSanitizing = true;

    const formControl = this.labSetupForm.get(controlName);
    if (formControl) {
      let sanitizedValue = formControl.value;

      if (sanitizedValue !== formControl.value) {
        formControl.setValue(sanitizedValue, { emitEvent: false });
      }
    }

    this.isSanitizing = false;
  }


  showSuccessToast(departmentName: string) {
    const message = `Department ${departmentName} was added to ${this.siteId} successfully!`;
    this.toastService.showToast(this.toastContainer, 'success', message, true);
  }
  showSuccessUpdateToast(departmentName: string) {
    const message = `Department ${departmentName} was updated for ${this.siteId} successfully!`;
    this.toastService.showToast(this.toastContainer, 'success', message, true);
  }

  showFailureToast(message?: string) {
    const errorMessage = message || 'An error occurred while adding the department.';
    this.toastService.showToast(this.toastContainer, 'danger', errorMessage, true);
  }
  showFailureToastParFile(message?: string) {
    const errorMessage = message || 'An error occurred while uploading Par File.';
    this.toastService.showToast(this.toastContainer, 'danger', errorMessage, true);
  }


  onFileUploadStatus(event: { fileName: string; isValid: boolean; message: string }): void {
    if (event.isValid) {
      this.fileUploaded = true;
      this.uploadedFileName = event.fileName;
      this.fileUploadError = null;
    } else {
      this.fileUploaded = false;
      this.fileUploadError = event.message;
    }
  }
  removeFileUpload(): void {
    this.fileUploaded = false;
    this.uploadedFileName = null;
    this.fileUploadError = null;
    this.labSetupForm.get('parFile')?.reset();
  }
  private resetFileUploadStatus(): void {
    this.fileUploaded = false;
    this.uploadedFileName = null;
    this.fileUploadError = null;
    this.uploadStatus = null;
    this.uploadMessage = null;
  }

  showSuccessFileToast(fileName: string) {
    const message = `File '${fileName}' uploaded successfully!`;
    this.toastService.showToast(this.toastContainer, 'success', message, true);
  }

  showFailureFileToast(message?: string) {
    const errorMessage = message || 'File upload failed. Please try again.';
    const timeout = 3000;
    this.toastService.showToast(this.toastContainer, 'danger', errorMessage, true, timeout);
  }


  // Getters for form controls

  get departmentName(): FormControl {
    return this.labSetupForm.get('departmentName') as FormControl;
  }

  get departmentCode(): FormControl {
    return this.labSetupForm.get('departmentCode') as FormControl;
  }

  get status(): FormControl {
    return this.labSetupForm.get('status') as FormControl;
  }
  get shipToCode(): FormControl {
    return this.labSetupForm.get('shipToCode') as FormControl;
  }
  get location(): FormControl {
    return this.labSetupForm.get('location') as FormControl;
  }

  get validationPatterns() {
    return this.validationService.validationPatterns;
  }

  get validationMessages() {
    return this.validationService.validationMessages;
  }

  get statusOptions() {
    return this.formOptionsService.statusOptions;
  }

  // Change the access modifier from private to public
  public getTextFromValue(value: string, options: { value: string, text: string }[]): string {
    if (!value) {
      return '';
    }

    // Extract the actual value without the prefix
    const cleanedValue = value.split(':').pop()?.trim() || value;
    const option = options.find(opt => opt.text === cleanedValue);

    return option ? option.text : cleanedValue;
  }
}
