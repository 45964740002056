export class GlobalConstants {
  public static appInfo = {
    header_appTitle: ' | Hospital Supply Management',
    footer_appTitle: 'Hospital Supply Management',
    appVersion: '0.0.1',
  };
  public static mainNav = [
    {
      title: ' Sites',
      route: '/sites',
    }, {
      title: 'Reports',
      children:[
        {
          title: ' Inventory on Hand',
          route: '/reports',
        },
        {
          title: ' Open Purchase Orders',
          route: '/poreports',
        }
      ]
    
    },
    {
      title: 'User Management',
      route: '/user-management'
    }
  ];
}