import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../services/api/report.service';
import { Department, Site } from '../model/report.model';
import { LoaderService } from '../services/spinner/loader.service';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, map, Observable, Subscription } from 'rxjs';
import { CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { AuthState } from '@okta/okta-auth-js';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage/token-storage.service';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { JsonPipe } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { auditReportItem, auditReportItemApiResponse, auditReportItemHeaders, auditReportItemRows } from '../model/auditReport.model';
import { SiteSetupConstants } from '../global/site-setup-constants';
import { ValidationService } from '../services/validation/validation.service';
import { AuditReportTableComponent } from './audit-report-table/audit-report-table.component';
import { saveAs } from 'file-saver';
import { FormInitializerService } from '../services/form-initializer/form-initializer.service';
import { SanitizationService } from '../services/sanitization/sanitization.service';
import { ReportType } from '../global/report-constants';
import { ReportUtilityServiceService } from '../services/report-service/report-utility-service.service';
@Component({
  selector: 'app-audit-reports',
  templateUrl: './audit-reports.component.html',
  styleUrl: './audit-reports.component.css'
})
export class AuditReportsComponent implements OnInit {
  @ViewChild(AuditReportTableComponent) auditReportTableComponent!: AuditReportTableComponent;
  sites: Site[] = [];
  departments: Department[] = []
  siteName: string = '';
  departmentName: string = '';
  email: string = "";
  public isAuthenticated$!: Observable<boolean>;
  authSubscription: Subscription | undefined;
  claims: { claim: string; value: CustomUserClaim | CustomUserClaim[]; }[] | undefined;
  loading: boolean = false;
  reports: any[] = [];
  isLoading!: boolean;
  isVisible: boolean = false;
  isSiteSelected: boolean = false;
  sitesLoading: boolean = false;
  parFileAudit!: FormGroup;
  siteOptions: { value: any; text: string }[] = [];
  departmentOptions: { value: any; text: string }[] = [];
  auditReportItem: auditReportItem[] = [];
  parFileAuditHeaders: any[] = [];
  parFileAuditRows: auditReportItemRows[] = [];
  auditReportResponse: auditReportItemApiResponse[] = [];
  currentReportType!: string;
  groupedItems: { [key: string]: auditReportItemRows[] } = {};
  totalItems: number = 0;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = this.calendar.getToday();
  toDate: NgbDate | null = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
  apiInProgress: boolean = true;
  site_tooltip: string = '';
  department_tooltip: string = '';
  dateRange_tooltip: string = '';
  itemId_tooltip: string = '';
  modifiedBy_tooltip: string = '';
  isDownloading!: boolean;
  isRouteChange: boolean = false;
  updateTooltips() {
    this.site_tooltip = SiteSetupConstants.tooltipInfo.getSiteTooltip(this.currentReportType);
    this.department_tooltip = SiteSetupConstants.tooltipInfo.getDepartmentTooltip(this.currentReportType);
    this.dateRange_tooltip = SiteSetupConstants.tooltipInfo.getDateRangeTooltip(this.currentReportType);
    this.itemId_tooltip = SiteSetupConstants.tooltipInfo.getItemIdTooltip(this.currentReportType);
    this.modifiedBy_tooltip = SiteSetupConstants.tooltipInfo.getModifiedByTooltip(this.currentReportType);
  }
  dateRange: string = '';
  itemId: string = '';
  nonGroupedReportTypes: string[] = ['ItemExpirationReport', 'MOMReport', 'CountSubmissionReport', 'OOSReport'];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private TokenStorageService: TokenStorageService,
    private reportService: ReportService,
    private oktaAuthStateService: OktaAuthStateService,
    private calendar: NgbCalendar,
    private formInitializer: FormInitializerService,
    public formatter: NgbDateParserFormatter,
    private validationService: ValidationService,
    private SanitizationService: SanitizationService,
    private reportUtilityService: ReportUtilityServiceService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {
    this.isRouteChange = true;
    setTimeout(() => { this.isRouteChange = false; }, 1000)


    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.parFileAudit = this.fb.group({
      site: ['', Validators.required],
      department: [{ value: '', disabled: true }, Validators.required],
      fromDateSubmit: ['', Validators.required],
      toDateSubmit: ['', Validators.required],
      itemIdSubmit: ['', [Validators.pattern(this.validationService.validationPatterns.itemId)]],
      modifiedBy: ['', this.validationService.validationPatterns.modifiedBy]
    });
    this.parFileAudit.setValidators(this.dateRangeValidator);
    this.route.queryParams.subscribe(params => {
      const newReportType = params['reportType'];
      if (this.currentReportType !== newReportType) {
        this.currentReportType = newReportType;
        this.updateTooltips();
        this.resetForm();
      }
    });
  }
  async ngOnInit() {
    const token = await this.oktaAuth.getAccessToken() || null;
    const userClaims = await this.oktaAuth.token.getUserInfo();
    this.claims = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
    this.email = userClaims['email'] || "";
    this.getFilters(this.email)
    this.transformSiteOptions();
  }
  resetForm() {
    this.parFileAudit = this.formInitializer.initializeReport();
    this.parFileAudit.patchValue({
      site: null,
      department: null
    });
    this.auditReportItem = [];
    this.isSiteSelected = false;
    this.isVisible = false;
    this.loading = false;
  }
  getFilters(email: string) {
    console.log("reports-email", email);
    // email = "noman.mohammed@labcorp.com"
    this.sitesLoading = true;
    this.reportService.getReportFilterList(email).subscribe({
      next: (response) => {
        console.log('report filter data:', response);
        this.sites = response?.siteAccess?.sites;
        this.transformSiteOptions();
        this.sitesLoading = false;
      },
      error: (error) => {
        this.sitesLoading = false;
      }
    });
  }
  transformSiteOptions() {
    this.siteOptions = this.sites.map(site => ({
      value: site.code,
      text: site.name,
    }));
  }
  onSiteChange(selectedSiteCode: string) {
    this.parFileAudit.patchValue({
      department: null,
      fromDateSubmit: null,
      toDateSubmit: null,
      modifiedBy: '',
      itemIdSubmit: ''
    });
    this.isSiteSelected = false;
    this.isVisible = false;
    this.parFileAuditHeaders = [];
    this.parFileAuditRows = [];

    this.getDepartmentsBySite(selectedSiteCode);
  }
  onClear() {
    this.parFileAudit.reset();
    this.departments = [];
    this.isSiteSelected = false;
    this.departmentOptions = [];
    this.isVisible = false;
    this.parFileAudit.get('department')?.disable();
  }
  getDepartmentsBySite(selectedSiteCode: string) {
    this.siteName = selectedSiteCode;
    let site = this.sites?.find((site: Site) => site.code === this.siteName);
    if (site) {
      this.departments = site.departments || [];
      this.transformDepartmentOptions();
      this.parFileAudit.get('department')?.enable();
    } else {
      this.departments = [];
      this.isSiteSelected = true;
      this.parFileAudit.get('department')?.disable();
    }
  }
  transformDepartmentOptions() {
    this.departmentOptions = this.departments.map(dept => ({
      value: dept.code,
      text: dept.name
    }))
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.parFileAudit.patchValue({
        fromDateSubmit: this.formatter.format(date)
      });
    } else if (this.fromDate && !this.toDate && date) {
      const from = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      const to = new Date(date.year, date.month - 1, date.day);
      const diffTime = Math.abs(to.getTime() - from.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 90 && date.after(this.fromDate)) {
        this.toDate = date;
        this.parFileAudit.patchValue({
          toDateSubmit: this.formatter.format(date)
        });
      } else {
        this.toDate = null;
      }
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.parFileAudit.patchValue({
        fromDateSubmit: this.formatter.format(date),
        toDateSubmit: null
      });
    }
  }
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const group = control as FormGroup;
    const fromDate = group.get('fromDateSubmit')?.value;
    const toDate = group.get('toDateSubmit')?.value;
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);
      const diffTime = Math.abs(to.getTime() - from.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 90) {
        console.log('hi')
        return { dateRangeExceeded: true };
      }
      if (from > to) {
        return { invalidDateRange: true };
      }
    }
    return null;
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  onSubmit() {
    if (!this.isFormValid()) {
      this.showValidationErrors();
      return;
    }
    switch (this.currentReportType) {
      case 'Par-FileAuditReport':
        this.submitParFileAuditReport();
        break;
      case 'ItemExpirationReport':
        this.submitItemExpirationReport();
        break;
      case 'MOMReport':
        this.submitMoMReport();
        break;
      case 'OOSReport':
        this.submitOOSReport();
        break;
      case 'CountSubmissionReport':
        this.getCountSubmissionReport();
        break;
      default:
        console.log('Unknown report type');
    }
  }
  private showValidationErrors() {
    Object.keys(this.parFileAudit.controls).forEach(key => {
      const control = this.parFileAudit.get(key);
      if (control) {
        control.markAsTouched();
      }
    });
  }
  isFormValid(): boolean {
    switch (this.currentReportType) {
      case 'Par-FileAuditReport':
        return this.validateParFileAuditReport();
      case 'ItemExpirationReport':
        return this.validateReportWithoutDate();
      case 'OOSReport':
        return this.validateReportWithoutDate();
      case 'MOMReport':
        return this.validateReportWithoutDate();
      case 'CountSubmissionReport':
        return this.validateParFileAuditReport();
      default:
        return false;
    }
  }
  validateParFileAuditReport(): boolean {
    const form = this.parFileAudit;
    if (!form.get('site')?.valid || !form.get('department')?.valid) {
      return false;
    }
    if (!form.get('fromDateSubmit')?.valid || !form.get('toDateSubmit')?.valid) {
      return false;
    }
    const fromDate = form.get('fromDateSubmit')?.value;
    const toDate = form.get('toDateSubmit')?.value;
    if (fromDate && toDate) {
      return new Date(fromDate) <= new Date(toDate);
    }
    return false;
  }
  validateReportWithoutDate(): boolean {
    return !!this.parFileAudit.get('site')?.valid &&
      !!this.parFileAudit.get('department')?.valid;
  }
  getCountSubmissionReport() {
    this.isSiteSelected = true;
    this.isLoading = true;
    this.isVisible = false;
    const formValue = this.parFileAudit.value;
    this.siteName = formValue.site;
    this.departmentName = formValue.department;
    const reportData = {
      fromDate: new Date(formValue.fromDateSubmit).toISOString(),
      toDate: new Date(formValue.toDateSubmit).toISOString(),
    };
    console.log("Form Value", formValue);
    this.reportService.postAuditReport(this.siteName, this.departmentName, reportData, 'CountSubmissionReport')
      .subscribe({
        next: (response) => {
          console.log(response);
          this.parFileAuditHeaders = this.reportUtilityService.getMappedHeaders('CountSubmissionReport');
          this.parFileAuditRows = this.reportUtilityService.formatCountSubmissionReport(response.rows);
          console.log('Mapped Headers:', this.parFileAuditHeaders);
          console.log('Processed Rows:', this.parFileAuditRows);
          this.isLoading = false;
          this.isVisible = true;
        },
        error: (err) => {
          console.error('Error submitting audit report:', err);
          this.isLoading = false;
          this.isVisible = false;
        }
      })
  }
  submitParFileAuditReport() {
    this.isSiteSelected = true;
    this.isLoading = true;
    this.isVisible = false;
    if (this.validateParFileAuditReport()) {
      const formValue = this.parFileAudit.value;
      this.siteName = formValue.site;
      this.departmentName = formValue.department;
      const reportData: {
        fromDate: string;
        toDate: string;
        modifiedBy: string | null;
        itemId: string | null;
      } = {
        fromDate: new Date(formValue.fromDateSubmit).toISOString(),
        toDate: new Date(formValue.toDateSubmit).toISOString(),
        modifiedBy: formValue.modifiedBy ? this.SanitizationService.sanitizeInput(formValue.modifiedBy) : null,
        itemId: formValue.itemIdSubmit ? this.SanitizationService.sanitizeInput(formValue.itemIdSubmit) : null
      };
      this.reportService.postAuditReport(formValue.site, formValue.department, reportData, 'ParItem')
        .subscribe({
          next: (response) => {
            console.log('Audit report submitted successfully:', response);
            this.parFileAuditHeaders = this.reportUtilityService.getMappedHeaders('ParItem');
            const parFileRecords = response.rows ? this.reportUtilityService.mapToAuditReportRows(response.rows) : [];
            const processedData = this.reportUtilityService.processAuditReport(parFileRecords);
            this.parFileAuditRows = processedData.processedRows;
            this.groupedItems = processedData.groupedItems;
            this.totalItems = processedData.totalItems;
            console.log('Mapped Headers:', this.parFileAuditHeaders);
            console.log('Processed Rows:', this.parFileAuditRows);
            console.log('Grouped Items:', this.groupedItems);
            console.log('Total Items:', this.totalItems);
            this.isLoading = false;
            this.isVisible = true;
          },
          error: (error) => {
            console.error('Error submitting audit report:', error);
            this.isLoading = false;
            this.isVisible = false;
          }
        });
    } else {
      console.log('Form is invalid');
    }
  }
  submitItemExpirationReport() {
    this.isSiteSelected = true;
    this.isLoading = true;
    this.isVisible = false;
    const siteControl = this.parFileAudit.get('site');
    const departmentControl = this.parFileAudit.get('department');
    if (siteControl && departmentControl && siteControl.valid && departmentControl.valid) {
      const formValue = this.parFileAudit.value;
      this.siteName = formValue.site;
      this.departmentName = formValue.department;
      const reportData: {
        itemId: string | null;
      } = {
        itemId: formValue.itemIdSubmit ? this.SanitizationService.sanitizeInput(formValue.itemIdSubmit) : null
      };
      this.reportService.postAuditReport(formValue.site, formValue.department, reportData, 'ItemExpirationReport')
        .subscribe({
          next: (response) => {
            console.log('Lot Tracked Items report submitted successfully:', response);
            this.parFileAuditHeaders = this.reportUtilityService.getMappedHeaders('ItemExpirationReport');
            const itemExpirationRecords = response ? this.reportUtilityService.formatItemExpirationReport(response) : [];
            this.parFileAuditRows = itemExpirationRecords;
            console.log('Mapped Headers:', this.parFileAuditHeaders);
            console.log('Processed Rows:', this.parFileAuditRows);
            this.isLoading = false;
            this.isVisible = true;
          },
          error: (error) => {
            console.error('Error submitting Lot Tracked Items report:', error);
            this.isLoading = false;
            this.isVisible = false;
          }
        });
    } else {
      console.log('Form is invalid');
    }
  }
  submitOOSReport() {
    this.isSiteSelected = true;
    this.isLoading = true;
    this.isVisible = false;
    const formValue = this.parFileAudit.value;
    this.siteName = formValue.site;
    this.departmentName = formValue.department;
    const reportData: {
      itemId: string | null;
    } = {
      itemId: formValue.itemIdSubmit ? this.SanitizationService.sanitizeInput(formValue.itemIdSubmit) : null
    };
    console.log("Form Value", formValue);
    this.reportService.postAuditReport(this.siteName, this.departmentName, reportData, 'OOSReport')
      .subscribe({
        next: (response) => {
          console.log('OOS report submitted successfully:', response);
          this.parFileAuditHeaders = this.reportUtilityService.getMappedHeaders('OOSReport');
          this.parFileAuditRows = response.rows ? this.reportUtilityService.mapToAuditReportRows(response.rows) : [];;
          console.log('Headers:', this.parFileAuditHeaders);
          console.log('Rows:', this.parFileAuditRows);
          this.isLoading = false;
          this.isVisible = true;
        },
        error: (err) => {
          console.error('Error submitting audit report:', err);
          this.isLoading = false;
          this.isVisible = false;
        }
      })
  }
  submitMoMReport() {
    this.isSiteSelected = true;
    this.isLoading = true;
    this.isVisible = false;
    const siteControl = this.parFileAudit.get('site');
    const departmentControl = this.parFileAudit.get('department');
    if (siteControl && departmentControl && siteControl.valid && departmentControl.valid) {
      const formValue = this.parFileAudit.value;
      this.siteName = formValue.site;
      this.departmentName = formValue.department;
      const reportData: {
        itemId: string | null;
      } = {
        itemId: formValue.itemIdSubmit ? this.SanitizationService.sanitizeInput(formValue.itemIdSubmit) : null
      };
      this.reportService.postAuditReport(formValue.site, formValue.department, reportData, 'MoMItemOrderQuantityReport')
        .subscribe({
          next: (response) => {
            console.log('Lot Tracked Items report submitted successfully:', response);
            this.parFileAuditHeaders = this.reportUtilityService.getMappedHeaders('MOMReport');
            this.parFileAuditRows = response.rows ? this.reportUtilityService.mapToAuditReportRows(response.rows) : [];
            console.log('Headers:', this.parFileAuditHeaders);
            console.log('Rows:', this.parFileAuditRows);
            this.isLoading = false;
            if (this.parFileAuditRows.length > 0)
              this.isVisible = true;
            else
              this.isVisible = false;
          },
          error: (error) => {
            console.error('Error submitting Lot Tracked Items report:', error);
            this.isLoading = false;
            this.isVisible = false;
          }
        });
    } else {
      console.log('Form is invalid');
    }
  }
  downloadCsv(): void {
    this.isDownloading = true;
    setTimeout(() => {
      const headers = [...this.parFileAuditHeaders];
      this.isDownloading = false;

      console.log(this.parFileAuditRows)
      const rows = this.parFileAuditRows.map(item => {
        return headers.map(header => {
          const value = this.reportUtilityService.getItemValue(item, header);
          return value !== undefined ? `"${String(value).replace(/"/g, '""')}"` : '';
        });
      });
      console.log('CSV Headers:', headers);
      console.log('CSV Rows:', rows);
      const csvContent = [
        headers.join(','),
        ...rows.map(row => row.join(','))
      ].join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const fileName = `${this.currentReportType}_${new Date().toISOString()}.csv`;
      saveAs(blob, fileName);
    }, 2000);
  }
  get site(): FormControl {
    return this.parFileAudit.get('site') as FormControl;
  }
  get department(): FormControl {
    return this.parFileAudit.get('department') as FormControl;
  }
  get fromDateSubmit(): FormControl {
    return this.parFileAudit.get('fromDateSubmit') as FormControl;
  }
  get toDateSubmit(): FormControl {
    return this.parFileAudit.get('toDateSubmit') as FormControl;
  }
  get itemIdSubmit(): FormControl {
    return this.parFileAudit.get('itemIdSubmit') as FormControl;
  }
  get modifiedBy(): FormControl {
    return this.parFileAudit.get('modifiedBy') as FormControl;
  }
  get validationMessages() {
    return this.validationService.validationMessages
  }
  get reportTitle(): string {
    switch (this.currentReportType) {
      case 'Par-FileAuditReport':
        return 'Par-File Audit';
      case 'ItemExpirationReport':
        return 'Item Expiration Report';
      case 'OOSReport':
        return 'OOS Report';
      case 'CountSubmissionReport':
        return 'Count Submission Report';
      case 'MOMReport':
        return 'MOM Report';
      default:
        return 'Audit Report';
    }
  }
  showDateRange(): boolean {
    switch (this.currentReportType) {
      case 'Par-FileAuditReport':
        return true;
      case 'OOSReport':
        return false;
      case 'ItemExpirationReport':
        return false;
      case 'MOMReport':
        return false;
      case 'CountSubmissionReport':
        return true;
      default:
        return true;
    }
  }
  showItemId(): boolean {
    switch (this.currentReportType) {
      case 'Par-FileAuditReport':
        return true;
      case 'OOSReport':
        return true;
      case 'ItemExpirationReport':
        return true;
      case 'MOMReport':
        return true;
      case 'CountSubmissionReport':
        return false;
      default:
        return true;
    }
  }
  showModifiedBy(): boolean {
    switch (this.currentReportType) {
      case 'Par-FileAuditReport':
        return true;
      case 'OOSReport':
        return false;
      case 'ItemExpirationReport':
        return false;
      case 'MOMReport':
        return false;
      case 'CountSubmissionReport':
        return false;
      default:
        return true;
    }
  }
}
