import { Injectable } from '@angular/core';
import { SiteSetupFormValue } from '../../model/site-setup-form.model';
import { LabSupplyDepartment } from '../../model/lab-supply.model';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  private siteInformation: SiteSetupFormValue | null = null;
  private departmentInformation: LabSupplyDepartment[] = [];

  setSiteInformation(siteInfo: SiteSetupFormValue): void {
    this.siteInformation = siteInfo;
  }

  getSiteInformation(): SiteSetupFormValue | null {
    return this.siteInformation;
  }

  setDepartmentInformation(departments: LabSupplyDepartment[]): void {
    this.departmentInformation = departments;

  }

  getDepartmentInformation(): LabSupplyDepartment[] {
    return this.departmentInformation;
  }

  getUserProfileData() {
    let userProfile = sessionStorage.getItem("UserProfile");
    let data;
    if (userProfile) {
      data = JSON.parse(userProfile);
    }

    return data;
  }
}