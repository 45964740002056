export enum SiteStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export class StatusConversionUtil{
  // Converts enum to readable string
  static statusToString(status: SiteStatus): string {
    switch (status) {
      case SiteStatus.ACTIVE:
        return 'Active';
      case SiteStatus.INACTIVE:
        return 'Inactive';
      default:
        return '';
    }
  }

  //converts readable string to enum
  static stringToStatus(status: string): SiteStatus | undefined {
    switch (status.toLowerCase()) {
      case 'active':
        return SiteStatus.ACTIVE;
      case 'inactive':
        return SiteStatus.INACTIVE;
      default:
        return undefined; // Handle undefined case appropriately
    }
  }
}