import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[appSortable]',
  host: {
    '(click)': 'onSort()'
  }
})
export class SortableDirective {
  @Input() appSortable: string = '';
  @Input() direction: string = '';
  @Output() sort = new EventEmitter<{ property: string; direction: string }>();
  constructor() { }
  onSort() {

    this.direction = this.direction === 'asc' ? 'desc' : 'asc';
    this.sort.emit({ property: this.appSortable, direction: this.direction });
  }
}
