import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonComponent } from './reusable/button/button.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LayoutHeaderComponent } from './template/common/layout/layout-header/layout-header.component';
import { LayoutFooterComponent } from './template/common/layout/layout-footer/layout-footer.component';
import { LayoutTemplateComponent } from './template/common/layout/layout-template/layout-template.component';
import { RouterModule } from '@angular/router';
import { LinkTwoComponent } from './template/link-two/link-two.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LoginComponent } from './login/login.component';
import { SitesComponent } from './sites/sites.component';
import { SiteSetupComponent } from './sites/site-setup/site-setup.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule} from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { DividerComponent } from './reusable/divider/divider.component';
import { InputFieldComponent } from './reusable/input-field/input-field.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SiteSetupFormComponent } from './sites/site-setup/site-setup-form/site-setup-form.component';
import { FileUploadComponent } from './reusable/file-upload/file-upload.component';
import { SelectDropdownComponent } from './reusable/select-dropdown/select-dropdown.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LabSetupFormComponent } from './sites/site-setup/lab-setup-form/lab-setup-form.component';
import { ModalComponent } from './reusable/modal/modal.component';
import { SiteSetupPreviewComponent } from './sites/site-setup/site-setup-preview/site-setup-preview.component';
import { ToastComponent } from './reusable/toast/toast.component';
import { FieldDisplayComponent } from './reusable/field-display/field-display.component';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { oktaAuth } from './okta.config';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { SiteTableComponent } from './sites/site-table/site-table.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportTableComponent } from './reports/report-table/report-table.component';
import { PaginationComponent } from './reusable/pagination/pagination.component';
import { SortableDirective } from './reusable/sorting/sortable.directive';
import { SortdirectionComponent } from './reusable/sortdirection/sortdirection.component'; 
import { AuthInterceptor } from './interceptor/auth/auth.interceptor';
import { SearchComponent } from './reusable/search/search.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserManagementTableComponent } from './user-management/user-management-table/user-management-table.component';
import { MultiSelectDropdownComponent } from './reusable/multi-select-dropdown/multi-select-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { OpenPOReportsComponent } from './open-poreports/open-poreports.component';
import { OpenPotableComponent } from './open-poreports/open-potable/open-potable.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { LineitemTableComponent } from './open-poreports/lineitem-table/lineitem-table.component';
import { LogoutComponent } from './logout/logout.component';



@NgModule({
  declarations: [
    AppComponent,
    ButtonComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    LayoutTemplateComponent,
    LinkTwoComponent,
    BreadcrumbComponent,
    LoginComponent,
    SitesComponent,
    SiteSetupComponent,
    DividerComponent,
    InputFieldComponent,
    SiteSetupFormComponent,
    FileUploadComponent,
    SelectDropdownComponent,
    LabSetupFormComponent,
    ModalComponent,
    SiteSetupPreviewComponent,
    ToastComponent,
    FieldDisplayComponent,
    AuthCallbackComponent,
    SiteTableComponent,
    ReportsComponent,
    ReportTableComponent,
    OpenPOReportsComponent,
    OpenPotableComponent,
    PaginationComponent,
    SortableDirective,
    SortdirectionComponent,
    SearchComponent,
    SpinnerComponent, 
    UserManagementComponent,
    UserManagementTableComponent,
    MultiSelectDropdownComponent,
    AccessDeniedComponent,
    LineitemTableComponent,
    LogoutComponent, 

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgbDropdown,
    RouterModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    MatDividerModule,
    ReactiveFormsModule,
    OktaAuthModule,
    NgSelectModule,


  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth }
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
