import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '../services/spinner/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.css'
})
export class SpinnerComponent implements OnInit {
 @Input() isLoading: boolean = false;

  //private subscription: Subscription;
  constructor(
   // private loaderService: LoaderService
  ) {

  }

  ngOnInit(): void {
    
  }
  // isLoading: boolean = false;

  // //private subscription: Subscription;
  // constructor(private loaderService: LoaderService) {

  // }

  // ngOnInit(): void {
  //   this.loaderService.loading$.subscribe((loading) => {
  //     this.isLoading = loading;
  //   });
  // }
}
