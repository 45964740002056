<div class="container-fluid d-flex justify-content-center align-items-center" style="height: 100%">
  <div class="text-center">

    <ng-container
      *ngIf="!isLoading && !isAuthenticated; else loadingSpinner">
      <div *ngIf="!isLoading && !isAuthenticated">
        <img [src]="ImagePath" class="login-img img-fluid" alt="Logo" />
      </div>
      <div class="center-button mt-4" *ngIf="!isLoading && !isAuthenticated">
        <app-button (click)="loginWithOkta()" [label]="'Login with OKTA'"
          [customClass]="'btn btn-primary'"></app-button>
      </div>
    </ng-container>

    <ng-template #loadingSpinner>
      <div class="position-absolute top-50 start-50 translate-middle">
        <app-spinner [isLoading]="true"></app-spinner>
      </div>
    </ng-template>
  </div>
</div>