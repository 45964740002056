import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FileValidationService {
  validateFile(file: File) {
    throw new Error('Method not implemented.');
  }
  maxFileSize = 1048577; // 1MB in bytes

  validateFileType(control: AbstractControl): { [key: string]: boolean } | null {
    const file = control.value;
    if (file && file.type !== 'text/csv') {
      return { invalidFileType: true };
    }
    return null;
  }

  validateFileSize(control: AbstractControl): { [key: string]: boolean } | null {
    const file = control.value;
    if (file && file.size > this.maxFileSize) {
      return { invalidFileSize: true };
    }
    return null;
  }
}