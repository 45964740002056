import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { ListUserUtilService } from '../services/list-user-util/list-user-util.service';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ValidationService } from '../services/validation/validation.service';
import { FormInitializerService } from '../services/form-initializer/form-initializer.service';
import { FormOptionsService } from '../services/form-options/form-options.service';
import { User } from '../model/user.model';
import { SiteSetupConstants } from '../global/site-setup-constants';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { SiteManagementService } from '../services/api/site-management.service';
import { StatusConversionUtil } from '../reusable/site-status.enum';
import { ChangeDetectorRef } from '@angular/core';
import { ToastService } from '../services/toast/toast.service';
import { UserStatus } from '../reusable/user-status.enum';
import { AuthState, CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { Observable, Subscription, filter, firstValueFrom, map, switchMap } from 'rxjs';
import { TokenStorageService } from '../services/token-storage/token-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SanitizationService } from '../services/sanitization/sanitization.service';
import { DataStorageService } from '../services/dataStorage/data-storage.service';
interface DepartmentOption {
  value: string;
  siteCode: string;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.css',

})
export class UserManagementComponent implements OnInit, OnDestroy {
  userForm!: FormGroup;
  showModal = false;
  showConfirmCancelModal = false;
  hasUsers: boolean = false;
  private isSanitizing = false;
  searchText: string = '';
  users: User[] = [];
  filteredUsers: any[] = [];
  sortProperty!: string
  sortDirection: string = 'asc';
  selectedSites: any[] = []; // Changed to an array for multi-select
  availableDepartments: Array<{ value: any, text: string }> = []; // New variable for departments
  selectedDepartments: any[] = []; // Changed to an array for multi-select
  selectedSiteIds!: string[];
  availableSites: {
    code: any; value: string, text: string
  }[] = [];
  availableSitesByDivision: {
    code: any; value: string, text: string
  }[] = [];
  sitesLoading = false;
  selectedSiteCodeUser: string | null = null;
  isSiteSelected: boolean = false;
  departmentLoading: boolean = false;
  siteOptions: { value: string, text: string }[] = [];
  departmentOptions: { value: string; text: string; group?: string; siteCode: string; }[] = [];
  selectedFormSites: any[] = [];
  subscription: Subscription | undefined;
  private apiCallInProgress = false;
  isEditMode: boolean = false;
  departmentsBySite: { [siteCode: string]: string[] } = {};
  @ViewChild('toastContainer', { read: ViewContainerRef }) toastContainer!: ViewContainerRef;
  private siteSubscription: Subscription | undefined;
  editUser: User | null = null;
  claims: { claim: string; value: CustomUserClaim | CustomUserClaim[]; }[] | undefined;
  private authSubscription: Subscription | null = null;
  public isAuthenticated$!: Observable<boolean>;
  dynamicRoleOptions: { value: string; text: string }[] = [];
  previousSelectedSites: string[] = [];
  private _currentDivisionOptions: any[];
  userDivision!: string;
  private isDivisionSelectionInProgress = false;
  userRoleName!: string;
  availableSitesByUserRole: {
    code: any; value: string, text: string
  }[] = [];

  cancelModalTitle = SiteSetupConstants.cancelModal.CANCEL_MODAL_TITLE;
  cancelModalDescription = SiteSetupConstants.cancelModal.CANCEL_MODAL_DESCRIPTION;
  email_tooltip = SiteSetupConstants.tooltipInfo.EMAIL_TOOLTIP;
  role_tooltip = SiteSetupConstants.tooltipInfo.ROLE_TOOLTIP;
  division_tooltip = SiteSetupConstants.tooltipInfo.DIVISION_TOOLTIP;
  firstName_tooltip = SiteSetupConstants.tooltipInfo.FIRST_NAME_TOOLTIP;
  lastName_tooltip = SiteSetupConstants.tooltipInfo.LAST_NAME_TOOLTIP;
  status_tooltip = SiteSetupConstants.tooltipInfo.STATUS_TOOLTIP;
  sites_tooltip = SiteSetupConstants.tooltipInfo.SITES_TOOLTIP;
  departments_tooltip = SiteSetupConstants.tooltipInfo.DEPARTMENTS_TOOLTIP;
  isLoading!: boolean;
  hasSites!: boolean;
  dynamicStyles = {
    'margin-top': '2rem'
  };
  isEmail!: boolean;
  useremail: any;
  isUserExist!: boolean;
  userType: string = 'internal';
  isRouteChange: boolean = false;

  constructor(
    private router: Router,
    private listUserUtilService: ListUserUtilService,
    private fb: FormBuilder,
    private validationService: ValidationService,
    private formInitializerService: FormInitializerService,
    private formOptionsService: FormOptionsService,
    private siteManagementService: SiteManagementService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastService: ToastService,
    private oktaAuthStateService: OktaAuthStateService,
    private tokenStorageService: TokenStorageService,
    private SanitizationService: SanitizationService,
    private dataStorageService: DataStorageService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this._currentDivisionOptions = [];

    this.isLoading = true;
    setTimeout(() => { this.isLoading = false; }, 1000)

  }


  ngOnInit() {
    console.log('User management page');
    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
      console.log('User authentication status:', isAuthenticated);
      if (isAuthenticated) {
        // User is authenticated
        this.resetForm();
        this.userForm.get('site')?.setValue([]);
        this.selectedSiteIds = this.userForm.get('site')?.value || [];
        this.loadSites();
        this.setupSiteSubscription();
        const userRole = this.tokenStorageService.getRole();
        this.dynamicRoleOptions = this.getRoleOptions(userRole);
        console.log("userRole:", userRole)
        this.updateCurrentDivisionOptions();

        this.setupSiteDeselection();
      } else {
        // User is not authenticated, perform a different action
        console.log('User is not authenticated');
        // Clear the stored token and user claims
        this.tokenStorageService.clearToken();
        this.tokenStorageService.clearUserData();
        this.router.navigate(['']);
      }
    });



    //     this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
    //       filter((s: AuthState) => !!s),
    //       map((s: AuthState) => s.isAuthenticated ?? false)
    //     );
    //   this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
    //   if (isAuthenticated) {
    //     const token = await this.oktaAuth.getAccessToken() || null;
    //     const userClaims = await this.oktaAuth.token.getUserInfo();
    //     this.claims = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
    //     console.log("User Claims", this.claims)
    //     const userRole = userClaims['app_role'];
    //     console.log("User Role:", userRole);
    //     this.dynamicRoleOptions = this.getRoleOptions('SuperUser');
    //   } else {
    //     console.log('User is not authenticated');
    //   }
    // });
  }
  updateCurrentDivisionOptions() {
    this._currentDivisionOptions = [...this.divisionOptions];
  }
  private setupSiteDeselection() {
    // Unsubscribe from any existing subscription
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
    this.userForm.get('site')?.valueChanges.subscribe((selectedSites: string[]) => {
      const previousSites = this.previousSelectedSites || [];
      const deselectedSites = previousSites.filter(site => !selectedSites.includes(site));
      const newlySelectedSites = selectedSites.filter(site => !previousSites.includes(site));

      // Handle deselected sites
      deselectedSites.forEach(site => this.handleSiteDeselection(site));

      // Load departments for newly selected sites
      if (newlySelectedSites.length > 0) {
        this.loadDepartmentsForSites(newlySelectedSites);
      }

      this.previousSelectedSites = selectedSites;
    });
  }
  handleSiteDeselection(deselectedSite: string) {
    const currentDepartments = this.userForm.get('department')?.value || [];
    const updatedDepartments = currentDepartments.filter((dept: string) => {
      const departmentOption = this.departmentOptions.find(option => option.value === dept);
      return departmentOption && departmentOption.siteCode !== deselectedSite;
    });

    // Update the form value
    this.userForm.patchValue({ department: updatedDepartments }, { emitEvent: false });

    // Update the department options
    this.departmentOptions = this.departmentOptions.filter(option => option.siteCode !== deselectedSite);

    // Force change detection
    this.changeDetectorRef.detectChanges();
  }

  setupRoleBasedFieldManagement() {
    this.userForm.get('division')?.disable();
    this.userForm.get('site')?.disable();
    this.userForm.get('department')?.disable();

    this.userForm.get('roleName')?.valueChanges.subscribe(role => {
      if (role) {
        this.initializeRoleSubscription();
        console.log("role", role)
      } else {
        this.userForm.get('division')?.disable();
        this.userForm.get('site')?.disable();
        this.userForm.get('department')?.disable();
      }
    });
    this.userForm.get('division')?.valueChanges.subscribe(division => {
      if (division) {
        const role = this.userForm.get('roleName')?.value;
        if (role === 'SITEMANAGER' || role === 'USER') {
          this.onDivisionSelected({ value: division });
        }
      }
    });
  }

  getRoleOptions(userRole: any): { value: string; text: string }[] {
    if (userRole === 'SUPERUSER') {
      return this.formOptionsService.superUserRoleOptions;
    } else if (userRole === 'DIRECTOR') {
      return this.formOptionsService.divisionManagerRoleOptions;
    } else if (userRole === 'SITEMANAGER') {
      return this.formOptionsService.siteManagerRoleOptions;
    } else if (userRole === 'BASICUSER') {
      return this.formOptionsService.basicUserRoleOptions;
    }
    else {
      return [];
    }
  }
  initializeRoleSubscription() {
    this.userForm.get('roleName')?.valueChanges.subscribe((roleName) => this.onRoleChange(roleName));
  }

  private setupSiteSubscription() {
    // Unsubscribe from the previous subscription, if it exists
    this.siteSubscription?.unsubscribe();

    this.siteSubscription = this.userForm.controls['site'].valueChanges.subscribe(selectedFormSites => {
      this.selectedSites = selectedFormSites;
      console.log("selectedFormSites", selectedFormSites);
      this.loadDepartmentsForSites(this.selectedSites);
    });
  }

  ngOnDestroy() {
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
    this.siteSubscription?.unsubscribe();
  }

  loadDepartmentsForSites(selectedSites: string[]) {
    this.departmentLoading = true;
    if (this.apiCallInProgress) {
      return;
    }
    const departmentRequests = selectedSites.map(siteCode =>
      firstValueFrom(this.siteManagementService.getDepartments(siteCode))
        .catch(() => [])
    );

    Promise.all(departmentRequests)
      .then(departmentDataArray => {
        const newDepartments: { value: string; text: string; group?: string; siteCode: string; disabled?: boolean; }[] = [];
        const departmentsBySite: { [siteCode: string]: Set<string> } = {};

        departmentDataArray.forEach((departmentData, index) => {
          const siteCode = selectedSites[index];
          departmentsBySite[siteCode] = new Set<string>();

          if (departmentData.length === 0) {
            newDepartments.push({
              value: siteCode,
              text: `${siteCode} (No departments)`,
              group: siteCode,
              siteCode: siteCode,
              disabled: true
            });
          } else {
            departmentData.forEach((department: { departmentCode: string; departmentName: string }) => {
              const departmentCode = department.departmentCode;
              if (!departmentsBySite[siteCode].has(departmentCode)) {
                departmentsBySite[siteCode].add(departmentCode);
                newDepartments.push({
                  value: `${siteCode}-${departmentCode}`,
                  text: departmentCode,
                  group: siteCode,
                  siteCode: siteCode
                });
              }
            });
          }
        });

        // Merge new departments with existing ones, avoiding duplicates within the same site
        this.departmentOptions = this.departmentOptions.filter(dept =>
          !selectedSites.includes(dept.siteCode) ||
          (departmentsBySite[dept.siteCode] && !departmentsBySite[dept.siteCode].has(dept.value))
        );
        this.departmentOptions = [...this.departmentOptions, ...newDepartments];
        this.departmentLoading = false;
        console.log("departmentOptions", this.departmentOptions);
        this.apiCallInProgress = false;

        // Force change detection
        this.changeDetectorRef.detectChanges();
      })
      .catch(error => {
        console.error('Error fetching department data:', error);
        const errorMessage = error.toString();
        if (errorMessage.includes('404')) {
          this.showFailureToast('Data not found. Please check the request URL.');
        } else if (errorMessage.includes('401') || errorMessage.includes('403')) {
          this.showFailureToast('Unauthorized access. Please check your permissions.');
        } else if (errorMessage.includes('500')) {
          this.showFailureToast('Internal server error. Please try again later.');
        } else {
          this.showFailureToast('Error fetching data: ' + errorMessage);
          console.error('Error fetching data:', error);
        }
        this.departmentLoading = false;
        this.apiCallInProgress = false;
      });

    // After loading departments, update the form control
    this.updateDepartmentOptions();
  }
  private updateDepartmentOptions() {
    const currentDepartments = this.userForm.get('department')?.value || [];
    const validDepartments = currentDepartments.filter((dept: string) =>
      this.departmentOptions.some(option => option.value === dept)
    );

    this.userForm.patchValue({
      department: validDepartments
    }, { emitEvent: false });

    // Trigger change detection for the multi-select dropdown
    this.changeDetectorRef.detectChanges();
  }




  async loadSites() {
    this.sitesLoading = true;
    if (!this.apiCallInProgress) {
      this.apiCallInProgress = true;
      try {
        const userClaims = await this.oktaAuth.getUser();
        const username = userClaims.email;

        if (username) {
          let profile = this.dataStorageService.getUserProfileData();
          if (profile) {
            this.availableSites = profile.siteAccess.sites;
            this.siteOptions = this.availableSites.map(site => ({
              value: site.code,
              text: site.code
            }));
            this.apiCallInProgress = false;
            this.sitesLoading = false;
            this.hasSites = this.availableSites.length > 0;
            // Check role and set division
            this.userRoleName = profile.roleName;
            this.availableSitesByUserRole = this.availableSites;

            if (profile.roleName === 'DIRECTOR' || profile.roleName === 'SITEMANAGER') {
              this.userDivision = profile.division;

              this._currentDivisionOptions = [{ value: this.userDivision, text: this.userDivision }];

            } else {
              // Reset division options for other roles
              this.updateCurrentDivisionOptions();
              // this.userForm.get('division')?.enable();
            }
          }
          else
            this.siteManagementService.getUserProfile(username).subscribe({
              next: (profile) => {
                console.log('User profile:', profile);
                if (profile && profile.siteAccess && profile.siteAccess.sites) {
                  this.availableSites = profile.siteAccess.sites;
                  this.siteOptions = this.availableSites.map(site => ({
                    value: site.code,
                    text: site.code
                  }));
                  console.log('Available sites:', this.availableSites);
                  this.hasSites = this.availableSites.length > 0;
                  // Check role and set division
                  this.userRoleName = profile.roleName;
                  this.availableSitesByUserRole = this.availableSites;
                  console.log(this.userRoleName);
                  if (profile.roleName === 'DIRECTOR' || profile.roleName === 'SITEMANAGER') {
                    this.userDivision = profile.division;
                    // Update the current division options
                    this._currentDivisionOptions = [{ value: this.userDivision, text: this.userDivision }];
                    // Set the division in the form
                    // this.userForm.get('division')?.setValue(this.userDivision);
                    // this.userForm.get('division')?.disable();
                  } else {
                    // Reset division options for other roles
                    this.updateCurrentDivisionOptions();
                    // this.userForm.get('division')?.enable();
                  }

                } else {
                  console.log('No sites found in user profile');
                  this.hasSites = false;
                }
                this.sitesLoading = false;
              },
              error: (error) => {
                console.error('Error fetching user profile:', error);
                this.handleLoadError(error);
              },
              complete: () => {
                this.apiCallInProgress = false;
              }
            });
        } else {
          console.error('No username available to fetch user profile');
          this.showFailureToast('Unable to fetch user profile');
          this.sitesLoading = false;
          this.apiCallInProgress = false;
        }
      } catch (error) {
        console.error('Error getting user claims:', error);
      }
    }
  }
  private handleLoadError(error: HttpErrorResponse): void {
    let errorMessage: string;

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `An error occurred: ${error.error}`;
    } else {
      // Server-side error
      switch (error.status) {
        case 400:
          errorMessage = 'Bad request. Please check the input parameters.';
          break;
        case 401:
        case 403:
          errorMessage = 'Unauthorized access. Please check your permissions.';
          break;
        case 404:
          errorMessage = 'Data not found. Please check the request URL.';
          break;
        case 500:
          errorMessage = 'Internal server error. Please try again later.';
          break;
        default:
          errorMessage = `Unexpected error: ${error.error}`;
      }
    }

    this.showFailureToast(errorMessage);
  }
  onRoleChange(roleName: string) {
    if (roleName === 'SUPERUSER') {
      this.setSuperUserFields();
    } else if (roleName === 'DIRECTOR') {
      this.setDivisionManagerFields();
    } else if (roleName === 'SITEMANAGER') {
      this.setSiteManagerFields();
    } else if (roleName === 'USER') {
      this.setSiteUserFields();
    }
  }
  setSuperUserFields() {
    this.userForm.patchValue({
      division: '',
      site: [],
      department: []
    });
    this.userForm.get('division')?.disable();
    this.userForm.get('site')?.disable();
    this.userForm.get('department')?.disable();
  }

  setDivisionManagerFields() {
    this.userForm.patchValue({
      site: [],
      department: []
    });
    this.userForm.get('division')?.enable();
    this.userForm.get('site')?.disable();
    this.userForm.get('department')?.disable();
  }

  setSiteManagerFields() {
    this.userForm.patchValue({
      department: []
    });
    this.userForm.get('division')?.enable();
    this.userForm.get('site')?.enable();
    this.userForm.get('department')?.disable();
  }


  setSiteUserFields() {
    this.userForm.get('division')?.enable();
    this.userForm.get('site')?.enable();
    this.userForm.get('department')?.enable();
  }
  getPlaceholder(field: string): string {
    const role = this.userForm.get('roleName')?.value;

    switch (role) {
      case 'SUPERUSER':
        return 'ALL';
      case 'DIRECTOR':
        return field === 'division' ? 'Select' : 'ALL';
      case 'SITEMANAGER':
        return field === 'department' ? 'ALL' : 'Select';
      case 'USER':
      default:
        return 'Select';
    }
  }

  onSearchTextChange(searchText: string) {
    console.log('Search text changed:', searchText);
    this.searchText = searchText;
    console.log('Users:', this.users);
    this.filteredUsers = this.listUserUtilService.filterUsers(this.users, this.searchText);
    console.log('Filtered users:', this.filteredUsers);
  }

  onSortChange(event: { property: string; direction: string }) {
    this.sortProperty = event.property;
    this.sortDirection = event.direction;
    this.sortUsers();
  }
  sortUsers() {
    this.filteredUsers = this.listUserUtilService.sortUsers(this.filteredUsers, this.sortProperty, this.sortDirection);
  }

  resetSearch() {
    this.searchText = '';
    this.filteredUsers = [...this.users];
    this.changeDetectorRef.detectChanges();
  }

  onSiteSelected(event: any) {
    if (event && event.code) {
      const selectedSiteCodeUser = event.code;
      this.selectedSiteCodeUser = selectedSiteCodeUser;
      this.isSiteSelected = true;
      this.isLoading = true;
      console.log('Selected site code:', selectedSiteCodeUser);
      this.siteManagementService.getUsers(selectedSiteCodeUser)
        .subscribe({
          next: (userData) => {
            this.users = userData;
            this.isSiteSelected = true;
            this.isLoading = false;
            this.resetSearch();
            // this.filteredUsers = this.users;
            console.log('Users:', this.users);
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error fetching user data:', error);
          }
        });
    } else {
      this.selectedSiteCodeUser = null;
      this.isSiteSelected = false;
      this.users = [];
      this.resetSearch();
    }
  }

  onDivisionSelected(event: any) {
    if (this.isDivisionSelectionInProgress) {
      return;
    }

    this.isDivisionSelectionInProgress = true;

    if (event && event.value) {
      const selectedDivision = event.value;
      this.loadSitesByDivision(selectedDivision);
    } else {
      // Reset sites if no division is selected
      this.siteOptions = [];
      this.userForm.get('site')?.setValue([]);
      this.userForm.get('department')?.setValue([]);
      this.isDivisionSelectionInProgress = false;
    }
  }

  loadSitesByDivision(division: string) {
    this.sitesLoading = true;
    this.siteManagementService.getSitesByDivision(division).subscribe({
      next: (sites) => {
        console.log('for selected division:', sites);
        if (this.userRoleName === 'SITEMANAGER') {
          this.siteOptions = this.availableSitesByUserRole.map(site => ({
            value: site.code,
            text: site.code
          }))
        }
        else {
          this.availableSitesByDivision = sites;
          this.siteOptions = this.availableSitesByDivision.map(site => ({
            value: site.code,
            text: site.code
          }));
        }

        this.isDivisionSelectionInProgress = false;
        this.sitesLoading = false;
      },
      error: (error) => {
        this.sitesLoading = false;
        console.error('Error fetching sites by division:', error);
        this.showFailureToast('Failed to load sites for the selected division');
        this.isDivisionSelectionInProgress = false;
      }
    });
  }

  handleDepartmentSelection(selectedDepartments: { value: string; group: string; }[]) {
    console.log('hi, here');
    const selectedDepartmentsWithSiteCodes = selectedDepartments;
    console.log("department", selectedDepartmentsWithSiteCodes);
  }

  getDepartmentsBySite(departments: string[], siteCode: string): string[] {
    console.log('siteCode', siteCode);
    console.log('departments', departments);
    console.log('departmentOptions', this.departmentOptions);

    const departmentsBySite: string[] = departments
      .filter(dept => dept.startsWith(`${siteCode}-`))
      .map(dept => dept.split('-')[1]);

    console.log('departmentsBySite', departmentsBySite);

    return Array.from(new Set(departmentsBySite));
  }

  isFormValid(): boolean {
    const form = this.userForm;

    const isFirstNameValid = form.get('firstName')?.valid ?? false;
    const isLastNameValid = form.get('lastName')?.valid ?? false;
    const isEmailValid = form.get('email')?.valid ?? false;
    const isStatusValid = form.get('status')?.valid ?? false;
    const roleName = form.get('roleName')?.value;

    let isRoleSpecificFieldsValid = true;

    switch (roleName) {
      case 'USER':
        isRoleSpecificFieldsValid =
          (form.get('division')?.valid ?? false) &&
          ((form.get('site')?.value?.length ?? 0) > 0) &&
          ((form.get('department')?.value?.length ?? 0) > 0);
        break;
      case 'SITEMANAGER':
        isRoleSpecificFieldsValid = (form.get('site')?.value?.length ?? 0) > 0;
        break;
      case 'DIRECTOR':
        isRoleSpecificFieldsValid = form.get('division')?.valid ?? false;
        break;
      case 'SUPERUSER':
        break;
      default:
        isRoleSpecificFieldsValid = false;
    }

    return isFirstNameValid && isLastNameValid && isEmailValid && isStatusValid && isRoleSpecificFieldsValid;
  }

  onSubmit() {
    if (!this.isFormValid()) {
      const roleName = this.userForm.get('roleName')?.value;
      let errorMessage = 'Please fill in all required fields correctly.';

      switch (roleName) {
        case 'USER':
          errorMessage = 'For User role, please ensure Division, Site, and Department are selected.';
          break;
        case 'SITEMANAGER':
          errorMessage = 'For Site Manager role, please select at least one Site.';
          break;
        case 'DIRECTOR':
          errorMessage = 'For Director role, please select a Division.';
          break;
        case 'SUPERUSER':
          break;
        default:
          errorMessage = 'Please select a valid role and fill in all required fields.';
      }

      this.showFailureToast(errorMessage);
      return;
    }

    const formValue = this.userForm.value;
    console.log('Form value:', formValue);
    console.log('Selected departments:', formValue.department);

    let uniqueSites = [...new Set(formValue.site)]
    const userData = {
      username: this.SanitizationService.sanitizeInput(formValue.email),
      firstName: this.SanitizationService.sanitizeInput(formValue.firstName),
      lastName: this.SanitizationService.sanitizeInput(formValue.lastName),
      userType: this.userType,
      // userType: 'internal',
      email: this.SanitizationService.sanitizeInput(formValue.email),
      roleName: formValue.roleName,
      status: StatusConversionUtil.stringToStatus(formValue.status),
      division: formValue.division,
      siteAccess: {
        sites: uniqueSites.map((site: any) => ({
          code: site,
          departments: formValue.department && formValue.department.length > 0
            ? this.getDepartmentsBySite(formValue.department, site).map(dept => ({ code: dept }))
            : []
        }))
      }
    };

    console.log('User data:', userData);
    console.log(formValue.site)

    if (this.apiCallInProgress) {
      this.showFailureToast('A request is already in progress. Please wait.');
      return;
    }

    this.isLoading = true;
    this.apiCallInProgress = true;

    this.siteManagementService.createUser(userData)
      .subscribe({
        next: (response) => {
          this.showModal = false;
          this.isLoading = false;
          console.log('User created successfully:', response);
          this.apiCallInProgress = false;
          this.isLoading = false;
          this.resetForm();
          this.loadSites();
          this.setupRoleBasedFieldManagement();
          this.setupSiteDeselection();
          this.setupSiteSubscription();
          const userRole = formValue.roleName;
          if (userRole === 'SITEMANAGER' || userRole === 'USER') {
            const selectedSiteCodes = formValue.site || [];
            if (selectedSiteCodes.length > 0) {
              selectedSiteCodes.forEach((siteCode: string) => {
                if (siteCode === this.selectedSiteCodeUser) {
                  this.onSiteSelected({ code: siteCode });
                }
              });
            }
          }
          else if (userRole === 'SUPERUSER' || userRole === 'DIRECTOR') {
            console.log('superuser')
            this.onSiteSelected({ code: this.selectedSiteCodeUser });
          }
          this.showSuccessUserCreateToast(userData.username);
        },
        error: (error) => {
          this.isLoading = false;
          this.apiCallInProgress = false;
          // this.setupRoleBasedFieldManagement();
          console.error('Error creating user:', error);
          console.log(error.status)
          if (error.status === 409) {
            this.showFailureToast('User with this email id already exists. Please use a different email id.');
          }
          else {
            this.handleGenericError(error);
          }
        },
        complete: () => {
          this.isLoading = false;
          this.apiCallInProgress = false;
        }
      });
  }
  private handleGenericError(error: HttpErrorResponse): void {
    let errorMessage: string;

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `An error occurred: ${error.error}`;
    } else {
      // Server-side error
      switch (error.status) {
        case 400:
          errorMessage = 'User save failed.  Please try again later.';
          break;
        case 404:
          errorMessage = 'Resource not found. Please check the request URL.';
          break;
        case 500:
          errorMessage = 'Internal server error. Please try again later.';
          break;
        default:
          errorMessage = `Unexpected error: ${error.error}`;
      }
    }

    this.showFailureToast(errorMessage);
  }

  openAddUserModal() {
    this.showModal = true;
    this.isEditMode = false;
    this.resetForm();
    this.setupSiteDeselection();
    this.clearAllSelections();

  }
  handleCancel() {
    this.showConfirmCancelModal = true;
    // this.resetForm();
    // this.setupRoleBasedFieldManagement();
    // this.loadDepartmentsForSites([]);
    // this.changeDetectorRef.detectChanges();
    // this.setupSiteSubscription();
    // this.setupSiteDeselection();
  }
  handleCloseConfirmModal() {
    this.showConfirmCancelModal = false;
  }

  handleConfirmCancel() {
    this.showModal = false;
    this.showConfirmCancelModal = false;
    this.resetForm();
    this.clearAllSelections();
    this.setupRoleBasedFieldManagement();
    this.loadDepartmentsForSites([]);
    this.changeDetectorRef.detectChanges();
    this.setupSiteSubscription();
    this.setupSiteDeselection();
  }
  handleCloseModal() {
    this.showModal = false;
    this.resetForm();
    this.setupRoleBasedFieldManagement();
    this.loadDepartmentsForSites([]);
    this.changeDetectorRef.detectChanges();
    this.setupSiteSubscription();
    this.setupSiteDeselection();
  }
  resetForm() {
    this.userForm = this.formInitializerService.initializeUserForm();
    this.clearAllSelections();
    const roleName = this.userForm.get('roleName')?.value;
    this.onRoleChange(roleName);
    this.initializeRoleSubscription();
    this.changeDetectorRef.detectChanges();
    this.setupSiteDeselection();
    this.setupRoleBasedFieldManagement();
    this.disableAllFields();
    this.isUserExist = false;
    this.isEmail = false;

  }

  //to clear all selections
  clearAllSelections() {
    this.siteOptions = [];
    this.departmentOptions = [];
    this.availableSitesByDivision = [];
    this.selectedSites = [];
    this.selectedDepartments = [];
    this.selectedSiteIds = [];
    this.userForm.patchValue({
      division: '',
      site: [],
      department: []
    }, { emitEvent: false });
  }

  //sanitization

  // sanitizeFormValues(formValues: any): User {
  //     return {
  //       email: formValues.email.trim(),
  //       role: formValues.role.trim(),
  //       division: formValues.division.trim(),
  //       firstName: formValues.firstName.trim(),
  //       lastName: formValues.lastName.trim(),
  //       site: formValues.site.trim(),
  //       department: formValues.department.trim(),
  //       status: formValues.status.trim()
  //     };
  //   }

  handleUserClicked(user: User) {
    this.isEditMode = true;
    this.resetForm();
    this.showModal = true;
    this.populateFormWithUserData(user);
    this.changeDetectorRef.detectChanges();
  }
  populateFormWithUserData(user: User) {
    console.log('User data:', user);
    const division = user?.siteAccess.division || user?.division;
    console.log('Division:', division);
    this.editUser = { ...user, division: division || '' }
    console.log('Edit User:', this.editUser);
    let status = user?.status
    if (status === 'ACTIVE') {
      this.userForm.get('status')?.setValue('Active');
    }
    else if (status === 'INACTIVE') {
      this.userForm.get('status')?.setValue('Inactive');
    }
    let uniqueSitesString = [...new Set(user?.siteAccess?.sites?.map(site => site.code) || [])] as string[];
    // this.loadDepartmentsForSites(uniqueSitesString)
    const departments = user?.siteAccess?.sites?.flatMap(site =>
      site.departments.map(dept => ({
        value: `${site.code}-${dept.code}`,
        text: dept.code,
        group: site.code,
        siteCode: site.code
      }))
    ) || [];
    console.log('Departments:', departments);
    this.userForm.patchValue({
      email: user?.email,
      roleName: user?.roleName,
      division: division || '',
      firstName: user?.firstName,
      lastName: user?.lastName,
      site: user?.siteAccess?.sites?.map(site => site.code) || [],
      department: departments.map(dept => dept.value) || [],
    });

    console.log('User Status:', user.status);

    this.userForm.get('email')?.disable();
    this.userForm.get('roleName')?.disable();
    this.userForm.get('firstName')?.disable();
    this.userForm.get('lastName')?.disable();
    this.userForm.get('division')?.disable();
    this.userForm.get('status')?.enable();
    // Set up subscription for site changes
    this.setupSiteDeselection();
    // Store the initial sites for comparison
    this.previousSelectedSites = uniqueSitesString;

  }



  handleUpdate() {
    // if (!this.isUpdateFormValid()) {
    //   this.showFailureUserUpdateToast('Please fill in all required fields correctly.');
    //   return;
    // }
    const formValue = this.userForm.value;
    let uniqueSites = [...new Set(formValue.site)];
    const username = this.editUser?.email || '';
    if (!this.apiCallInProgress) {
      this.apiCallInProgress = true;
      this.isLoading = true;
      let isLabcorpEmail = this.checkLabcorpEmail(username);
      if (isLabcorpEmail) {
        this.userType = "internal"
      }
      else {
        this.userType = "external"
      }
      const updateUser = {
        username: this.editUser?.email || '',
        firstName: this.editUser?.firstName || '',
        lastName: this.editUser?.lastName || '',
        userType: this.userType,
        email: this.editUser?.email || '',
        roleName: this.editUser?.roleName || '',
        status: formValue?.status === 'Active' ? UserStatus.Active : UserStatus.Inactive,
        division: this.editUser?.division,
        siteAccess: {
          sites: uniqueSites.map((site: any) => ({
            code: site,
            departments: formValue.department && formValue?.department.length > 0 ? this.getDepartmentsBySite(formValue?.department, site).map(
              (dept) => ({
                code: dept
              })
            ) : []
          }))
        }
      };
      console.log('Update User:', updateUser);

      this.siteManagementService.updateUser(username, updateUser)
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            console.log('User updated successfully:', response);
            this.showModal = false;
            this.apiCallInProgress = false;
            this.isLoading = false;
            this.resetForm();
            const userRole = updateUser.roleName;
            const selectedSiteCodes = formValue.site || [];
            if (userRole === 'SITEMANAGER' || userRole === 'USER') {
              if (selectedSiteCodes.length > 0) {
                selectedSiteCodes.forEach((siteCode: string) => {
                  if (siteCode === this.selectedSiteCodeUser) {
                    this.onSiteSelected({ code: siteCode });
                  }
                  else {
                    this.onSiteSelected({ code: this.selectedSiteCodeUser });
                  }
                });
              }
            } else if (userRole === 'SUPERUSER' || userRole === 'DIRECTOR') {
              console.log('superuser');
              this.onSiteSelected({ code: this.selectedSiteCodeUser });
            }
            this.showSuccessUserUpdateToast(username);
          },
          error: (error) => {
            this.isLoading = false;
            this.apiCallInProgress = false;
            console.error('Error updating user:', error);
            this.showFailureUserUpdateToast('Failed to update user. Please verify the form and try again.');
          },
          complete: () => {
            this.isLoading = false;
            this.apiCallInProgress = false;
          }
        });
    }
    else {
      console.log('API call in progress. Please wait.');
    }
  }

  isUpdateFormValid(): boolean {
    const form = this.userForm;
    console.log('Checking form validity');
    const roleName = form.get('roleName')?.value;
    // Check if status has changed
    const currentStatusRaw = form.get('status')?.value;
    const currentStatus = currentStatusRaw === 'Inactive' ? 'INACTIVE' : 'ACTIVE';
    const originalStatus = this.editUser?.status;
    const statusChanged = currentStatus !== originalStatus;
    console.log(currentStatus);
    console.log(originalStatus)
    console.log(statusChanged)
    const isStatusValid = form.get('status')?.value ? true : false;
    // console.log('Role:', roleName, 'Status valid:', isStatusValid);

    if (!isStatusValid) {
      console.log('Status is not valid');
      return false;
    }
    const siteChanged = this.hasSiteChanged();
    const departmentChanged = this.hasDepartmentChanged();
    let result: boolean;
    switch (roleName) {
      case 'USER':

        result = siteChanged || departmentChanged;
        // console.log('USER - Site changed:', siteChanged, 'Department changed:', departmentChanged, 'Result:', result);
        break;
      case 'SITEMANAGER':
        result = siteChanged || departmentChanged;
        // console.log('SITEMANAGER - Site changed:', result);
        break;
      case 'DIRECTOR':
      case 'SUPERUSER':
        result = siteChanged || departmentChanged;
        console.log('DIRECTOR/SUPERUSER - Always valid if status is valid');
        break;
      default:
        result = false;
        console.log('Unknown role');
    }

    console.log('Final result:', result);
    return result;
  }
  checkFormChanged() {
    if (this.userForm.dirty) {
      return true;
    } else {
      return false;
    }

  }

  private hasSiteChanged(): boolean {
    const currentSites = this.userForm.get('site')?.value || [];
    const originalSites = this.editUser?.siteAccess?.sites?.map(site => site.code) || [];
    console.log("currentSites", currentSites)
    const currentDepartments = this.userForm.get('department')?.value || [];
    // If currentSites is empty (length 0), return false
    if (currentSites.length === 0 || currentDepartments.length == 0) {
      console.log('Site change check - Current sites empty, disallowing update');
      return false;
    }
    else
      return true;
    // const changed = !this.areArraysEqual(currentSites, originalSites);
    // console.log('Site change check - Current:', currentSites, 'Original:', originalSites, 'Changed:', changed);
    // return changed;
  }

  private hasDepartmentChanged(): boolean {
    const currentDepartments = this.userForm.get('department')?.value || [];
    const originalDepartments = this.editUser?.siteAccess?.sites?.flatMap(site =>
      site.departments.map(dept => `${site.code}-${dept.code}`)
    ) || [];
    const currentSites = this.userForm.get('site')?.value || [];
    console.log("currentDepartments", currentDepartments)
    // If currentDepartment is empty (length 0), return false
    if (currentSites.length === 0 || currentDepartments.length == 0) {
      return false;
    }
    else
      return true
    // const changed = !this.areArraysEqual(currentDepartments, originalDepartments);
    // console.log('Department change check - Current:', currentDepartments, 'Original:', originalDepartments, 'Changed:', changed);
    // return changed;
  }

  private areArraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;
    return arr1.every(item => arr2.includes(item)) && arr2.every(item => arr1.includes(item));
  }

  sanitizeInput(event: any, controlName: string): void {
    if (this.isSanitizing) return;
    this.isSanitizing = true;

    const formControl = this.userForm.get(controlName);
    if (formControl) {
      let sanitizedValue = formControl.value;

      if (sanitizedValue !== formControl.value) {
        formControl.setValue(sanitizedValue, { emitEvent: false });
      }
    }

    this.isSanitizing = false;
  }

  showSuccessUserCreateToast(username: string) {
    if (this.toastContainer) {
      const message = `User ${username} was created Successfully!`;
      this.toastService.showToast(this.toastContainer, 'success', message, true);
    }
    else {
      console.log('Toast container not found');
    }
  }

  showFailureUserCreateToast(message?: string) {
    if (this.toastContainer) {
      const errorMessage = message || 'An error occurred';
      this.toastService.showToast(this.toastContainer, 'danger', errorMessage, true);
    }
    else {
      console.log('Toast container not found');
    }

  }
  showSuccessUserUpdateToast(username: string) {
    if (this.toastContainer) {
      const message = `User ${username} was updated Successfully!`;
      this.toastService.showToast(this.toastContainer, 'success', message, true);
    }
    else {
      console.log('Toast container not found');
    }

  }
  showFailureUserUpdateToast(message?: string) {
    if (this.toastContainer) {
      const errorMessage = message || 'An error occurred';
      this.toastService.showToast(this.toastContainer, 'danger', errorMessage, true);
    }
    else {
      console.log('Toast container not found');
    }
  }
  showFailureToast(message?: string) {
    if (this.toastContainer) {
      const errorMessage = message || 'An error occurred';
      this.toastService.showToast(this.toastContainer, 'danger', errorMessage, true);
    } else {
      console.error('toastContainer is not defined');
    }
  }
  showUserFailureToast(message?: string) {
    if (this.toastContainer) {
      const errorMessage = message || 'An error occurred';
      this.toastService.showToast(this.toastContainer, 'danger', errorMessage, true, 10000);
    } else {
      console.error('toastContainer is not defined');
    }
  }
  //getters
  get email(): FormControl {
    return this.userForm.get('email') as FormControl;
  }
  get roleName(): FormControl {
    return this.userForm.get('roleName') as FormControl;
  }
  get division(): FormControl {
    return this.userForm.get('division') as FormControl;
  }
  get firstName(): FormControl {
    return this.userForm.get('firstName') as FormControl;
  }
  get lastName(): FormControl {
    return this.userForm.get('lastName') as FormControl;
  }
  get isUpdateValid(): boolean {
    return this.isUpdateFormValid();
  }

  // get site() {
  //   return this.userForm.get('site');
  // }

  // get department() {
  //   return this.userForm.get('department');
  // }
  get site(): FormControl {
    return this.userForm.get('site') as FormControl;
  }
  get department(): FormControl {
    return this.userForm.get('department') as FormControl;
  }
  get status(): FormControl {
    return this.userForm.get('status') as FormControl;
  }
  get validationPatterns() {
    return this.validationService.validationPatterns;
  }

  get validationMessages() {
    return this.validationService.validationMessages;
  }
  get statusOptions() {
    return this.formOptionsService.statusOptions;
  }
  get roleOptions() {
    return this.formOptionsService.role;
  }
  get divisionOptions() {
    return this.formOptionsService.divisionOptions;
  }
  get currentDivisionOptions() {
    return this._currentDivisionOptions;
  }


  disableAllFields() {
    this.userForm.get('roleName')?.disable();
    this.userForm.get('firstName')?.disable();
    this.userForm.get('lastName')?.disable();
    this.userForm.get('status')?.disable();
    this.userForm.get('site')?.disable();
    this.userForm.get('department')?.disable();
    this.userForm.get('division')?.disable();
  }

  nullAllFields() {
    this.userForm.get('roleName')?.setValue('');
    this.userForm.get('firstName')?.setValue('');
    this.userForm.get('lastName')?.setValue('');
    this.userForm.get('status')?.setValue('');
    this.userForm.get('site')?.setValue('');
    this.userForm.get('department')?.setValue('');
    this.userForm.get('division')?.setValue('');
  }


  enableAllFields() {
    this.userForm.get('roleName')?.enable();
    this.userForm.get('firstName')?.enable();
    this.userForm.get('lastName')?.enable();
    this.userForm.get('status')?.enable();
  }

  private resetFormExceptEmail() {
    const currentEmail = this.userForm.get('email')?.value;
    this.resetForm();
    this.userForm.patchValue({ email: currentEmail });
    this.clearAllSelections();
    this.setupRoleBasedFieldManagement();
    this.disableAllFields();
    this.nullAllFields();
    this.enableEmailField();
  }
  private enableEmailField() {
    this.userForm.get('email')?.enable();
  }

  onInputChange(event: any) {
    //const inputElement = event.target as HTMLInputElement;
    const form = this.userForm;
    this.isEmail = form.get('email')?.valid ?? false;
    if ((event.target?.value === "" || event.target?.value === undefined || event.target?.value === null || !this.isEmail)) {
      this.resetFormExceptEmail();
      this.isUserExist = false;
    }
    if (this.isEmail) {
      this.useremail = event.target?.value;
      let isLabcorpEmail = this.checkLabcorpEmail(event.target?.value);
      if (isLabcorpEmail) {
        const userRole = this.tokenStorageService.getRole();
        this.dynamicRoleOptions = this.getRoleOptions(userRole);
        this.userType = "internal"
      }
      else {
        this.dynamicRoleOptions = this.getRoleOptions("BASICUSER");
        this.userType = "external"
      }
    }
    this.disableAllFields();
    this.nullAllFields();
    this.clearAllSelections();
    //this.setupSiteDeselection();
  }

  validateEmail() {
    if (this.useremail) {
      this.isLoading = true;
      this.siteManagementService.getUserProfile(this.useremail).subscribe({
        next: (profile) => {
          this.isLoading = true;
          console.log('User profile:', profile);
          this.isUserExist = true;
          this.userForm.get('email')?.setValue('');
          this.isEmail = false;
          this.showUserFailureToast(`${this.useremail} already exists`);
          this.disableAllFields()
        },
        error: (error) => {
          this.isUserExist = false;
          this.isLoading = false;
          this.enableAllFields();
          console.error('Error fetching user profile:', error);
          //this.handleLoadError(error);
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    }
  }


  checkLabcorpEmail(email: string): boolean {
    return email.toLowerCase().endsWith('@labcorp.com');
  }

}