<!-- Navbar Header -->
<nav class="navbar navbar-dark bg-primary navbar-expand-lg navbar-link-underline " aria-label="Main Navbar">
  <div class="container-xxl">
    <button class="navbar-toggler m-0 me-4" (click)="openMainNav(offcanvasMainNav)" type="button">
      <span class="material-icons-round icon-lg">menu</span>
    </button>

    <a class="navbar-brand navbar-logo" routerLink="/"><img alt="{{ appTitle }}" [src]="ImagePath" class="header-img" />
    </a>
    <ul class="navbar-nav m-auto d-none d-lg-flex" *ngIf="mainNav.length>0">
      <!-- <li class="nav-item" *ngFor="let navItem of mainNav">
        <a
          [routerLink]="navItem.route"
          routerLinkActive="active"
          class="nav-link"
          attr.data-label="{{ navItem.title }}"
        >
          <span class="nav-link-label">{{ navItem.title }}</span>
        </a>
      </li> -->
      <li class="nav-item" *ngFor="let navItem of mainNav">
        <ng-container *ngIf="!navItem.children; else dropdown">
          <a [routerLink]="navItem.route" routerLinkActive="active" class="nav-link"
            attr.data-label="{{ navItem.title }}">
            <span class="nav-link-label">{{ navItem.title }}</span>
          </a>
        </ng-container>
        <ng-template #dropdown>
          <div ngbDropdown class="nav-item me-0">
            <a class="nav-link" ngbDropdownToggle role="button" tabindex="0">
              <span class="nav-link-wrapper" attr.data-label="{{ navItem.title }}">
                <span class="nav-link-label">{{ navItem.title }}</span>
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end position-absolute">
              <a class="dropdown-item" *ngFor="let child of navItem.children" [routerLink]="child.route"
                [queryParams]="{ reportType: removeSpaces(child.title) }">
                {{child.title}}
              </a>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>

    <button type="button" name="toggleUserNavigation" aria-label="Toggle User Navigation"
      class="navbar-toggler d-lg-none m-0" (click)="openUserNav(offcanvasUserNav)">
      <span class="badge badge-username m-0">UN</span>
    </button>

    <ul class="navbar-nav me-0 d-none d-lg-block username ">
      <li ngbDropdown class="nav-item me-0">
        <a class="nav-link" ngbDropdownToggle role="button">
          <span class="badge badge-username">
            <span>
              {{ userInitials }}
              <!-- Display the first character of the user name -->
            </span>
          </span>
          <span class="nav-link-wrapper" data-label="User Name">
            <span *ngIf="fullname; else defaultName" class="nav-link-label">{{ fullname }}</span>
            <ng-template #defaultName>
              <span class="nav-link-label">User Name</span>
            </ng-template>
          </span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end position-absolute">
          <!-- <a class="dropdown-item" routerLink="./">Action</a>
        <a class="dropdown-item" routerLink="./">Another action</a> -->
          <!-- <div class="dropdown-divider"></div> -->
          <a class="dropdown-item" (click)="signOut()">Sign Out</a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<div>
  <app-breadcrumb></app-breadcrumb>
</div>

<!-- Offcanvas: Main Menu -->
<ng-template #offcanvasMainNav let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" id="offcanvas-main-nav">Navigation</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close"
      (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <div class="offcanvas-body m-0 p-0" *ngIf="mainNav.length>0">
    <div class="sidebar sidebar-dark">
      <ul class="nav flex-column">
        <li class="nav-item" *ngFor="let navItem of mainNav">
          <ng-container *ngIf="!navItem.children; else dropdown">
            <a [routerLink]="navItem.route" routerLinkActive="active" class="nav-link"
              attr.data-label="{{ navItem.title }}">
              <span class="nav-link-label">{{ navItem.title }}</span>
            </a>
          </ng-container>
          <ng-template #dropdown>
            <div ngbDropdown class="nav-item me-0">
              <a class="nav-link" ngbDropdownToggle role="button" tabindex="0">
                <span class="nav-link-wrapper" attr.data-label="{{ navItem.title }}">
                  <span class="nav-link-label">{{ navItem.title }}</span>
                </span>
              </a>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end position-absolute">
                <a class="dropdown-item" *ngFor="let child of navItem.children" [routerLink]="child.route"
                  [queryParams]="{ reportType: removeSpaces(child.title) }">
                  {{child.title}}
                </a>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<!-- Offcanvas: UserNav -->
<ng-template #offcanvasUserNav let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" id="offcanvas-user-nav">User Name</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close"
      (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <div class="offcanvas-body m-0 p-0">
    <div class="sidebar sidebar-dark">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a routerLink="./" class="nav-link d-flex justify-content-between align-items-center"
            (click)="offcanvas.dismiss('Cross click')">
            <span class="flex-fill">Action</span>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="./" class="nav-link d-flex justify-content-between align-items-center"
            (click)="offcanvas.dismiss('Cross click')">
            <span class="flex-fill">Another Action</span>
          </a>
        </li>
        <li>
          <hr class="px-4" />
        </li>
        <li class="nav-item">
          <a routerLink="./" class="nav-link d-flex justify-content-between align-items-center"
            (click)="offcanvas.dismiss('Cross click')">
            <span class="flex-fill">Sign Out</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>