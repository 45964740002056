import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, map, Observable, Subscription } from 'rxjs';
import { CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { AuthState } from '@okta/okta-auth-js';
import { TokenStorageService } from '../token-storage/token-storage.service';
@Injectable({
  providedIn: 'root'
})
export class OpenporeportService {
  private apiUrl = environment.backendApiUrl;
  private headers: HttpHeaders;
  email: string = "";
  public isAuthenticated$!: Observable<boolean>;
  authSubscription: Subscription | undefined;
  claims: { claim: string; value: CustomUserClaim | CustomUserClaim[]; }[] | undefined;



  constructor(private http: HttpClient,
    private oktaAuthStateService: OktaAuthStateService,
    private tokenStorage: TokenStorageService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    // const authToken = "eyJraWQiOiJCT1l3WXppcENoT2FBTG9OcUR2d2lHanhCZ25oVVNGVC0yWmdLNFJfNU0wIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULi1FSnRkLTBBckgtSlRSWF9BOWx4elotUnhlRXpyOUgwQkt1WVNXN2NycDQiLCJpc3MiOiJodHRwczovL2xvZ2luLWRldi5sYWJjb3JwLmNvbS9vYXV0aDIvZGVmYXVsdCIsImF1ZCI6ImFwaTovL2RlZmF1bHQiLCJpYXQiOjE3MjkwNTYxNDYsImV4cCI6MTcyOTE0MjU0NiwiY2lkIjoiMG9hMjRoMnlkZGdnOVR6VmYwaDgiLCJ1aWQiOiIwMHUyNmR5aXJ2NmZIelBtdzBoOCIsInNjcCI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiXSwiYXV0aF90aW1lIjoxNzI5MDU2MTQzLCJzdWIiOiJub21hbi5tb2hhbW1lZEBsYWJjb3JwLmNvbSIsIkVtYWlsIjoibm9tYW4ubW9oYW1tZWRAbGFiY29ycC5jb20iLCJsYXN0X25hbWUiOiJNb2hhbW1lZCIsImZpcnN0X25hbWUiOiJOb21hbiIsImFwcF9yb2xlIjoiU3VwZXJVc2VyIiwiZW1haWwiOiJub21hbi5tb2hhbW1lZEBsYWJjb3JwLmNvbSJ9.uNK8e5YbTGFHobcgMcv-pWpnSktjU3Ty3q35yQWIXmobwM9cKCNfr9un8hAFf0UigVZgRMzbB2tgjh_ODJMBSomcDBhle03Fv-EP0BfMFiafXzoHqYw3Cu7M03HZhjYYQka4gjay3nhXKbGwSITKUQx6eCgNXdjHYOAi_fDU0NnamozlqYsf719bh5u_E77gs3tXY1Dfb8BGvAa_YLdu89usSl_x-s2EbuKCUBP3vBaVFl9CJkJWLa6zjvu5hxqI5lGXpZhAerlLUoDtt1HV7x8w3VQNx1rR4Z1MZCpNdVKzDOqXk0RGpjGmGsxkryX4ISkD7KEIEMpyKoAPqzNnsQ"//this.tokenStorage.getToken();
    const authToken = this.tokenStorage.getToken();
    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
      console.log('User authentication status:', isAuthenticated);

      if (isAuthenticated) {
        const token = await this.oktaAuth.getAccessToken() || null;
        const userClaims = await this.oktaAuth.token.getUserInfo();
        this.claims = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
        this.email = userClaims['email'] || "";
        console.log(this.email)
      } else {
        // User is not authenticated, perform a different action
        console.log('User is not authenticated');
      }
    });

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    });
  }

  getReports(): Observable<any> {
    return this.http.put(this.apiUrl, {});
  }

  getReportFilterList(email: string): Observable<any> {
    console.log("service-email")
    //const url = `https://am4ztr28mj.execute-api.us-east-1.amazonaws.com/v1/auth/user/check`;
    const url = `${this.apiUrl}/auth/user/${email}`
    return this.http.get(url, { headers: this.headers });
  }
  getPOReports(siteCode: string, departmentCode: string): Observable<any> {
    const url = `${this.apiUrl}/purchaseorder/${siteCode}/${departmentCode}`
    return this.http.get(url, { headers: this.headers });
  }
}
