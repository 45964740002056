<div class="container-xxl mt-2 " style="height: 100%;">
    <div class="d-flex justify-content-between pb-5 align-items-center" style=" border-bottom: 1px solid #D3D2D2;">
        <div class="d-flex align-items-center gap-5">
            <h1 class="m-0">IOH Reports</h1>
            <!-- <select class="form-select" [(ngModel)]="siteName" (change)="getDepartmentsBySite()">
                <option value="" disabled  >Select
                   
                </option>
                <option *ngFor="let item of sites" [value]="item?.code"> {{item?.name}}</option>
            </select> -->

            <div class="col-5 me-2">
                <ng-select [items]="sites" bindLabel="name" bindValue="code" [loading]="sitesLoading"
                    (change)="getDepartmentsBySite($event)" placeholder="Select Site" (clear)="onClear()">
                </ng-select>
            </div>

            <div class="col-5 me-2">
                <select class="form-select" [(ngModel)]="departmentName" (change)="getParFileData()">
                    <option value="" disabled>Select Department</option>
                    <option *ngFor="let item of departments" [value]="item?.code"> {{item?.name}}</option>
                </select>
            </div>
        </div>
        <div>
            <button type="button" (click)="downloadCsv()" class="btn btn-secondary btn-sm">Download CSV file <span
                class="material-icons-round me-2">download</span></button>

            <!-- <app-select-dropdown [label]="'Division'" [placeholder]="'Select'"
            [id]="'division-dropdown'"></app-select-dropdown> -->
        </div>
    </div>
    <app-divider></app-divider>
    <div class="sites d-flex flex-column justify-content-center align-items-center">

        <div class="col-12">
            <app-spinner [isLoading]="isLoading"></app-spinner>
            <ng-container *ngIf="isSiteSelected; else noSiteSelected">
                <app-report-table *ngIf="!isLoading " [requisitionData]="reports" [siteName]="siteName"
                    [departmentName]="departmentName"></app-report-table>
            </ng-container>
            <ng-template #noSiteSelected>
                <div class="d-flex justify-content-center align-items-center" style="height: 50vh">
                    <h3>Please select a site and department to view the reports.</h3>
                </div>
            </ng-template>
        </div>
    </div>

</div>