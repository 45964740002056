<div>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <label [for]="id" class="form-label">{{
        fileUploadConstants.FILE_UPLOAD_TTILE
      }}</label>
      <ng-template #tooltipTemplate>{{ tooltipContent }}</ng-template>
      <span
        class="material-icons-round ms-2 fs-6"
        [ngbPopover]="tooltipTemplate"
        [placement]="'top'"
        [autoClose]="'outside'"
        triggers="hover"
        [style.cursor]="'default'"
      >
        info_outline
      </span>
    </div>
    <div>
      <a
        href="assets/images/Par-file-template.csv"
        download="PAR_Template.csv"
      >
        Download PAR Template
      </a>
    </div>
  </div>
  <div
    class="file-upload-container"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
  >
    <span class="material-icons-round">upload</span>
    <div class="file-upload-icon">
      <i class="bi bi-file-earmark-arrow-up"></i>
    </div>
    <div class="file-upload-text">
      <h4 *ngIf="!selectedFile; else fileNameDisplay">
        {{ fileUploadConstants.FILE_UPLOAD_HEADING }}
      </h4>
      <ng-template #fileNameDisplay>
        <h4>{{ selectedFile!.name }}</h4>
      </ng-template>
      <p>or</p>
      <!-- <p>{{ fileUploadConstants.FILE_UPLOAD_DESCRIPTION }}</p> -->
      <p>
        <app-button
          [label]="fileUploadConstants.FILE_UPLOAD_BUTTON_LABEL"
          [customClass]="'btn btn-secondary'"
          (click)="fileInput.click()"
        >
          <span class="material-icons-round">{{
            fileUploadConstants.FILE_UPLOAD_BUTTON_ICON
          }}</span>
        </app-button>
        <input
          #fileInput
          type="file"
          [attr.aria-label]="fileUploadConstants.FILE_UPLOAD_BUTTON_LABEL"
          (change)="onFileSelected($event)"
          [accept]="fileUploadConstants.FILE_UPLOAD_ACCEPT"
          style="display: none"
          [id]="id"
        />
      </p>
    </div>
  </div>

  <!-- Error messages -->
  <div
    class="invalid-feedback"
    id="textInputErrorMessage"
    role="alert"
    aria-live="polite"
    *ngIf="fileControl?.hasError('required') && fileControl?.touched"
  >
    <span class="material-icons-round icon-sm me-1">error_outline</span>
    {{ fileUploadConstants.FILE_UPLOAD_VALIDATION_MESSAGE }}
  </div>
  <div
    id="textInputErrorDiscription"
    class="form-text"
    *ngIf="fileControl?.hasError('invalidFileType') && fileControl?.touched"
  >
    {{ fileUploadConstants.INVALID_FILE_TYPE_MESSAGE }}
  </div>
  <div
    id="textInputErrorDiscription"
    class="form-text"
    *ngIf="fileControl?.hasError('invalidFileSize') && fileControl?.touched"
  >
    {{ fileUploadConstants.INVALID_FILE_SIZE_MESSAGE }}
  </div>
</div>
