import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  @Input() modalTitle!: string;
  @Input() modalContent!: string;
  @Output() closeModal = new EventEmitter<void>();
  @Output() confirmCancel = new EventEmitter<void>();

  isVisible = false;

  @Input()
set showModal(value: boolean) {
  this.isVisible = value;
  // this.toggleBodyClass(value);
}

  onCloseModal() {
    this.closeModal.emit();
  }

  onConfirmCancel() {
    this.confirmCancel.emit();
  }
}
