import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LabSupplyDepartment } from '../../model/lab-supply.model';
import { DataStorageService } from '../dataStorage/data-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  private departmentsSubject: BehaviorSubject<LabSupplyDepartment[]> = new BehaviorSubject<LabSupplyDepartment[]>([]);
  departments$: Observable<LabSupplyDepartment[]> = this.departmentsSubject.asObservable();

  constructor(private dataStorageService: DataStorageService) {}

  private updateDepartmentsSubject(departments: LabSupplyDepartment[]): void {
    this.departmentsSubject.next(departments);
  }

  setDepartments(departments: LabSupplyDepartment[]): void {
    this.departmentsSubject.next(departments);
    console.log('Department Data:', this.departmentsSubject.value);

  }

  getDepartments(): Observable<LabSupplyDepartment[]> {
    return this.departments$;
    console.log('Department Data:', this.departments$);
  }

  addDepartment(department: LabSupplyDepartment): void {
    const currentDepartments = this.departmentsSubject.value;
    const updatedDepartments = [...currentDepartments, department];
    this.updateDepartmentsSubject(updatedDepartments);
    console.log('Department Data:', this.departmentsSubject.value);
    console.log('Department Data:', this.departments$);

  }

  

  updateDepartment(updatedDepartment: LabSupplyDepartment): void {
    const currentDepartments = this.departmentsSubject.value;
    const updatedDepartments = currentDepartments.map(dept =>
      dept.departmentCode === updatedDepartment.departmentCode ? updatedDepartment : dept
    );
    this.updateDepartmentsSubject(updatedDepartments);
    console.log('Department Data:', this.departmentsSubject.value);
    console.log('updateDepartment:',updatedDepartment)
  }

   clearDepartments(): void {
    this.departmentsSubject.next([]);
  }

  clearDepartmentsSubject(): void {
    this.departmentsSubject.next([]);
  }

  updateDepartmentsStatus(status: string): void {
    const updatedDepartments = this.departmentsSubject.value.map(department => ({
      ...department,
      departmentStatus: status
    }));
    this.updateDepartmentsSubject(updatedDepartments);
  }

   initializeDepartmentStatus(status: string): void {
    const updatedDepartments = this.departmentsSubject.value.map(department => ({
      ...department,
      departmentStatus: status
    }));
    this.updateDepartmentsSubject(updatedDepartments);
  }


}