import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LabSupplyDepartment } from '../../model/lab-supply.model';

@Injectable({
  providedIn: 'root'
})
export class LabFormDataService {
  private formDataSource = new BehaviorSubject<LabSupplyDepartment | null>(null);
    formData$ = this.formDataSource.asObservable();

    setLabFormData(formData: LabSupplyDepartment) {
      this.formDataSource.next(formData);
    }
  
    clearLabFormData() {
      this.formDataSource.next(null);
    }
  
    updateLabFormData(data: LabSupplyDepartment){
      this.formDataSource.next(data);
    }
}
