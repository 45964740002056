<div class="mb-4">
  <label [for]="id" class="form-label d-flex align-items-center">
    {{ label }}
    <ng-template #tooltipTemplate>{{ tooltipContent }}</ng-template>
    <span
      class="material-icons-round ms-2 fs-6"
      [ngbPopover]="tooltipTemplate"
      [placement]="'top'"
      [autoClose]="'outside'"
      triggers="hover"
    >
      info_outline
    </span>
  </label>
  <select
    [formControl]="formControl"
    class="form-select"
    [id]="id"
    aria-label="Default Select Input"
    (change)="onValueChange($event)"
    [class.is-invalid]="formControl.invalid && formControl.touched"
  >
    <option value="" disabled [selected]="!formControl.value">
      {{ placeholder }}
    </option>
    <option *ngFor="let option of options" [ngValue]="option.value">
      {{ option.text }}
    </option>
  </select>
  <div
    *ngIf="formControl?.invalid && formControl?.touched"
    class="invalid-feedback d-flex flex-direction-column mt-2"
    role="alert"
    aria-live="polite"
  >
    <span class="material-icons-round icon-sm me-1">error_outline</span>
    <div *ngIf="formControl?.errors?.['required']">
      {{ validationMessages["required"] }}
    </div>
  </div>
</div>
