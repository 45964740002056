<div class="text-bg-navy py-4">
    <div class="container-xxl">
      <small
        >{{ appTitle }} v{{ appVersion }} ©{{ appYear }} Laboratory Corporation
        of America® Holdings. All Rights Reserved. Visit
        <a
          href="https://www.labcorp.com/"
          target="_blank"
          class="text-white"
          title="Labcorp.com (Opens in a new window)"
          ><u>labcorp.com</u></a
        >
        |
        <a
          href="https://www.labcorp.com/hipaa-privacy"
          target="_blank"
          class="text-white"
          title="Labcorp Privacy Statement (Opens in a new window)"
          ><u>Privacy Statement</u></a
        ></small
      >
    </div>
  </div>