import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalConstants } from '../../GlobalConstants';
import {
  NgbOffcanvas,
  OffcanvasDismissReasons,
  NgbDropdownModule
} from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgFor } from '@angular/common';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { filter, map, Observable, Subscription } from 'rxjs';
import { TokenStorageService } from '../../../../services/token-storage/token-storage.service';


@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrl: './layout-header.component.css',
})
export class LayoutHeaderComponent implements OnInit {
  appTitle = GlobalConstants.appInfo.header_appTitle;
  mainNav = GlobalConstants.mainNav;
  closeResult = '';
  isMenuCollapsed = true;
  public userName$!: Observable<string>;
  public isAuthenticated$!: Observable<boolean>;
  public token$!: Observable<string | null>;
  public userClaims$!: Observable<any>;
  userInitials!: string;
  private authSubscription: Subscription | null = null;
  claims: { claim: string; value: CustomUserClaim | CustomUserClaim[]; }[] | undefined;
  firstName: any;
  ImagePath: string;
  lastName: any;
  fullname: any;


  constructor(
    private offcanvasService: NgbOffcanvas,
    public router: Router,
    private oktaAuthStateService: OktaAuthStateService,
    private tokenStorage: TokenStorageService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this.ImagePath = "assets/images/logo-hsm-white.png";
  }
  ngOnInit(): void {
    this.userName$ = this.oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.name ?? '')
    );
    this.userName$.subscribe((name) => {
      console.log('User name:', name);
      this.userInitials = name ? name.charAt(0) + (name.split(' ')[1]?.charAt(0) || '') : '';
      this.userInitials = this.userInitials.split('').reverse().join('');
      console.log('User initials:', this.userInitials);
    });
    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    let userName = localStorage.getItem("Username");
    if (userName) {
      this.fullname = userName;
    }
    else
      this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
        console.log('User authentication status:', isAuthenticated);

        //if (isAuthenticated) {
        // User is authenticated, perform desired action
        console.log('User is authenticated');
        const token = await this.oktaAuth.getAccessToken() || null;
        console.log("okta token", token);
        const userClaims = await this.oktaAuth.token.getUserInfo();
        this.claims = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
        console.log("User Claims", this.claims)
        this.firstName = userClaims['first_name'] || null;
        this.lastName = userClaims['last_name'] || null;
        this.fullname = this.firstName + " " + this.lastName;
        localStorage.setItem("Username", this.fullname);
        console.log('Extracted first_name:', this.firstName);
        // } else {
        //   console.log('User is not authenticated');
        // }
      });
  }

  removeSpaces(title: string): string {
    return title.replace(/\s+/g, '');
  }

  openMainNav(offcanvasMainNav: any) {
    this.offcanvasService
      .open(offcanvasMainNav, {
        ariaLabelledBy: 'offcanvas-main-nav',
        position: 'start',
        panelClass: 'text-bg-navy',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  openUserNav(offcanvasUserNav: any) {
    this.offcanvasService
      .open(offcanvasUserNav, {
        ariaLabelledBy: 'offcanvas-UserNav',
        position: 'end',
        panelClass: 'text-bg-navy',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === OffcanvasDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on the backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public async signOut(): Promise<void> {
    localStorage.removeItem("isOktaLoggin");
    localStorage.removeItem("Username")
    await this.oktaAuth.signOut();
  }

}