<div class="container-xxl mt-2" style="height: 100%;">
    <h1 class="mb-4">{{reportTitle}}</h1>
    <form [formGroup]="parFileAudit" (ngSubmit)="onSubmit()" class="d-flex row mb-2">
        <div class="col-md-2">
            <app-ng-select-dropdown
                [siteOptions]="siteOptions"
                [label]="'Site'"
                [placeholder]="'Select'"
                [formControl]="site"
                [tooltipContent]="site_tooltip"
                [validationMessages]="validationMessages.site"
                [id]="'site-dropdown'"
                [loading]="sitesLoading"
                (clear)="onClear()"
                (change)="onSiteChange($event)">
            </app-ng-select-dropdown>
        </div>        
        <div class="col-md-2">
            <app-ng-select-dropdown
                [siteOptions]="departmentOptions"
                [label]="'Department'"
                [placeholder]="'Select'"
                [formControl]="department"
                [tooltipContent]="department_tooltip"
                [validationMessages]="validationMessages.department"
                [id]="'department-dropdown'"
                >
            </app-ng-select-dropdown>
        </div>
        <div class="col-md-4 mb-4" *ngIf="showDateRange()">
            <label class="form-label d-flex align-items-center">
                Date Range
                <span class="material-icons-round ms-2 fs-6"
                    [ngbPopover]="dateRange_tooltip"
                    [placement]="'top'"
                    [autoClose]="'outside'"
                    triggers="hover">
                    info_outline
                </span>
            </label>
            <div class="row">
                <div class="col-6">
                    <div class="dp-hidden position-absolute">
                        <div class="input-group">
                            <input
                                name="datepicker"
                                class="form-control"
                                ngbDatepicker
                                #datepicker="ngbDatepicker"
                                [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event)"
                                [displayMonths]="2"
                                [dayTemplate]="t"
                                outsideDays="hidden"
                                [startDate]="fromDate!"
                                tabindex="-1"
                               
                            />
                            <ng-template #t let-date let-focused="focused">
                                <span
                                    class="custom-day"
                                    [class.focused]="focused"
                                    [class.range]="isRange(date)"
                                    [class.faded]="isHovered(date) || isInside(date)"
                                    (mouseenter)="hoveredDate = date"
                                    (mouseleave)="hoveredDate = null"
                                >
                                    {{ date.day }}
                                </span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="input-group">
                         <!-- (input)="fromDate = validateInput(fromDate, dpFromDate.value)" -->
                        <input
                            #dpFromDate
                            class="form-control"
                            placeholder="From date"
                            name="dpFromDate"
                            formControlName="fromDateSubmit"
                            [value]="fromDate ? formatter.format(fromDate) : ''" 
                           
                            [class.is-invalid]="fromDateSubmit.invalid && (fromDateSubmit.dirty || fromDateSubmit.touched)"
                        />
                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button">
                            <span class="material-icons-round">
                                date_range
                            </span>
                        </button>
                        <div
                *ngIf="fromDateSubmit?.invalid && fromDateSubmit?.touched"
                class="invalid-feedback d-flex flex-direction-column mt-2"
                role="alert"
                aria-live="polite"
            >
                <span class="material-icons-round icon-sm me-1">error_outline</span>
                <div *ngIf="fromDateSubmit?.errors?.['required']">
                    From date is required
                </div>
            </div>
                    </div>
                </div>
                <div class="col-6">
                    <!--  (input)="toDate = validateInput(toDate, dpToDate.value)" -->
                    <div class="input-group">
                        <input
                            #dpToDate
                            class="form-control"
                            placeholder="To date"
                            name="dpToDate"
                            formControlName="toDateSubmit"
                            [value]="toDate ? formatter.format(toDate) : ''" 
                           
                            [class.is-invalid]="toDateSubmit.invalid && (toDateSubmit.dirty || toDateSubmit.touched)"
                        />
                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button">
                            <span class="material-icons-round">
                                date_range
                            </span>
                        </button>
                        <div
                        *ngIf="toDateSubmit?.invalid && toDateSubmit?.touched"
                        class="invalid-feedback d-block"
                        role="alert"
                        aria-live="polite"
                    >
                        <span class="material-icons-round icon-sm me-1">error_outline</span>
                        <span *ngIf="toDateSubmit?.errors?.['required']">
                            To date is required
                        </span>
                    </div>
                    </div>
                </div>
            </div>
            <div
            *ngIf="parFileAudit.errors?.['dateRangeExceeded'] || parFileAudit.errors?.['invalidDateRange']"
            class="invalid-feedback d-block mt-2"
            role="alert"
            aria-live="polite"
        >
            <span class="material-icons-round icon-sm me-1">error_outline</span>
            <span *ngIf="parFileAudit.errors?.['dateRangeExceeded']">
                Date range cannot exceed 90 days
            </span>
            <span *ngIf="parFileAudit.errors?.['invalidDateRange']">
                End date must be after start date
            </span>
        </div>
        </div>      
        <div class="col-md-2" *ngIf="showItemId()">
            <app-input-field formControlName="itemIdSubmit" [label]="'Item ID (Optional)'" [placeholder]="''"
            [formControl]="itemIdSubmit"
            [tooltipContent]="itemId_tooltip"
            [validationMessages]="validationMessages.itemId"
            [id]="'itemID-input'"></app-input-field>
        </div>
        <div class="col-md-2" *ngIf="showModifiedBy()">
            <app-input-field formControlName="modifiedBy" [label]="'Modified By (Optional)'" [placeholder]="''"
            [formControl]="modifiedBy"
            [tooltipContent]="modifiedBy_tooltip"
            [validationMessages]="validationMessages.modifiedBy"
            [id]="'itemID-input'"></app-input-field>
        </div>
    </form>
    <div class="d-flex justify-content-between align-items-center mb-1">
        <div>
            <ng-container *ngIf="isFormValid(); else disabledButton">
                <app-button 
                    [label]="'Run'"
                    [customClass]="'btn btn-primary'"
                    (click)="onSubmit()"
                ></app-button>
            </ng-container>
            <ng-template #disabledButton>
                <app-button
                    [label]="'Run'"
                    [customClass]="'btn btn-primary'"
                    [isDisabled]="true"
                ></app-button>
            </ng-template>
        </div>
        <div *ngIf="isVisible && isSiteSelected">
            <button 
                type="button" 
                (click)="downloadCsv()" 
                class="btn btn-secondary btn-sm"
            > <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
            *ngIf="isDownloading"></span>
                <span class="material-icons-round me-2">download</span>
                Download CSV file
            </button>
        </div>
    </div>
    <app-divider></app-divider>
    <app-spinner [isLoading]="isRouteChange"></app-spinner>
    <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="!isRouteChange">
        <div class="col-12 position-relative" style="min-height: 50vh;">
            <!-- Spinner overlay -->
            <div *ngIf="isLoading" class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center" style="background-color: rgba(255,255,255,0.8); z-index: 1000;">
                <app-spinner [isLoading]="isLoading"></app-spinner>
            </div>
            <!-- Content -->
            <ng-container *ngIf="!isLoading">
              <ng-container *ngIf="isSiteSelected && departmentName; else noSiteSelected">
                <app-audit-report-table 
                    [headers]="parFileAuditHeaders"
                    [rows]="parFileAuditRows"
                    [groupedItems]="groupedItems"
                    [isGrouped]="!nonGroupedReportTypes.includes(currentReportType)"
                    [siteName]="siteName"
                    [departmentName]="departmentName"
                    [reportType]="currentReportType">
                </app-audit-report-table>
            </ng-container>
            <ng-template #noSiteSelected>
                <div class="d-flex justify-content-center align-items-center" style="height: 50vh">
                    <ng-container [ngSwitch]="currentReportType">
                        <h3 *ngSwitchCase="'ItemExpirationReport'">
                            Please select a site and department to view the reports.
                        </h3>
                        <h3 *ngSwitchCase="'MOMReport'">
                            Please select a site and department to view the reports.
                        </h3>
                        <h3 *ngSwitchCase="'OOSReport'">
                            Please select a site and department to view the reports.
                        </h3>
                        <h3 *ngSwitchDefault>
                            Please select a site, department and Date Range to view the reports.
                        </h3>
                </ng-container>
                </div>
            </ng-template>
            </ng-container>
        </div>
    </div>
</div>