import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListSitesUtilityService {

  constructor() { }

  filterData(data: any[], searchText: string): any[] {
    if (!searchText || searchText.trim() === '') {
      return data;
    }

    const searchTextLower = searchText.toLowerCase();
    return data.filter(item =>
      item.code.toLowerCase().includes(searchTextLower) ||
      item.name.toLowerCase().includes(searchTextLower)
    );
  }

  sortData(data: any[], sortProperty: string, sortDirection: string): any[] {
    if (!sortProperty) {
      return data;
    }

    return [...data].sort((a, b) => {
      const valueA = this.getPropertyValue(a, sortProperty);
      const valueB = this.getPropertyValue(b, sortProperty);
      const res = this.compare(valueA, valueB);
      return sortDirection === 'asc' ? res : -res;
    });
  }

  getPropertyValue(item: any, property: string): any {
    if (property === 'id' || property === 'activeDeptCount' || property === 'countFrequency') {
      return item[property];
    } else {
      return (item[property] as string).toLowerCase();
    }
  }

  compare(v1: string | number, v2: string | number): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  paginateData(data: any[], currentPage: number, pageSize: number): any[] {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return data.slice(start, end);
  }
}