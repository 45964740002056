<div>
    <div class="table-responsive col-12">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th appSortable="purchaseOrderId" (sort)="onSort($event)">PO
                        @if(property ==="purchaseOrderId" ) {<app-sortdirection [property]="property"
                            [direction]="direction"></app-sortdirection>}

                    </th>
                    <th appSortable="purchaseOrderDate" (sort)="onSort($event)">PO Date
                        @if(property ==="purchaseOrderDate" ) {<app-sortdirection [property]="property"
                            [direction]="direction"></app-sortdirection>}
                    </th>
                    <th appSortable="poaStatus" (sort)="onSort($event)">PO Status @if(property
                        ==="poaStatus" ) {<app-sortdirection [property]="property"
                            [direction]="direction"></app-sortdirection>} </th>
                    <th appSortable="supplier" (sort)="onSort($event)">Vendor @if(property ==="supplier" )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection> }</th>
                    <th appSortable="status" (sort)="onSort($event)">Vendor Status @if(property ==="status"
                        )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="shipToLocation" (sort)="onSort($event)">Ship To Location @if(property
                        ==="shipToLocation" )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="description" (sort)="onSort($event)">Received Status @if(property ==="description"
                        )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="itemReceived" (sort)="onSort($event)">Items Received @if(property ==="itemReceived"
                        )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="itemQuantity" (sort)="onSort($event)">PO Item Count @if(property ==="itemQuantity"
                        )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th>Action </th>

                </tr>
            </thead>
            <tbody>
                <!-- @for (item of requisationByPagination;let i = index; track item;) { -->
                <ng-container *ngFor="let item of requisationByPagination; let i = index; track item;">
                    <tr>


                        <td>{{ item?.purchaseOrderId }}</td>
                        <td>{{ item?.purchaseOrderDate }}</td>

                        <td>{{ item?.status }}</td>
                        <td>{{ item?.supplier }}</td>
                        <td>{{ item?.poaStatus }}</td>
                        <td>{{ item?.shipToLocation }}</td>
                        <td>{{ item?.receivedStatus }}</td>
                        <td>{{ item?.itemsReceived }}</td>
                        <td>{{ item?.itemQuantity }}</td>
                        <td>
                            <button class="btn btn-sm" (click)="updateLineItemData(item,i)">

                                <span class="material-icons-round" *ngIf="!item.isExpand">
                                    add
                                </span>
                                <span class="material-icons-round" *ngIf="item.isExpand">
                                    remove
                                </span>
                            </button>
                        </td>
                    </tr>
                    <!-- } -->
                    <tr *ngIf="item.isExpand">
                        <td colspan="9">
                            <app-lineitem-table [lineItemData]="item?.items"></app-lineitem-table>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>


    </div>

    <div>
        <app-pagination [totalItems]="totalItems" [pageSize]="pageSize" [currentPage]="currentPage"
            (pageChanged)="onPageChanged($event)"></app-pagination>
    </div>
</div>