import { OktaAuth, isRedirectUri } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';

export const oktaConfig = {
  issuer: environment.oktaIssuer, 
  clientId: environment.oktaClientId,
  redirectUri: environment.oktaRedirectUri,
  postLogoutRedirectUri: environment.post_logout_redirect_uri,
  scopes: ['openid', 'profile', 'email'],
  pkce: true, 

};

export const oktaAuth = new OktaAuth(oktaConfig);
console.log('OktaAuth initialized:', oktaAuth);