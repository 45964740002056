import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { switchMap, catchError, map, take } from 'rxjs/operators';
import { SiteManagementService } from '../services/api/site-management.service';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, Subscription } from 'rxjs';
import { CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { AuthState } from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public oktaAuthStateService: OktaAuthStateService,
    private router: Router,
    private siteManagementService: SiteManagementService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuth();
  }
   private checkAuth(): Observable<boolean | UrlTree> {
  return this.oktaAuthStateService.authState$.pipe(
    filter((s: AuthState) => !!s),
    take(1),
    switchMap((s: AuthState) => {
      const isAuthenticated = s.isAuthenticated ?? false;
      console.log('User authentication status:', isAuthenticated);

      if (!isAuthenticated) {
        console.log('User is not authenticated');
        return of(this.router.createUrlTree(['']));
      }

      console.log('User is authenticated');
        return from(this.oktaAuth.tokenManager.getTokens()).pipe(
          switchMap(tokens => {
            const accessToken = tokens.accessToken;
            if (!accessToken) {
              console.error('No access token available');
              return of(this.router.createUrlTree(['']));
            }
            return from(this.oktaAuth.token.getUserInfo()).pipe(
              switchMap(userClaims => {
                const email = userClaims['email'];
                console.log("given_email", email);
                if (email && typeof email === 'string') {
                  return this.siteManagementService.getUserProfile(email).pipe(
                    map(profile => {
                      const role = profile.roleName;
                      const status = profile.status;
                      console.log('User role:', role);
                      console.log('User status:', status);

                      if (status === 'Inactive' || status === 'INACTIVE') {
                        console.log('User status is Inactive');
                        return this.router.createUrlTree(['/access-denied']);
                      }

                      switch (role) {
                        case 'SUPERUSER':
                        case 'DIRECTOR':
                        case 'SITEMANAGER':
                          return true;
                        case 'USER':
                        default:
                          console.log('User role not recognized or insufficient permissions');
                          return this.router.createUrlTree(['/access-denied']);
                      }
                    }),
                    catchError(error => {
                      console.error('Error fetching user profile:', error);
                      return of(this.router.createUrlTree(['/access-denied']));
                    })
                  );
                } else {
                  console.error('Email not found in user claims or is not a string');
                  return of(this.router.createUrlTree(['/access-denied']));
                }
              })
            );
          })
        );
      }),
      catchError(error => {
        console.error('Authentication error:', error);
        return of(this.router.createUrlTree(['']));
      })
    );
   }

}