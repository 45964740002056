<div>
    <div class="row row-cols-auto justify-content-center justify-content-md-end">
      <div class="col">
        <div class="d-flex align-items-center mb-4">
          <label
            for="goToPage"
            class="form-label small fw-normal text-nowrap mb-0 me-2"
            >Go to page</label
          >
          <select
            class="form-select form-select-sm"
            aria-label="Default Select Input"
            [(ngModel)]="currentPage"
            (change)="gotoPage(currentPage)"
          >
            <option *ngFor="let page of totalPages" [value]="page">
              {{ page }}
            </option>
          </select>
        </div>
      </div>
  
      <div class="col">
        <ngb-pagination
          class="mb-4"
          [collectionSize]="totalItems"
          [(page)]="currentPage"
          [pageSize]="pageSize"
          aria-label="Small pagination"
          [ellipses]="false"
          [boundaryLinks]="true"
          (pageChange)="gotoPage($event)"
        >
          <ng-template ngbPaginationFirst
            ><span class="material-icons-round icon-sm" aria-hidden="true"
              >first_page</span
            ></ng-template
          >
          <ng-template ngbPaginationPrevious
            ><span class="material-icons-round icon-sm" aria-hidden="true"
              >chevron_left</span
            ></ng-template
          >
          <ng-template ngbPaginationNext
            ><span class="material-icons-round icon-sm" aria-hidden="true"
              >chevron_right</span
            ></ng-template
          >
          <ng-template ngbPaginationLast
            ><span class="material-icons-round icon-sm" aria-hidden="true"
              >last_page</span
            ></ng-template
          >
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
  