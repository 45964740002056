import { Injectable, ComponentRef, ViewContainerRef } from '@angular/core';
import { ToastComponent } from '../../reusable/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastComponentRef: ComponentRef<ToastComponent> | null = null;

  constructor() { }

  showToast(
    viewContainerRef: ViewContainerRef,
    toastType: 'success' | 'danger',
    message?: string,
    customStyles?: boolean,
    duration?: number
  ) {
    this.hideToast();

    this.toastComponentRef = viewContainerRef.createComponent(ToastComponent);

    const toastComponent = this.toastComponentRef.instance;
    toastComponent.toastType = toastType;
    toastComponent.message = message;
    toastComponent.customStyles = customStyles || false;
    toastComponent.showToast = true;
    toastComponent.duration = duration || 3000;
    

    setTimeout(() => {
      this.hideToast();
    }, toastComponent.duration);
  }

  hideToast() {
    if (this.toastComponentRef) {
      this.toastComponentRef.destroy();
      this.toastComponentRef = null;
    }
  }
}
