<div #toastContainer></div>
<div class="row justify-content-center">
  <div class="col-10 mt-8">
    <div class="d-flex flex-direction-column justify-content-between">
      <h3>{{ labTitle }}</h3>
      <div *ngIf="departments.length > 0">
        <app-button [customClass]="'btn btn-secondary btn-sm'" [label]="'Add'" (click)="openModal()">
          <span class="material-icons-round">add</span>
        </app-button>
      </div>
    </div>

    <div class="lab-dept-container d-flex flex-column justify-content-center align-items-center"
      *ngIf="!isLoading && departments.length == 0">
      <div class="mt-10">
        <p>
          click <strong>Add Department</strong> to add a lab supply department
        </p>
      </div>
      <div class="mb-10">
        <app-button [customClass]="'btn btn-secondary'" [label]="'Add Department'" (click)="openModal()">
          <span class="material-icons-round">add</span>
        </app-button>
      </div>
    </div>
    <app-spinner [isLoading]="isLoading"></app-spinner>
    <div *ngIf="!isLoading && departments.length > 0; else noDepartments" class="lab-dept-container d-flex">
      <div class="dept-table mt-6 mb-6 col-12">
        <table class="table" summary="List of Lab Supply Departments">
          <thead>
            <tr>
              <th scope="col">Status</th>
              <th scope="col">Department Name</th>
              <th scope="col">Department Code</th>
              <!-- <th scope="col">shipToCode</th>
              <th scope="col">location</th> -->
              <th scope="col">Par File</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let department of departments">
              <td [ngStyle]="{
                  color: department?.status === 'ACTIVE' ? '#0d820d' : '#c5203c'
                }">
                {{ department?.status }}
              </td>
              <td>{{ department?.departmentName || "N/A" }}</td>
              <td>{{ department?.departmentCode || "N/A" }}</td>
              <!-- <td>{{ department?.shipToCode || "N/A" }}</td>
              <td>{{ department?.location || "N/A" }}</td> -->
              <td>
                <ng-container *ngIf="
                    parFileStatus[department.departmentCode];
                    else notUploaded
                  ">
                  <span class="material-icons" [ngStyle]="{ color: '#0D820D' }">
                    check
                  </span>
                </ng-container>
                <ng-template #notUploaded>
                  <span class="material-icons" [ngStyle]="{ color: '#0D820D' }">
                    upload_file
                  </span>
                </ng-template>
              </td>

              <td>
                <div class="d-inline-flex">
                  <a class="btn btn-link border-0 p-0 m-0" (click)="openEditModal(department)">
                    <span class="material-icons-round"> edit </span>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ng-template #noDepartments> </ng-template>
  </div>
</div>

<app-modal *ngIf="showModal" [modalTitle]="
    editDepartment ? 'Edit Lab Supply Department' : 'Add Lab Supply Department'
  " (closeModal)="handleCloseModal()" (confirmCancel)="handleConfirmCancel()">
  <div modal-footer>
    <app-button [customClass]="'btn btn-secondary me-2'" [label]="'Cancel'" (click)="handleCancel()"></app-button>
    <ng-container *ngIf="!editDepartment; else updateButton">
      <ng-container *ngIf="isFormValid(); else disabledSaveButton">
        <app-button [customClass]="'btn btn-primary'" [label]="'Save'" (click)="onSubmit()"
          [isLoading]="isLoading"></app-button>
      </ng-container>
      <ng-template #disabledSaveButton>
        <app-button [customClass]="'btn btn-primary disabled'" [label]="'Save'" [isDisabled]="true" [isLoading]="isLoading"></app-button>
      </ng-template>
    </ng-container>
    <ng-template #updateButton>
      <app-button [customClass]="'btn btn-primary'" [label]="'Update'" (click)="handleUpdate()" [isLoading]="isLoading"></app-button>
    </ng-template>
  </div>

  <form [formGroup]="labSetupForm" (ngSubmit)="onSubmit()">
    <div class="par-form">
      <div class="row justify-content-center" style="border-radius: 1rem">
        <div class="">
          <div class="row">
            <div class="col-lg-6 pe-2">
              <app-input-field formControlName="departmentCode" [label]="'Department Code'" [placeholder]="''"
                [formControl]="departmentCode" [validationPattern]="validationPatterns.departmentCode"
                [validationMessages]="validationMessages.departmentCode" [tooltipContent]="department_code_tooltip"
                [id]="'departmentCode-inputField'"></app-input-field>
            </div>
            <div class="col-lg-6 ps-2">
              <app-select-dropdown formControlName="status" [label]="'Status'" [options]="statusOptions"
                [placeholder]="'Select'" [formControl]="status" [tooltipContent]="department_status_tooltip"
                [validationMessages]="validationMessages.departmentStatus"
                [id]="'status-dropdown'"></app-select-dropdown>
            </div>
          </div>
          <app-input-field formControlName="departmentName" [label]="'Department Name'" [placeholder]="''"
            [formControl]="departmentName" [validationPattern]="validationPatterns.departmentName"
            [validationMessages]="validationMessages.departmentName" [tooltipContent]="department_name_tooltip"
            [id]="'departmentName-inputField'"></app-input-field>
          <div class="row">
            <div class="col-lg-6 pe-2">
              <app-input-field formControlName="shipToCode" [label]="'Ship to Code'" [placeholder]="''"
                [formControl]="shipToCode" [validationPattern]="validationPatterns.shipToCode"
                [validationMessages]="validationMessages.shipToCode" [tooltipContent]="ship_to_code_tooltip"
                [id]="'shipToCode-inputField'"></app-input-field>
            </div>
            <div class="col-lg-6 ps-2">
              <app-input-field formControlName="location" [label]="'Location'" [placeholder]="''"
                [formControl]="location" [validationPattern]="validationPatterns.location"
                [validationMessages]="validationMessages.location" [tooltipContent]="location_tooltip"
                [id]="'location-inputField'"></app-input-field>
            </div>
          </div>

          <div *ngIf="!fileUploaded; else successBox">
            <app-file-upload [form]="labSetupForm" controlName="parFile" [tooltipContent]="file_upload_tooltip"
              [id]="'par-file'" (fileUploadStatus)="onFileUploadStatus($event)"></app-file-upload>
            <div *ngIf="fileUploadError" class="text-danger">
              {{ fileUploadError }}
            </div>
          </div>

          <ng-template #successBox>
            <div class="success-box d-flex align-items-center justify-content-between">
              <span>{{ uploadedFileName }}</span>
              <span class="material-icons-round delete-icon" (click)="removeFileUpload()">delete</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </form>
</app-modal>