import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, map, Observable, Subscription } from 'rxjs';
import { CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { AuthState } from '@okta/okta-auth-js';
import { Event, NavigationStart, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage/token-storage.service';
import { SiteManagementService } from '../services/api/site-management.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  ImagePath: string;
  public name$!: Observable<string>;
  public isAuthenticated$!: Observable<boolean>;
  public groupInfo$!: Observable<CustomUserClaim | CustomUserClaim[]>;
  private authSubscription: Subscription | null = null;
  claims: { claim: string; value: CustomUserClaim | CustomUserClaim[]; }[] | undefined;
  username!: string
  isLoading: boolean = true;
  isAuthenticated: boolean = false;
  constructor(
    private router: Router,
    public oktaAuthStateService: OktaAuthStateService,
    private tokenStorage: TokenStorageService,
    private siteManagementService: SiteManagementService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this.ImagePath = "assets/images/logo-hsm-color.png";
    let isOktaLoggin = localStorage.getItem("isOktaLoggin");
    if (isOktaLoggin) {
      this.isAuthenticated = true;
    }
  }


  async ngOnInit() {
    let isOktaLoggin = localStorage.getItem("isOktaLoggin");
    if (isOktaLoggin) {
      this.isAuthenticated = true;
    }
    else {
      this.isAuthenticated = await this.oktaAuth.isAuthenticated();
      if (this.isAuthenticated)
        localStorage.setItem("isOktaLoggin", "TRUE");
    }
    console.log("login called")
    this.name$ = this.oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.name ?? '')
    );

    this.groupInfo$ = this.oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims['groupinfo'] ?? [])
    );
    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );

    this.name$.subscribe((name) => {
      console.log('User name:', name);
    });
    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    )
    console.log("login called-1")




    if (this.isAuthenticated) {
      console.log('User is authenticated');
      try {
        const token = await this.oktaAuth.getAccessToken();
        if (!token) {
          throw new Error('No access token available');
        }

        const userClaims = await this.oktaAuth.token.getUserInfo();
        this.claims = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
        console.log("User Claims", this.claims);
        const email = userClaims['email'];

        // Store token and user claims
        this.tokenStorage.setToken(token);
        this.tokenStorage.setUserClaims(userClaims);
        console.log("given_email", email);
        if (email && typeof email === 'string') {
          this.username = email;
          console.log("given_email", this.username);
          this.isLoading = true;
            this.siteManagementService.getUserProfile(email).subscribe({
            next: (profile) => {
              sessionStorage.setItem("UserProfile", JSON.stringify(profile))
              console.log(profile);
              const role = profile.roleName;
              const status = profile.status;
              if (status === 'Inactive' || status === 'INACTIVE') {
                this.router.navigate(['/access-denied']);
                return;
              }
              console.log(role);
              this.tokenStorage.setRole(role);

              // Route based on role
              switch (role) {
                case 'SUPERUSER':
                case 'DIRECTOR':
                case 'SITEMANAGER':
                  this.router.navigate(['/sites']);
                  break;
                case 'USER':
                default:
                  this.router.navigate(['/access-denied']);
                  console.log('User role not recognized or insufficient permissions');
                  break;
              }
            },
            error: (error) => {
              this.isLoading = false;
              console.error('Error fetching user profile:', error);
              this.router.navigate(['/access-denied']);
            },
            complete: () => {
              this.isLoading = false;
            }
          });
        } else {
          console.error('Email not found in user claims or is not a string');
          this.router.navigate(['/access-denied']);
        }
      } catch (error) {
        this.isLoading = false
        console.error('Error during authentication process:', error);
        this.tokenStorage.clearToken();
        this.tokenStorage.clearUserData();
        this.router.navigate(['/access-denied']);
      }
    } else {
      this.isLoading = false;
      console.log('User is not authenticated');
      this.tokenStorage.clearToken();
      this.tokenStorage.clearUserData();
      this.router.navigate(['']);
    }

  }
  //   ngOnInit(): void {
  //     console.log("login called")
  //     this.name$ = this.oktaAuthStateService.authState$.pipe(
  //       filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
  //       map((authState: AuthState) => authState.idToken?.claims.name ?? '')
  //     );

  //     this.groupInfo$ = this.oktaAuthStateService.authState$.pipe(
  //       filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
  //       map((authState: AuthState) => authState.idToken?.claims['groupinfo'] ?? [])
  //     );
  //     this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
  //       filter((s: AuthState) => !!s),
  //       map((s: AuthState) => s.isAuthenticated ?? false)
  //     );
  //     this.name$.subscribe((name) => {
  //       console.log('User name:', name);
  //     });

  //     console.log("login called-1")
  //     this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
  //       console.log('User authentication status:', isAuthenticated);

  //       if (isAuthenticated) {
  //         console.log('User is authenticated');
  //         try {
  //           const token = await this.oktaAuth.getAccessToken();
  //           if (!token) {
  //             throw new Error('No access token available');
  //           }

  //           const userClaims = await this.oktaAuth.token.getUserInfo();
  //           this.claims = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
  //           console.log("User Claims", this.claims);
  //           const email = userClaims['email'];

  //           // Store token and user claims
  //           this.tokenStorage.setToken(token);
  //           this.tokenStorage.setUserClaims(userClaims);
  //           console.log("given_email", email);
  //           if (email && typeof email === 'string') {
  //             this.username = email;
  //             console.log("given_email", this.username);
  //             this.isLoading = true;
  //             this.siteManagementService.getUserProfile(email).subscribe({
  //               next: (profile) => {
  //                 console.log(profile);
  //                 const role = profile.roleName;
  //                 const status = profile.status;
  //                 if (status === 'Inactive' || status === 'INACTIVE') {
  //                   this.router.navigate(['/access-denied']);
  //                   return;
  //                 }
  //                 console.log(role);
  //                 this.tokenStorage.setRole(role);

  //                 // Route based on role
  //                 switch(role) {
  //                   case 'SUPERUSER':
  //                   case 'DIRECTOR':
  //                   case 'SITEMANAGER':
  //                     this.router.navigate(['/sites']);
  //                     break;
  //                   case 'USER':
  //                   default:
  //                     this.router.navigate(['/access-denied']);
  //                     console.log('User role not recognized or insufficient permissions');
  //                     break;
  //                 }
  //               },
  //               error: (error) => {
  //                 this.isLoading=false;
  //                 console.error('Error fetching user profile:', error);
  //                 this.router.navigate(['/access-denied']);
  //               },
  //               complete: () => {
  //                 this.isLoading = false;
  //               }
  //             });
  //           } else {
  //             console.error('Email not found in user claims or is not a string');
  //             this.router.navigate(['/access-denied']);
  //           }
  //         } catch (error) {
  //           this.isLoading=false
  //           console.error('Error during authentication process:', error);
  //           this.tokenStorage.clearToken();
  //           this.tokenStorage.clearUserData();
  //           this.router.navigate(['/access-denied']);
  //         }
  //       } else {
  //         this.isLoading=false;
  //         console.log('User is not authenticated');
  //         this.tokenStorage.clearToken();
  //         this.tokenStorage.clearUserData();
  //         this.router.navigate(['']);
  //       }
  //     });
  // }

  ngOnDestroy() {
    // Unsubscribe from the observable when the component is destroyed
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
  async loginWithOkta(): Promise<void> {
    try {
      // Redirects the user to the Okta login page
      await this.oktaAuth.signInWithRedirect();
      console.log('Okta login redirection initiated');
    } catch (err) {
      console.error('Error during Okta login:', err);
    }
  }
}
