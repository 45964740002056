<!-- Updated Sites Component HTML -->
<div class="container-xxl mt-2" style="height: 100%">
  <div class="d-flex justify-content-between align-items-center header-container">
    <div class="d-flex align-items-center">
      <h1>Sites</h1>
      <ng-container *ngIf="hasSites">
        <app-search icon="search" placeholder="Search here" label="Search Prepend Icon"
          inputId="searchPrependIconNormal" [(value)]="searchText"
          (valueChange)="onSearchTextChange($event)"></app-search>
      </ng-container>
    </div>
    <ng-container *ngIf="hasSites && canCreateSite()">
      <app-button (click)="navigateToCreateSite()" [label]="'Create Site'"
        [customClass]="'btn btn-primary'"></app-button>
    </ng-container>
  </div>
  <app-divider></app-divider>
  <div class="sites d-flex flex-column align-items-center" [ngClass]="{ 'justify-content-center': !hasSites }"
    style="height: 100%">
    <ng-container *ngIf="hasSites; else noSitesTemplate">
      <app-spinner [isLoading]="loading"></app-spinner>
      <div class="col-12" *ngIf="!loading">
          <!-- Pass sort properties and handle sort event -->
          <app-site-table [sites]="filteredSites" [sortProperty]="sortProperty" [sortDirection]="sortDirection"
            (sortChange)="onSortChange($event)"></app-site-table>
        </div>
    </ng-container>

    <ng-template #noSitesTemplate>
      <app-spinner [isLoading]="loading"></app-spinner>
      <div class="text-center">
        <span>There are no sites. Click 'Create Site' to add sites</span>
      </div>
      <div class="center-button mt-4" *ngIf="canCreateSite()">
        <app-button (click)="navigateToCreateSite()" [label]="'Create Site'"
          [customClass]="'btn btn-primary'"></app-button>
      </div>
    </ng-template>
  </div>

</div>