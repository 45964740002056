<div #toastContainer></div>
<div class="container-xxl mt-2" style="height: 100%">
  <div
    class="d-flex justify-content-between align-items-center header-container"
  >
    <div class="d-flex align-items-center">
      <div class="col-6">
        <h1>User Management</h1>
      </div>
      <div class="col-3 me-2">
        <ng-select
          [items]="availableSites"
          bindLabel="code"
          bindValue="code"
          [loading]="sitesLoading"
          (change)="onSiteSelected($event)"
          placeholder="Select Site"
        ></ng-select>
      </div>
      <div class="search-bar-spacing flex-grow-1">
        <app-search
          icon="search"
          placeholder="Search First, Last or Username"
          label="Search Prepend Icon"
          inputId="searchPrependIconNormal"
          [(value)]="searchText"
          (valueChange)="onSearchTextChange($event)"
        ></app-search>
      </div>
    </div>
    <ng-container>
      <app-button
        [label]="'Add User'"
        [customClass]="'btn btn-primary'"
        (click)="openAddUserModal()"
      ></app-button>
    </ng-container>
  </div>
  <app-divider></app-divider>
  <app-spinner [isLoading]="isLoading"></app-spinner>
  <ng-container *ngIf="isSiteSelected; else noSiteSelected">
    <div *ngIf="!isLoading">
      <app-user-management-table
        [users]="filteredUsers"
        [sortProperty]="sortProperty"
        [sortDirection]="sortDirection"
        (sortChange)="onSortChange($event)"
        (userClicked)="handleUserClicked($event)"
      ></app-user-management-table>
    </div>
  </ng-container>
  <ng-template #noSiteSelected>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 50vh"
    >
      <h3>Please select a site to view the user list.</h3>
    </div>
  </ng-template>
</div>
<app-modal
  *ngIf="showModal"
  [modalTitle]="isEditMode ? 'Update User' : 'Add User'"
  (closeModal)="handleCloseModal()"
  (confirmCancel)="handleConfirmCancel()"
>
<div modal-footer>
  <app-button
    [customClass]="'btn btn-secondary me-2'"
    [label]="'Cancel'"
    (click)="handleCancel()"
    [isDisabled]="isLoading"
  ></app-button>

  <ng-container *ngIf="!isEditMode; else updateButtonContainer">
    <ng-container
      *ngIf="isFormValid() && !isLoading; else disabledSaveButton"
    >
      <app-button
        [customClass]="'btn btn-primary'"
        [label]="'Save'"
        (click)="onSubmit()"
        [isDisabled]="isLoading"
        [isLoading]="isLoading"
      ></app-button>
    </ng-container>
    <ng-template #disabledSaveButton>
      <app-button
        [customClass]="'btn btn-primary disabled'"
        [label]="'Save'"
        [isDisabled]="true"
        [isLoading]="isLoading"
      ></app-button>
    </ng-template>
  </ng-container>

  <ng-template #updateButtonContainer>
    <ng-container
      *ngIf="isUpdateFormValid() && !isLoading; else disabledUpdateButton"
    >
      <app-button
        [customClass]="'btn btn-primary'"
        [label]="'Update'"
        (click)="handleUpdate()"
        [isDisabled]="isLoading"
        [isLoading]="isLoading"
      ></app-button>
    </ng-container>
    <ng-template #disabledUpdateButton>
      <app-button
        [customClass]="'btn btn-primary disabled'"
        [label]="'Update'"
        [isDisabled]="true"
        [isLoading]="isLoading"
      ></app-button>
    </ng-template>
  </ng-template>
</div>

  <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="row justify-content-center">
      <div class="user-form col-12">
        <app-input-field
          formControlName="email"
          [label]="'Email'"
          [placeholder]="''"
          [formControl]="email"
          [validationMessages]="validationMessages.email"
          [tooltipContent]="email_tooltip"
          [id]="'email-inputField'"
        ></app-input-field>
        <div class="row">
          <div class="col-lg-6 pe-2">
            <app-select-dropdown
              formControlName="roleName"
              [label]="'Role'"
              [options]="dynamicRoleOptions"
              [placeholder]="'Select'"
              [formControl]="roleName"
              [tooltipContent]="role_tooltip"
              [validationMessages]="validationMessages.role"
              [id]="'role-dropdown'"
            ></app-select-dropdown>
          </div>
          <div class="col-lg-6 ps-2">
            <app-select-dropdown
              formControlName="division"
              [label]="'Division'"
              [options]="currentDivisionOptions"
              [placeholder]="getPlaceholder('division')"
              [formControl]="division"
              [tooltipContent]="division_tooltip"
              [validationMessages]="validationMessages.division"
              [id]="'division-dropdown'"
            ></app-select-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 pe-2">
            <app-input-field
              formControlName="firstName"
              [label]="'First Name'"
              [placeholder]="''"
              [formControl]="firstName"
              [validationMessages]="validationMessages.firstName"
              [tooltipContent]="firstName_tooltip"
              [id]="'firstName-inputField'"
            ></app-input-field>
          </div>
          <div class="col-lg-6 ps-2">
            <app-input-field
              formControlName="lastName"
              [label]="'Last Name'"
              [placeholder]="''"
              [formControl]="lastName"
              [validationMessages]="validationMessages.lastName"
              [tooltipContent]="lastName_tooltip"
              [id]="'lastName-inputField'"
            ></app-input-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 pe-2">
            <app-multi-select-dropdown
              formControlName="site"
              [label]="'Site(s)'"
              [options]="siteOptions"
              [placeholder]="getPlaceholder('site')"
              [formControl]="site"
              [validationMessages]="validationMessages.site"
              [tooltipContent]="sites_tooltip"
              [id]="'site-dropdown'"
            ></app-multi-select-dropdown>
          </div>
          <div class="col-lg-6 ps-2">
            <app-multi-select-dropdown
              formControlName="department"
              [label]="'Department(s)'"
              [options]="departmentOptions"
              [placeholder]="getPlaceholder('department')"
              [formControl]="department"
              [validationMessages]="validationMessages.department"
              [id]="'department-dropdown'"
              [tooltipContent]="departments_tooltip"
              [groupBy]="'group'"
            ></app-multi-select-dropdown>
          </div>
        </div>
        <!-- <div>
          <span class="text-primary cursor-pointer mt-2">
            + Add Site/Dept
          </span>
        </div> -->

        <!-- <app-select-dropdown
              formControlName="site"
              [label]="'Site(s)'"
              [options]="siteOptions"
              [placeholder]="'Select Site'"
              [formControl]="site"
              [id]="'site-dropdown'"
            ></app-select-dropdown> -->

        <!-- <app-select-dropdown
              formControlName="department"
              [label]="'Department(s)'"
              [options]="departmentOptions"
              [placeholder]="'Select Department(s)'"
              [formControl]="department"
              [validationMessages]="validationMessages.department"
              [id]="'department-dropdown'"
            ></app-select-dropdown> -->

        <app-select-dropdown
          formControlName="status"
          [label]="'Status'"
          [options]="statusOptions"
          [placeholder]="'Select Status'"
          [formControl]="status"
          [id]="'status-dropdown'"
          [tooltipContent]="status_tooltip"
          [validationMessages]="validationMessages.status"
        ></app-select-dropdown>
      </div>
    </div>
  </form>
</app-modal>