import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl, SafeStyle, SafeResourceUrl} from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SanitizationService {

  constructor(private sanitizer: DomSanitizer) {}

  // sanitizeHtml(value: string): SafeHtml {
  //   return this.sanitizer.bypassSecurityTrustHtml(value);
  // }
  sanitizeHtml(value: string): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, value) || '';
  }

  // sanitizeUrl(value: string): SafeUrl {
  //   return this.sanitizer.bypassSecurityTrustUrl(value);
  // }
  sanitizeUrl(value: string): SafeUrl {
    return this.sanitizer.sanitize(SecurityContext.URL, value) || '';
  }

  // sanitizeStyle(value: string): SafeStyle {
  //   return this.sanitizer.bypassSecurityTrustStyle(value);
  // }
  sanitizeStyle(value: string): SafeStyle {
    return this.sanitizer.sanitize(SecurityContext.STYLE, value) || '';
  }

  sanitizeResourceUrl(value: string): SafeResourceUrl {
    return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, value) || '';
  }

  sanitizeInput(value: string): string {
    const div = document.createElement('div');
    div.textContent = value;
    return div.innerHTML;
  }

  sanitizeAlphanumeric(value: string): string {
    if (!value) {
      return '';
    }
  
    let sanitizedValue = '';
    const regex = /^[a-zA-Z0-9]$/;
  
    for (let char of value) {
      if (regex.test(char)) {
        sanitizedValue += char;
      }
    }
  
    return sanitizedValue;
  }
  sanitizeSiteDepartmentName(value: string): string {
    if (!value) {
      return '';
    }
  
    let sanitizedValue = '';
    const regex = /^(?=.{1,32}$)(?!.*\s{2})(?![\W_])[\w\s.,()&\\-]{1,32}(?<![\W_])$/;
  
    if (regex.test(value)) {
      sanitizedValue = value;
    } else {
      for (let char of value) {
        if (/[\w\s.,()&\\-]/.test(char)) {
          sanitizedValue += char;
        }
      }
      sanitizedValue = sanitizedValue.slice(0, 32);
    }
  
    return sanitizedValue.trim();
  }
  sanitizeNumber(value: string): string {
    return value.replace(/[^0-9]/g, '');
  }
}