import { Injectable } from '@angular/core';
import { FormOptionsService } from '../form-options/form-options.service';

@Injectable({
  providedIn: 'root'
})
export class OptionTextService {

  constructor(
    private formOptionsService: FormOptionsService
  ) { }

  getTextFromValue(value: string, options: { value: string, text: string }[]): string {
    if (!value) {
      return '';
    }

    // Extract the actual value without the prefix
    const cleanedValue = value.split(':').pop()?.trim() || value;
    const option = options.find(opt => opt.text === cleanedValue);

    return option ? option.text : cleanedValue;
  }

  getDivisionText(value: string): string {
    return this.getTextFromValue(value, this.formOptionsService.divisionOptions);
  }

  

  getStatusText(value: string): string {
    return this.getTextFromValue(value, this.formOptionsService.statusOptions);
  }

  getRecountFrequencyText(value: string): string {
    return this.getTextFromValue(value, this.formOptionsService.recountFrequency);
  }
}
