<div class="mb-4">
  <label [for]="id" class="form-label d-flex align-items-center">
    {{ label }}
    <ng-template #tooltipTemplate>{{ tooltipContent }}</ng-template>
    <span
      class="material-icons-round ms-2 fs-6"
      [ngbPopover]="tooltipTemplate"
      [placement]="'top'"
      [autoClose]="'outside'"
      triggers="hover"
    >
      info_outline
    </span>
  </label>

  <ng-select
    [formControl]="formControl"
    [items]="options"
    bindLabel="text"
    bindValue="value"
    [multiple]="true"
    [placeholder]="placeholder"
    [id]="id"
    [virtualScroll]="true"
    [groupBy]="groupBy ? groupBy : ''"
    [selectableGroup]="!!groupBy"
    [selectableGroupAsModel]="!groupBy"
    [closeOnSelect]="false"
    [class.is-invalid]="formControl.invalid && formControl.touched"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div class="form-check">
        <input
          id="item-{{ index }}"
          type="checkbox"
          class="form-check-input"
          [checked]="formControl.value?.includes(item.value)"
          (change)="onCheckboxChange(item.value, $event)"
          [disabled]="item.disabled"
        />
        <label class="form-check-label" [for]="'item-' + index">
          {{ item.text }}
        </label>
      </div>
    </ng-template>
  </ng-select>

  <div
    *ngIf="formControl?.invalid && formControl?.touched"
    class="invalid-feedback d-flex flex-direction-column mt-2"
    role="alert"
    aria-live="polite"
  >
    <span class="material-icons-round icon-sm me-1">error_outline</span>
    <div *ngIf="formControl?.errors?.['required']">
      {{ validationMessages["required"] }}
    </div>
  </div>
</div>
