<div class="search-container">
  <div class="input-group">
    <div class="input-with-icon">
      <span class="icon" aria-hidden="true">
        <span class="material-icons-round">{{ icon }}</span>
      </span>
      <input
        type="text"
        class="form-control"
        [id]="inputId"
        [placeholder]="placeholder"
        [attr.aria-label]="label"
        [(ngModel)]="value"
        (input)="onInputChange($event)"
      />
    </div>
  </div>
</div>