<div>
    <div class="table-responsive col-12">
        <table class="table table-striped table-hover">
            <thead>
                <tr>

                    <th appSortable="status" (sort)="onSort($event)">Status @if(property ==="status" )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                  
                    <th>Site ID </th>
                    <th>Department Code </th>
                    <th appSortable="itemId" (sort)="onSort($event)">Item ID @if(property ==="itemId" )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="description" (sort)="onSort($event)">Description @if(property ==="description" )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="lotTracked" (sort)="onSort($event)">Lot tracked @if(property ==="lotTracked" )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="parQuantity" (sort)="onSort($event)">Optimal Qty @if(property ==="parQuantity" )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="reorderQuantity" (sort)="onSort($event)">Ordering Qty @if(property
                        ==="reorderQuantity"
                        ) {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="uom" (sort)="onSort($event)">UOM @if(property ==="uom"
                        ) {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="modified" (sort)="onSort($event)">Updated Date @if(property ==="modified"
                        ) {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="quantity" (sort)="onSort($event)">IOH Qty @if(property ==="quantity" )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                    <th appSortable="deltaIOH" (sort)="onSort($event)">Delta IOH @if(property ==="deltaIOH" )
                        {<app-sortdirection [property]="property" [direction]="direction"></app-sortdirection>}</th>
                </tr>
            </thead>
            <tbody>
                @for (item of requisationByPagination; track item) {
                <tr>

                    <td [ngStyle]="{
                      color: item?.status === 'active' ? '#0d820d' : '#c5203c'
                    }">
                        {{ item?.status?.toUpperCase() }}
                    </td>
                    <td>{{ siteName }}</td>
                    <td>{{ departmentName }}</td>
                    <td>{{ item?.itemId }}</td>
                    <td>{{ item?.description }}</td>
                    <td>{{ item?.lotTracked }}</td>
                    <td>{{ item?.parQuantity }}</td>
                    <td>{{ item?.reorderQuantity }}</td>
                    <td>{{ item?.uom }}</td>
                    <td>{{ item?.modified }}</td>
                    <td>{{ item?.quantity }}</td>
                    <td>{{ item?.deltaIOH }}</td>
                </tr>
                }

            </tbody>
        </table>


    </div>

    <div>
        <app-pagination [totalItems]="totalItems" [pageSize]="pageSize" [currentPage]="currentPage"
            (pageChanged)="onPageChanged($event)"></app-pagination>
    </div>
</div>