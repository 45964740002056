<div class="access-denied-container">
    <div class="access-denied-content">
      <h1>Access Denied</h1>
  
      <div class="alert alert-danger" role="alert">
        <span class="alert-icon material-icons-round">warning</span>
        <div class="alert-content">{{ message }}</div>
      </div>
  
      <div class="button-container">
        <app-button
        [label]="'Go Back'"
        [customClass]="'btn btn-secondary'"
        (click)="signOut()"
      >
      </app-button>
      </div>
    </div>
  </div>
  