<div class="table-responsive col-12">
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <!-- <th>
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="tableCheckbox"
            />
            <label class="form-check-label" for="tableCheckbox"
              ><span class="visually-hidden">Select all users</span></label
            >
          </div>
        </th> -->
        <!-- <th appSortable="userType" (sort)="onSort($event)">
          Type
          <app-sortdirection
            *ngIf="property === 'userType'"
            [property]="property"
            [direction]="direction"
          ></app-sortdirection>
        </th> -->
        <th>
          Type
        </th>
        <th appSortable="status" (sort)="onSort($event)">
          Status
          <app-sortdirection
            *ngIf="property === 'status'"
            [property]="property"
            [direction]="direction"
          ></app-sortdirection>
        </th>
        <!-- <th appSortable="username" (sort)="onSort($event)">
          Username
          <app-sortdirection
            *ngIf="property === 'username'"
            [property]="property"
            [direction]="direction"
          ></app-sortdirection>
        </th> -->
        <th appSortable="firstName" (sort)="onSort($event)">
          First Name
          <app-sortdirection
            *ngIf="property === 'firstName'"
            [property]="property"
            [direction]="direction"
          ></app-sortdirection>
        </th>
        <th appSortable="lastName" (sort)="onSort($event)">
          Last Name
          <app-sortdirection
            *ngIf="property === 'lastName'"
            [property]="property"
            [direction]="direction"
          ></app-sortdirection>
        </th>
        <th appSortable="email" (sort)="onSort($event)">
          Username
          <app-sortdirection
            *ngIf="property === 'email'"
            [property]="property"
            [direction]="direction"
          ></app-sortdirection>
        </th>
        <th appSortable="division" (sort)="onSort($event)">
          Division
          <app-sortdirection
            *ngIf="property === 'division'"
            [property]="property"
            [direction]="direction"
          ></app-sortdirection>
        </th>
        <th appSortable="roleName" (sort)="onSort($event)">
          Role
          <app-sortdirection
            *ngIf="property === 'roleName'"
            [property]="property"
            [direction]="direction"
          ></app-sortdirection>
        </th>
        <th appSortable="siteAccess" (sort)="onSort($event)">
          Site/Department Access
          <app-sortdirection
            *ngIf="property === 'siteAccess'"
            [property]="property"
            [direction]="direction"
          ></app-sortdirection>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of usersForPagination; let i = index">
        <!-- <td>
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              [id]="'user-checkbox-' + i"
              [(ngModel)]="user.selected"
            />
            <label
              class="form-check-label"
              [for]="'user-checkbox-' + i"
            ></label>
          </div>
        </td> -->
        <td>
          <ng-container *ngIf="user?.userType === 'internal'">
            <ng-container *ngIf="user?.status === 'ACTIVE'">
              <span class="material-icons-round" style="color: #0d820d"
                >person</span
              >
            </ng-container>
            <ng-container *ngIf="user?.status !== 'ACTIVE'">
              <span class="material-icons-round" style="color: #c5203c"
                >person_off</span
              >
            </ng-container>
          </ng-container>
          <!-- {{ user?.userType }} -->
        </td>
        <td
          [ngStyle]="{
            color: user?.status === 'ACTIVE' ? '#0d820d' : '#c5203c'
          }"
        >
          {{ user?.status || "N/A" }}
        </td>
        <!-- <td>{{ user?.username }}</td> -->
        <td>{{ user?.firstName || "N/A" }}</td>
        <td>{{ user?.lastName || "N/A" }}</td>
        <td class="edit cursor-pointer" (click)="onUserClicked(user)">
          {{ user?.email || "N/A" }}
        </td>
        <!-- <td>{{ user?.email || "N/A" }}</td> -->
        <td>
          {{
            user?.roleName === "SUPERUSER"
              ? "ALL"
              : user?.siteAccess?.division || user?.division || "N/A"
          }}
        </td>
        <td>{{ user?.roleName || "N/A" }}</td>
        <td>
          <ng-container
            *ngIf="
              user?.roleName === 'SUPERUSER' || user?.roleName === 'DIRECTOR'
            "
          >
            ALL
          </ng-container>

          <ng-container *ngIf="user?.roleName === 'USER'">
            <ng-container
              *ngIf="
                user.siteAccess && user.siteAccess.sites;
                else noSiteAccess
              "
            >
              <ul class="list-unstyled mb-0">
                <li *ngFor="let site of user.siteAccess.sites">
                  <div>
                    <strong>{{ site?.code || "N/A"}}</strong>
                    <ng-container
                      *ngIf="site?.departments?.length; else noDepartments"
                    >
                      <ul class="list-unstyled mb-0 ms-3">
                        <li *ngFor="let department of site.departments">
                          {{ department?.code || "N/A"}} - {{ department?.name || "N/A"}}
                        </li>
                      </ul>
                    </ng-container>
                    <ng-template #noDepartments>
                      <span class="text-muted">(No departments)</span>
                    </ng-template>
                  </div>
                </li>
              </ul>
            </ng-container>
            <ng-template #noSiteAccess>N/A</ng-template>
          </ng-container>

          <ng-container *ngIf="user?.roleName === 'SITEMANAGER'">
            <ng-container
              *ngIf="
                user?.siteAccess && user?.siteAccess?.sites;
                else noSiteAccess
              "
            >
              <ul class="list-unstyled mb-0">
                <li *ngFor="let site of user.siteAccess.sites">
                  <div>
                    <strong>{{ site?.code || "N/A"}}</strong>
                    <span class="text-muted">(ALL)</span>
                  </div>
                </li>
              </ul>
            </ng-container>
            <ng-template #noSiteAccess>N/A</ng-template>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <div>
    <app-pagination
      [totalItems]="totalItems"
      [pageSize]="pageSize"
      [currentPage]="currentPage"
      (pageChanged)="onPageChanged($event)"
    ></app-pagination>
  </div>
</div>
