import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../token-storage/token-storage.service';


@Injectable({
  providedIn: 'root'
})
export class SiteManagementService {
  private apiUrl = environment.backendApiUrl;
  private headers: HttpHeaders;
  data$: Observable<string> = of('');
  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
    const token = this.tokenStorage.getToken();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  // Get divisions
  getDivisions(): Observable<any> {
    const url = `${this.apiUrl}/division`;
    return this.http.get(url, { headers: this.headers });
  }

  // Create a new site
  createSite(siteData: any): Observable<any> {
    const url = `${this.apiUrl}/site`;
    return this.http.post(url, siteData, { headers: this.headers })
  }

  // Get an existing site
  getSite(code: string): Observable<any> {
    const url = `${this.apiUrl}/site/${code}`;
    return this.http.get(url, { headers: this.headers });
  }

  getSites(): Observable<any> {
    const url = `${this.apiUrl}/site`;
    return this.http.get(url, { headers: this.headers });
  }

  // Update an existing site
  updateSite(code: string, siteData: any): Observable<any> {
    const url = `${this.apiUrl}/site/${code}`;
    return this.http.put(url, siteData, { headers: this.headers });
  }

  // Create a par location for a site
  createParLocation(siteCode: string, parLocationData: any): Observable<any> {
    const url = `${this.apiUrl}/site/${siteCode}/department`;
    return this.http.post(url, parLocationData, { headers: this.headers });
  }
  // Get par locations for a site
  getDepartments(siteCode: string): Observable<any> {
    const url = `${this.apiUrl}/site/${siteCode}/departments`;
    return this.http.get(url, { headers: this.headers });
  }

  getDepartment(siteCode: string, departmentCode: string): Observable<any> {
    const url = `${this.apiUrl}/site/${siteCode}/department/${departmentCode}`;
    return this.http.get(url, { headers: this.headers });
  }

  // Update a par location for a site
  updateParLocation(siteCode: string, departmentCode: string, parLocationData: any): Observable<any> {
    const url = `${this.apiUrl}/site/${siteCode}/department/${departmentCode}`;
    return this.http.put(url, parLocationData, { headers: this.headers });
  }


  uploadParFile(siteCode: string, departmentCode: string, parFile: Blob): Observable<any> {
    const url = `${this.apiUrl}/par-file/${siteCode}/${departmentCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'text/csv'
    });

    // Send the Blob directly
    return this.http.post(url, parFile, { headers });
  }

  getParFile(siteCode: string, departmentCode: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/par-file/${siteCode}/${departmentCode}`;
    const headers = new HttpHeaders({
    });

    // Ensure that the full response is observed to check the status
    return this.http.get(url, { headers, observe: 'response' });
  }

  getParFileUploadSummary(siteCode: string, departmentCode: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/par-file/${siteCode}/${departmentCode}/summary`;
    const headers = new HttpHeaders({
    });

    // Ensure that the full response is observed to check the status
    return this.http.get(url, { headers, observe: 'response' });
  }

  getUsers(siteCode: string): Observable<any> {
    const userRole = this.tokenStorage.getRole();
    const url = `${this.apiUrl}/auth/user/site/${siteCode}/?userRole=${userRole}`;
    // const url = `${this.apiUrl}/auth/user/site/${siteCode}/?userRole=SUPERUSER`;
    return this.http.get(url, { headers: this.headers });
  }

  getUserProfile(username: string): Observable<any> {
    const url = `${this.apiUrl}/auth/user/${username}`;
    return this.http.get(url, { headers: this.headers });
  }

  checkUserProfile(username: string): Observable<any> {
    let userProfile = sessionStorage.getItem("UserProfile");
    if (userProfile) {
      this.data$ = of(JSON.parse(userProfile));
      return this.data$;
    }
    else {
      const url = `${this.apiUrl}/auth/user/${username}`;
      return this.http.get(url, { headers: this.headers });
    }
  }


  createUser(userData: any): Observable<any> {
    const url = `${this.apiUrl}/auth/user`;
    return this.http.post(url, userData, { headers: this.headers });
  }
  updateUser(username: string, userData: any): Observable<any> {
    const url = `${this.apiUrl}/auth/user/${username}`;
    return this.http.put(url, userData, { headers: this.headers });
  }

  getSitesByDivision(division: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/site/division/${division}`);
  }


  private logHeaders(method: string, url: string) {
    console.log(`${method} request to ${url}`);
    console.log('Headers:', this.headers);
  }
}
