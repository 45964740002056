import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-display',
  templateUrl: './field-display.component.html',
  styleUrl: './field-display.component.css'
})
export class FieldDisplayComponent {
  @Input() label: string = '';
  @Input() value: string = '';

}
