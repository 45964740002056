// form-data.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SiteSetupFormValue } from '../../model/site-setup-form.model';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  private formDataSource = new BehaviorSubject<SiteSetupFormValue | null>(null);
  formData$ = this.formDataSource.asObservable();

  private initialStatus: string | null = null; // To store initial site status
  private initialStatusSubject = new BehaviorSubject<string | null>(null);
initialStatus$ = this.initialStatusSubject.asObservable();

  setFormData(formData: SiteSetupFormValue, status: string) {
    this.formDataSource.next(formData);
    this.initialStatus = status;
    this.initialStatusSubject.next(status); // Notify observers
}

  clearFormData() {
    this.formDataSource.next(null);
    this.initialStatus = null; // Clear initial status
  }

  updateFormData(data: SiteSetupFormValue) {
    this.formDataSource.next(data);
  }

  getInitialStatus(): string | null {
    console.log('Initial status:', this.initialStatus);
    return this.initialStatus; // Provide a method to retrieve the initial status
  }
}

