import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  private _userClaims = new BehaviorSubject<any>(null);
  private tokenSubject = new BehaviorSubject<string | null>(null);
  private roleSubject = new BehaviorSubject<string | null>(null);

  public userClaims$ = this._userClaims.asObservable();
  public token$ = this.tokenSubject.asObservable();
  public role$: Observable<string | null> = this.roleSubject.asObservable();

  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  setUserClaims(claims: any) {
    this._userClaims.next(claims);
  }

  clearUserData() {
    this._userClaims.next(null);
  }

  setToken(token: string | null) {
    this.tokenSubject.next(token);
    if (this.isBrowser && token) {
      // Store the token in localStorage only if in a browser environment
      localStorage.setItem('token', token);
    }
  }

  getToken(): string | null {
    if (this.isBrowser) {
      const storedToken = localStorage.getItem('token');
      return storedToken || null; // Return null if the token is not found
    }
    return null; // Return null if not in a browser environment
  }

  clearToken() {
    this.tokenSubject.next(null);
    if (this.isBrowser) {
      localStorage.removeItem('token');
      localStorage.removeItem("isOktaLoggin");
      localStorage.removeItem("Username")
    }
  }
  setRole(role: string) {
    console.log('Setting user role:', role);
    this.roleSubject.next(role);
    if (this.isBrowser) {
      localStorage.setItem('userRole', role);
    }
  }

  getRole(): string | null {
    if (this.isBrowser) {
      return localStorage.getItem('userRole') || this.roleSubject.getValue();
    }
    return this.roleSubject.getValue();
  }
}
