<div class="mb-4">
  <label [for]="id" class="form-label d-flex align-items-center">
    {{ label }}
    <ng-template #tooltipTemplate>{{ tooltipContent }}</ng-template>
    <span
      class="material-icons-round ms-2 fs-6"
      [ngbPopover]="tooltipTemplate"
      [placement]="'top'"
      [autoClose]="'outside'"
      triggers="hover"
    >
      info_outline
    </span>
  </label>
  <input
    [formControl]="formControl"
    [placeholder]="placeholder"
    [id]="id"
    class="form-control"
    [class.is-invalid]="formControl.invalid && formControl.touched"
    (input)="onModelChange($event)"
    aria-describedby="textInputErrorMessage textInputErrorDescription"
  />
  <div
    *ngIf="formControl?.invalid && formControl?.touched"
    class="invalid-feedback d-flex flex-direction-column mt-2"
    id="textInputErrorMessage"
    role="alert"
    aria-live="polite"
  >
    <span class="material-icons-round icon-sm me-1">error_outline</span>
    <div *ngIf="formControl?.errors?.['required']">
      {{ validationMessages["required"] }}
    </div>
    <div *ngIf="formControl?.errors?.['pattern']">
      {{ validationMessages["pattern"] }}
    </div>
    <div *ngIf="formControl?.errors?.['email']">
      {{ validationMessages["email"] }}
    </div>
  </div>
</div>
