<div
  *ngIf="showToast"
  class="toast d-flex mb-4 show"
  [ngClass]="{
    'border-success': toastType === 'success',
    'border-danger': toastType === 'danger',
    'custom-styles': customStyles,
    'no-custom-styles': !customStyles
  }"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div class="toast-body">
    <span
      class="toast-icon material-icons-round text-white"
      [ngClass]="{
        'text-success': toastType === 'success',
        'text-danger': toastType === 'danger'
      }"
    >
      {{ toastType === "success" ? "check_circle" : "warning" }}
    </span>
    <ng-container *ngIf="toastType === 'success'">
      {{ message || "Operation completed successfully!" }}
    </ng-container>
    <ng-container *ngIf="toastType === 'danger'">
      {{ message || "An error occurred!" }}
    </ng-container>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeToast()"
  ></button>
</div>
