import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {
  @Input() toastType: 'success' | 'danger' = 'success';
  @Input() message: string | undefined;
  @Input() customStyles: boolean = false;
  @Input() showToast: boolean = false;
  @Input() duration: number = 3000;

  constructor() {}

  ngOnInit(): void {}

  closeToast() {
    this.showToast = false;
  }
}
