import { Component, Inject, OnInit } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth, CustomUserClaim } from '@okta/okta-auth-js';
import { filter, map, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent implements OnInit {
  public isAuthenticated$!: Observable<boolean>;
  public userName$!: Observable<string>;
  public userRole$!: Observable<CustomUserClaim | undefined>;

  // constructor(
  //   private router: Router,
  //   private oktaStateService: OktaAuthStateService,
  //   @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  // ) {}

  // public ngOnInit(): void {
  //   this.isAuthenticated$ = this.oktaStateService.authState$.pipe(
  //     filter((s: AuthState) => !!s),
  //     map((s: AuthState) => s.isAuthenticated ?? false)
  //   );

  //   this.userName$ = this.oktaStateService.authState$.pipe(
  //     filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
  //     map((authState: AuthState) => authState.idToken?.claims.name ?? '')
  //   );
  //   console.log(this.userName$);

  //   this.userRole$ = this.oktaStateService.authState$.pipe(
  //     filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
  //     map((authState: AuthState) => authState.idToken?.claims['app_role'] as CustomUserClaim | undefined)
  //   );
  //   console.log(this.userRole$);

  //   this.handleAuthentication();
  // }

  // private handleAuthentication(): void {
  //   const subscription = this.userRole$.subscribe({

  //     next: userRole => {
  //       if (userRole === 'super_user') {
  //         this.router.navigate(['/sites']);
  //       } else {
  //         console.log('User is not a super user');
  //       }
  //     },
  //     error: err => console.error('Error getting user role:', err)
  //   });
  // }

  constructor(private router: Router, private oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }
  public ngOnInit(): void {
    this.isAuthenticated$ = this.oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    if(this.isAuthenticated$)
      {
        this.router.navigate(['/sites'])
      }
  }
  
}