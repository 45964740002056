import { Component } from '@angular/core';

@Component({
  selector: 'app-link-two',
  templateUrl: './link-two.component.html',
  styleUrl: './link-two.component.css'
})
export class LinkTwoComponent {

}
