import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../services/api/report.service';
import { Department, Site } from '../model/report.model';
import { LoaderService } from '../services/spinner/loader.service';

import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, map, Observable, Subscription } from 'rxjs';
import { CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { AuthState } from '@okta/okta-auth-js';
import { DatePipe } from '@angular/common';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage/token-storage.service';
import { saveAs } from 'file-saver';
import { ReportTableComponent } from './report-table/report-table.component';
import { DataStorageService } from '../services/dataStorage/data-storage.service';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.css',
  providers: [DatePipe]
})
export class ReportsComponent implements OnInit {
  @ViewChild(ReportTableComponent) reportTableComponent!: ReportTableComponent;

  sites: Site[] = [];
  departments: Department[] = []
  siteName: string = '';
  departmentName: string = '';
  email: string = "";
  public isAuthenticated$!: Observable<boolean>;
  authSubscription: Subscription | undefined;
  claims: { claim: string; value: CustomUserClaim | CustomUserClaim[]; }[] | undefined;
  loading: boolean = false;
  reports: any[] = []
  isLoading!: boolean;
  isDownloading!: boolean;
  isSiteSelected: boolean = false;
  sitesLoading: boolean = false;
  isVisible: boolean = false;
  isRouteChange: boolean = false;
  constructor(
    private router: Router,
    private TokenStorageService: TokenStorageService,
    private reportService: ReportService,
    private oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private dataStorageService: DataStorageService,
    private datePipe: DatePipe
  ) {
    this.isRouteChange = true;
    setTimeout(() => { this.isRouteChange = false; }, 1000)

    //this.loading = true;
    // setTimeout(() => {
    //     this.loading = false;
    // }, 3000)

  }

  async ngOnInit() {


    // this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
    //   filter((s: AuthState) => !!s),
    //   map((s: AuthState) => s.isAuthenticated ?? false)
    // );
    // this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
    //   console.log('User authentication status:', isAuthenticated);
    //   //this.getFilters(this.email)
    //   if (isAuthenticated) {
    const token = await this.oktaAuth.getAccessToken() || null;
    const userClaims = await this.oktaAuth.token.getUserInfo();
    this.claims = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
    this.email = userClaims['email'] || "";

    let data = this.dataStorageService.getUserProfileData();
    if (data) {
      this.sites = data?.siteAccess?.sites;
    }
    else
      this.getFilters(this.email)
    //   } else {
    //     // Clear the stored token and user claims
    //     this.TokenStorageService.clearToken();
    //     this.TokenStorageService.clearUserData();
    //     this.router.navigate(['']);
    //   }
    // });


  }

  getFilters(email: string) {
    console.log("reports-email", email);
    //email = "noman.mohammed@labcorp.com"
    this.sitesLoading = true;
    this.reportService.getReportFilterList(email).subscribe({
      next: (response) => {
        console.log('report filter data:', response);
        this.sites = response?.siteAccess?.sites;
        this.sitesLoading = false;
        // response?.siteAccess?.sites?.map((site: Site) => {
        //   this.department = site?.departments?.map((department: Department) => {
        //     return department;
        //   })
        // });

      },
      error: (error) => {
        this.sitesLoading = false;
      }
    });
  }

  getDepartmentsBySite(event: any) {
    this.siteName = event.code;

    let site = this.sites?.find((site: Site) => site.code === this.siteName);
    this.departments = site?.departments || [];
    this.isSiteSelected = false;
    this.departmentName = "";
    this.reports = [];
  }

  convertToUTC(timestamp: string): string {
    const date = new Date(timestamp);
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss', 'UTC') ?? '';
  }


  getParFileData() {
    this.isSiteSelected = true;
    this.isLoading = true;
    // this.siteName = "CAH190";
    // this.departmentName = "1234";
    this.reports = [];
    this.reportService.getReportData(this.siteName, this.departmentName).subscribe({
      next: (response) => {
        this.isLoading = false;
        let sitename = this.siteName;
        let departmentname = this.departmentName;
        console.log('report table data:', response);
        if (response?.length > 0) {
          this.isVisible = true;
          this.reports = response.map((report: any) => {
            return {
              ...report,
              departmentname: departmentname,
              sitename: sitename,
              deltaIOH: report.quantity - report.parQuantity,
              modified: this.convertToUTC(report.modified)
            }
          });
        }
        else {
          this.isVisible = false;
          this.reports = []
        }
      },
      error: (error) => {
        this.isLoading = false;
      }
    });
  }


  onClear() {
    this.siteName = "";
    this.departmentName = "";
    this.departments = [];
    this.isSiteSelected = false;
  }
  downloadCsv(): void {
    this.isDownloading = true;
    setTimeout(() => {
      const headers = ['Status', 'Site ID', 'Department Code', 'Item ID', 'Description', 'Lot tracked', 'Optimal Qty', 'Ordering Qty', 'UOM', 'Updated Date', 'IOH Qty', 'Delta IOH'];
      this.isDownloading = false;
      const rows = this.reportTableComponent.requisitionData.map(item => [
        item.status?.toUpperCase(),
        this.reportTableComponent.siteName,
        this.reportTableComponent.departmentName,
        item.itemId,
        item.description,
        item.lotTracked,
        item.parQuantity,
        item.reorderQuantity,
        item.uom,
        item.modified,
        item.quantity,
        item.deltaIOH,
      ]);

      const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'report.csv');
    }, 2000);


  }
}
