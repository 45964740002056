import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SiteSetupFormValue } from '../model/site-setup-form.model';
import { LabSupplyDepartment } from '../model/lab-supply.model';
import { DataStorageService } from '../services/dataStorage/data-storage.service';
import { SiteManagementService } from '../services/api/site-management.service';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, map, Observable, Subscription } from 'rxjs';
import { CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { AuthState } from '@okta/okta-auth-js';
import { TokenStorageService } from '../services/token-storage/token-storage.service';
import { ListSitesUtilityService } from '../services/list-sites-utility/list-sites-utlity.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.css']
})
export class SitesComponent implements OnInit {

  siteData: SiteSetupFormValue | null = null;
  hasSites: boolean = false;
  sites: any[] = [];
  filteredSites: any[] = [];
  isAuthenticated$!: Observable<boolean>;
  private authSubscription: Subscription | null = null;
  searchText: string = '';
  sortProperty!: string
  sortDirection: string = 'asc';
  departmentData: { [siteCode: string]: LabSupplyDepartment[] } = {};
  loading!: boolean;
  userRole: string ='';

  constructor(
  private router: Router,
  private dataStorageService: DataStorageService,
  private siteManagementService: SiteManagementService,
  private oktaAuthStateService: OktaAuthStateService,
  private tokenStorage: TokenStorageService,
  private listSitesUtilityService: ListSitesUtilityService,
  @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
) {}

  ngOnInit() {
  this.siteData = this.dataStorageService.getSiteInformation();

  if (this.siteData) {
    this.hasSites = true;
  }

  this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
    filter((s: AuthState) => !!s),
    map((s: AuthState) => s.isAuthenticated ?? false)
  );

  this.authSubscription = this.isAuthenticated$.subscribe(async (isAuthenticated) => {
    console.log('User authentication status:', isAuthenticated);

    if (isAuthenticated) {
      await this.fetchUserProfile();
    } else {
      // User is not authenticated, perform a different action
      console.log('User is not authenticated');
      
      // Clear the stored token and user claims
      this.tokenStorage.clearToken();
      this.tokenStorage.clearUserData();
      this.router.navigate(['']);
    }
  });
}
async fetchUserProfile() {
  const userClaims = await this.oktaAuth.getUser();
  const username = userClaims.email;
  const token = await this.oktaAuth.getAccessToken() || null;
  if (token && username) {
    this.tokenStorage.setToken(token);
    this.loading=true;
    this.siteManagementService.getUserProfile(username).subscribe({
      next: (profile) => {
        // this.userProfile = profile;
        console.log('User profile:', profile);
        console.log('user rolename:', profile.roleName)
          this.userRole = profile.roleName;
        if (profile && profile?.siteAccess && profile?.siteAccess?.sites) {
          this.loading=false;
        this.sites = profile?.siteAccess?.sites;
        
        this.filteredSites = [...this.sites];
        this.hasSites = this.sites.length > 0;
        console.log('Sites:', this.sites);
        }
      },
      error: (error) => {
        this.loading=false;
        console.error('Error fetching user profile:', error);
      }
    });
  } else {
    console.error('No username available to fetch user profile');
  }
}
// async fetchSitesData() {
//   const token = await this.oktaAuth.getAccessToken() || null;

//   if (token) {
//     this.tokenStorage.setToken(token);
//     this.loading=true;

//     this.siteManagementService.getSites()
//       .subscribe({
//         next: (siteData) => {
//           console.log('Site data:', siteData);
//           this.sites = siteData;
//           this.loading=false;
//           this.filteredSites = siteData;
//           console.log('Sites:', this.sites);
//           console.log('Filtered Sites:', this.filteredSites);
//           this.hasSites = this.sites.length>0;

//           //fetch departments for site
//          for (const site of this.sites) {
//             this.fetchDepartmentsForSite(site.code);
//             site.activeDepartmentsCount = 0;
//           }
//         },
//         error: (error) => {
//           this.loading=false;
//           console.error('Error fetching site data:', error);
//         }
//       });
//   } else {
//     console.error('Failed to obtain Okta access token');
//   }
// }
// async fetchDepartmentsForSite(siteCode: string) {
//   const token = await this.oktaAuth.getAccessToken() || null;

//   if (token) {
//     this.tokenStorage.setToken(token);

//     this.siteManagementService.getDepartments(siteCode)
//       .subscribe({
//         next: (departmentData) => {
//           console.log('Department data:', departmentData);
//           // Update the departmentData array with the fetched data
//           this.departmentData[siteCode] = departmentData;
//           console.log('Department data:', this.departmentData);

//           //count the active departments for the current site
//           const activeDepartments = departmentData.filter((dept: { departmentCode: string, departmentName: string, modified: string, modifiedBy: string, siteCode: string, status: string }) => dept.status === 'ACTIVE');
//           console.log('Active departments:', activeDepartments);
//           const site = this.sites.find(s => s.code === siteCode);
//           if (site) {
//             site.activeDepartmentsCount = activeDepartments.length;
//             console.log('Site:', site);
//             console.log('Active departments count:', site.activeDepartmentsCount);
//           }
//         },
//         error: (error) => {
//           if(error.status === 404)
//             {
//               this.departmentData[siteCode] = [];
//               console.log(`No departments found for site ${siteCode}`);
//             }
//             else{
//               console.error('Error fetching department data:', error);
//             }
//         }
//       });
//   } else {
//     console.error('Failed to obtain Okta access token');
//   }
// }
 onSearchTextChange(searchText: string) {
  console.log('Search text changed:', searchText);
    this.searchText = searchText;
    // this.filterSites();
    this.filteredSites = this.listSitesUtilityService.filterData(this.sites, this.searchText)
  }

  // Filter sites based on search text
//   filterSites() {
//   console.log('Filtering with search text:', this.searchText);
//   console.log('Original sites:', this.sites);

//   if (this.searchText.trim()) {
//   const searchTextLower = this.searchText.toLowerCase();
//   this.filteredSites = this.sites.filter(site =>
//     site.code.toLowerCase().includes(searchTextLower) ||
//     site.name.toLowerCase().includes(searchTextLower)
//   );
//   console.log('Filtered sites:', this.filteredSites);
// } else {
//   this.filteredSites = [...this.sites];
// }
//   this.sortSites(); 
// }
onSortChange(event: { property: string; direction: string }) {
  this.sortProperty = event.property;
  this.sortDirection = event.direction;
  // this.sortSites(); 
  this.filteredSites = this.listSitesUtilityService.sortData(this.sites, this.sortProperty, this.sortDirection);
}
canCreateSite(): boolean {
  const allowedRoles = ['SUPERUSER', 'DIRECTOR']; 
  return allowedRoles.includes(this.userRole);
}

  sortSites() {
  if (!this.sortProperty) return; // Avoid sorting if property is not set
  this.filteredSites.sort((a, b) => {
    const valueA = a[this.sortProperty];
    const valueB = b[this.sortProperty];
    
    // Sorting logic to handle ascending and descending directions
    if (this.sortDirection === 'asc') {
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    } else {
      return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
    }
  });
}



ngOnDestroy() {
  // Unsubscribe from the observable when the component is destroyed
  if (this.authSubscription) {
    this.authSubscription.unsubscribe();
  }
}


  navigateToCreateSite() {
    this.router.navigate(['sites/site-setup']);
  }
}