import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Site } from '../../model/site.model';
import { Router } from '@angular/router';
import { ListSitesUtilityService } from '../../services/list-sites-utility/list-sites-utlity.service';

@Component({
  selector: 'app-site-table',
  templateUrl: './site-table.component.html',
  styleUrls: ['./site-table.component.css']
})
export class SiteTableComponent implements OnInit, OnChanges {
  activeDepartmentsCount = 0; // Property to store the count of active departments
  sitesDataObservable$!: Observable<any>;
  @Input() sites: Site[] = [];
  @Input() sortProperty: string = '';
  @Input() sortDirection: string = 'asc';
  @Output() sortChange = new EventEmitter<{ property: string; direction: string }>();
  totalItems = 0;
  pageSize = 12;
  currentPage = 1;
  sitesForPagination: any[] = [];
  sortedSites: any[] = [];
  

  property: string = '';
  direction: string = '';

  constructor(
    private router: Router,
    private listSitesUtilityService: ListSitesUtilityService
  ) {}

  ngOnInit() {
    this.updatePagination();
  }

  ngOnChanges(changes: SimpleChanges) {
  if (changes['sites'] && changes['sites'].currentValue) {
    console.log('Sites updated in SiteTableComponent:', this.sites);
    this.totalItems = this.sites.length;
    this.updatePagination();
  }

  if (changes['sortProperty'] || changes['sortDirection']) {
    this.sortSites();
  }
}

// sortSites() {
//   if (!this.sortProperty) {
//     this.sitesForPagination = [...this.sites];
//     return;
//   }

//    this.sitesForPagination = [...this.sites].sort((a, b) => {
//     const valueA = this.sortProperty === 'id' || this.sortProperty === 'activeDepartmentsCount' || this.sortProperty === 'countFrequency' ? a[this.sortProperty as keyof Site] : (a[this.sortProperty as keyof Site] as string).toLowerCase();
//     const valueB = this.sortProperty === 'id' || this.sortProperty === 'activeDepartmentsCount' || this.sortProperty === 'countFrequency' ? b[this.sortProperty as keyof Site] : (b[this.sortProperty as keyof Site] as string).toLowerCase();
//     const res = this.compare(valueA, valueB);
//     return this.sortDirection === 'asc' ? res : -res;
//   });
//   this.updatePagination();
// }

sortSites() {
    this.sitesForPagination = this.listSitesUtilityService.sortData(this.sites, this.sortProperty, this.sortDirection);
    this.updatePagination();
  }
onSort(event: { property: string }) {
  const { property } = event;

  // Toggle sort direction if the same property is clicked, else reset to ascending
  if (this.sortProperty === property) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  } else {
    this.sortDirection = 'asc'; // Default to ascending if a new property is clicked
  }

  this.sortProperty = property;
  this.sortChange.emit({ property, direction: this.sortDirection });
  this.sortSites(); // Apply sorting when the sort changes
}


  onPageChanged(page: number) {
    this.currentPage = page;
    this.updatePagination();
  }
  updatePagination() {
    this.sitesForPagination = this.listSitesUtilityService.paginateData(this.sites, this.currentPage, this.pageSize);
  }

// onSort(event: { property: string; direction: string }) {
//     const { property, direction } = event;
//     this.direction = event.direction;
//     this.property = event.property;
//     this.sortChange.emit({ property, direction });

//   }

// Helper method to compare values
// compare(v1: string | number, v2: string | number): number {
//   return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
// }
  // onPageChanged(page: number) {
  //   this.currentPage = page;
  //   this.updatePagination();
  // }

  // updatePagination() {
  //   const start = (this.currentPage - 1) * this.pageSize;
  //   const end = start + this.pageSize;
  //   this.sitesForPagination = this.sites.slice(start, end);
  // }

  navigateToSiteSetupPreview(siteCode: string) {
    this.router.navigate(['sites/site-setup-preview', siteCode]);
    console.log(`Navigating to site details for site ID: ${siteCode}`);
  }
}
