import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sortdirection',
  templateUrl: './sortdirection.component.html',
  styleUrl: './sortdirection.component.css'
})
export class SortdirectionComponent {
  @Input() property: string = ''
  @Input() direction: string = ''

}
