import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteSetupService {
  // private apiUrl = 'https://api.example.com/site-setup'; //replace with api url
  // constructor(private http: HttpClient) {}

  // submitSiteSetup(data: any): Observable<any> {
  //   return this.http.post(this.apiUrl, data);
  // }
}
