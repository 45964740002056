import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormOptionsService {

  divisionOptions = [
    { value: 'Atlantic', text: 'Atlantic' },
    { value: 'Mid America', text: 'Mid America'},
    { value: 'North Central', text: 'North Central'},
    { value: 'Northeast', text: 'Northeast' },
    { value: 'Southeast', text: 'Southeast'},
    { value: 'West', text: 'West' },
    { value: 'HSO', text: 'HSO'}
  ]

  requesterOptions = [
    { value: 'adamsd', text: 'adamsd' },
    { value: '1', text: 'One' },
    { value: '2', text: 'Two' },
    { value: '3', text: 'Three' }
  ];

  statusOptions = [
    { value: 'Active', text: 'Active' },
    { value: 'Inactive', text: 'Inactive' }
  ];

  recountFrequency = [
    { value: '30', text: '30 Days' },
    { value: '60', text: '60 Days' },
    { value: '90', text: '90 Days' }
  ];

  role = [
    { value: 'SUPERUSER', text: 'Super User' },
    { value: 'USER', text: 'Site User' },
    { value: 'DIRECTOR', text: 'Division Manager'},
    { value: 'SITEMANAGER', text: 'Site Manager'}
  ]
   superUserRoleOptions= [
    { value: 'SUPERUSER', text: 'Super User' },
    { value: 'USER', text: 'Site User' },
    { value: 'DIRECTOR', text: 'Division Manager'},
    { value: 'SITEMANAGER', text: 'Site Manager'}
  ]
  divisionManagerRoleOptions= [
    { value: 'USER', text: 'Site User' },
    { value: 'SITEMANAGER', text: 'Site Manager'}
  ]
  siteManagerRoleOptions= [
    { value: 'USER', text: 'Site User' },
  ]

  siteOptions = [
    { value: 'site1', text: 'Site One' },
    { value: 'site2', text: 'Site Two' },
    { value: 'site3', text: 'Site Three' }
  ];

  departmentOptions = [
    { value: 'department1', text: 'Department One' },
    { value: 'department2', text: 'Department Two' },
    { value: 'department3', text: 'Department Three' }
  ];
}
