import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.css'
})
export class PaginationComponent implements OnInit, OnChanges {
  totalPages: number[] = [];
  @Input() totalItems: number = 0;
  @Input() pageSize: number = 12;
  @Input() currentPage: number = 1;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
    this.calculatePages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalItems'] || changes['pageSize']) {
      this.calculatePages();
    }
  }

  calculatePages(): void {
    let pages = Math.ceil(this.totalItems / this.pageSize);
    this.totalPages = Array(pages).fill(0).map((_, i) => i + 1);
  }

  gotoPage(page: number) {
    this.pageChanged.emit(page);
  }
}