import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.css'
})
export class SearchComponent {
  @Input() icon: string = 'search'; 
  @Input() placeholder: string = 'Search'; 
  @Input() label: string = 'Search Input';
  @Input() inputId: string = 'search-input'; 
  @Input() value: string = ''; 
  @Output() valueChange = new EventEmitter<string>(); // Output event when input changes

  onInputChange(event: Event): void {
  const target = event.target as HTMLInputElement;
  this.valueChange.emit(target.value);
}


}
