import { Injectable } from '@angular/core';
import { User } from '../../model/user.model';
import { UserStatus } from '../../reusable/user-status.enum';

@Injectable({
  providedIn: 'root'
})

export class ListUserUtilService {
  
  constructor() {}

  filterUsers(users: User[], searchText: string): User[] {
    if (!searchText.trim()) return users;

    const searchTextLower = searchText.toLowerCase();
    console.log(searchTextLower);
    return users.filter(user =>
      user.email?.toLowerCase().includes(searchTextLower) ||
      user.firstName?.toLowerCase().includes(searchTextLower) ||
      user.lastName?.toLowerCase().includes(searchTextLower)
    );
  }

  sortUsers(users: User[], sortProperty: string, sortDirection: string): User[] {
    const divisionOrder = ['Atlantic', 'Northeast', 'West'];
  
    function convertToString(value: any): string {
      if (value === null) {
        return '';
      } else if (typeof value === 'string') {
        return value;
      } else if (typeof value === 'number') {
        return value.toString();
      } else if (typeof value === 'object' && value !== null) {
        return value.toString();
      } else {
        return '';
      }
    }
  
    function getSiteAccessValue(user: User): string {
      if (user.roleName === 'SUPERUSER' || user.roleName === 'DIRECTOR') {
        return 'ALL'; // SUPERUSER or DIRECTOR has access to all sites
      }
  
      if (user.roleName === 'USER' && user.siteAccess?.sites?.length) {
        const firstSite = user.siteAccess.sites[0];
        if (firstSite) {
          const siteCode = firstSite.code || 'N/A';
          const departmentCodes = firstSite.departments?.map(dept => dept?.code || 'N/A').join(', ') || '(No departments)';
          return `${siteCode} - ${departmentCodes}`;
        }
      }
  
      if (user.roleName === 'SITEMANAGER' && user.siteAccess?.sites?.length) {
        const firstSite = user.siteAccess.sites[0];
        return firstSite ? `${firstSite.code || 'N/A'} (ALL)` : 'N/A';
      }
  
      return 'N/A'; // Default if no valid access
    }
  
    return users.sort((a, b) => {
      let valueA: string | number | UserStatus | null | undefined = undefined;
      let valueB: string | number | UserStatus | null | undefined = undefined;
  
      switch (sortProperty) {
        case 'userType':
        case 'username':
        case 'firstName':
        case 'lastName':
        case 'email':
          valueA = (a[sortProperty as keyof User] || '').toString().toLowerCase();
          valueB = (b[sortProperty as keyof User] || '').toString().toLowerCase();
          break;
  
        case 'status':
          valueA = a[sortProperty as keyof User] as UserStatus || null;
          valueB = b[sortProperty as keyof User] as UserStatus || null;
          break;
  
        case 'division':
          const isDivisionAllA = a.roleName === 'SUPERUSER' || (a.siteAccess?.division || a.division) === 'ALL';
          const isDivisionAllB = b.roleName === 'SUPERUSER' || (b.siteAccess?.division || b.division) === 'ALL';
          valueA = (isDivisionAllA ? 'ALL' : a.siteAccess?.division || a.division || '').toString().toLowerCase();
          valueB = (isDivisionAllB ? 'ALL' : b.siteAccess?.division || b.division || '').toString().toLowerCase();
          break;
  
        case 'roleName':
          valueA = (a[sortProperty as keyof User] || '' || 'N/A')?.toString().toUpperCase();
          valueB = (b[sortProperty as keyof User] || '' || 'N/A')?.toString().toUpperCase();
          break;
  
        case 'siteAccess':  // New case for sorting by Site/Department Access
          valueA = getSiteAccessValue(a).toLowerCase();
          valueB = getSiteAccessValue(b).toLowerCase();
          break;
  
        default:
          break;
      }
  
      if (valueA === null && valueB === null) {
        return 0;
      } else if (valueA === null) {
        return sortDirection === 'asc' ? 1 : -1;
      } else if (valueB === null) {
        return sortDirection === 'asc' ? -1 : 1;
      } else if (valueA === undefined && valueB === undefined) {
        return 0;
      } else if (valueA === undefined) {
        return sortDirection === 'asc' ? 1 : -1;
      } else if (valueB === undefined) {
        return sortDirection === 'asc' ? -1 : 1;
      } else {
        const valueAString = convertToString(valueA).toLowerCase();
        const valueBString = convertToString(valueB).toLowerCase();
  
        if (sortProperty === 'division') {
          if (valueAString === 'all' && valueBString !== 'all') {
            return sortDirection === 'asc' ? -1 : 1; // 'ALL' comes first
          } else if (valueAString !== 'all' && valueBString === 'all') {
            return sortDirection === 'asc' ? 1 : -1; // 'ALL' comes first
          } else {
            const indexA = divisionOrder.indexOf(valueAString);
            const indexB = divisionOrder.indexOf(valueBString);
  
            if (indexA === -1 && indexB === -1) {
              const comparison = valueAString < valueBString ? -1 : valueAString > valueBString ? 1 : 0;
              return sortDirection === 'asc' ? comparison : -comparison;
            } else if (indexA === -1) {
              return sortDirection === 'asc' ? 1 : -1; // Unknown division comes after known divisions
            } else if (indexB === -1) {
              return sortDirection === 'asc' ? -1 : 1; // Unknown division comes before known divisions
            } else {
              const divisionComparison = indexA - indexB;
              return sortDirection === 'asc' ? divisionComparison : -divisionComparison;
            }
          }
        } else {
          const comparison = valueAString < valueBString ? -1 : valueAString > valueBString ? 1 : 0;
          return sortDirection === 'asc' ? comparison : -comparison;
        }
      }
    });
  }
  

  // sortUsers(data: any[], sortProperty: string, sortDirection: string): any[] {
  //   if (!sortProperty) {
  //     return data;
  //   }

  //   return [...data].sort((a, b) => {
  //     const valueA = this.getPropertyValue(a, sortProperty);
  //     const valueB = this.getPropertyValue(b, sortProperty);
  //     const res = this.compare(valueA, valueB);
  //     return sortDirection === 'asc' ? res : -res;
  //   });
  // }
  paginateUsers(users: User[], pageSize: number, currentPage: number): User[] {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return users.slice(start, end);
  }
}
