import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ValidationService } from '../validation/validation.service';
import { FileValidationService } from '../file-validation/file-validation.service';

@Injectable({
  providedIn: 'root'
})
export class FormInitializerService {

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
    private fileValidationService: FileValidationService
  ) { }

  initializeSiteSetupForm(): FormGroup{
    return this.fb.group({
      division: ['', [Validators.required]],
      siteId: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.siteId), Validators.maxLength(6)]],
      siteName: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.siteName), Validators.maxLength(32)]],
      requesterId: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.requesterId)]],
      recountFrequency: ['', [Validators.required]],
      businessUnit: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.businessUnit), Validators.maxLength(5)]],
      glUnit: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.glUnit), Validators.maxLength(5)]],
      status: ['', [Validators.required]]
    });
  
}

  initializeLabSetupForm(): FormGroup {
    return this.fb.group({
  departmentName: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.departmentName)]],
  departmentCode: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.departmentCode)]],
  status: ['', [Validators.required]],
  shipToCode: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.shipToCode)]],
  location: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.location)]],
  parFile: [null, [this.optionalFileValidator()]] // Use a custom validator that checks if the file exists before validating
});
  }

  initializeUserForm(): FormGroup {
    return this.fb.group({
 email: ['', [Validators.required,Validators.email]],
  roleName: ['', [Validators.required]],
  division: ['', [Validators.required]],
  firstName: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.firstName)]],
  lastName: ['', [Validators.required, Validators.pattern(this.validationService.validationPatterns.lastName)]],
  // site: ['', [Validators.required]],
  // department: ['', Validators.required],
  site: [[], Validators.required],  
  department: [[], Validators.required], 
  // siteAccess: this.fb.group({
  //   sites: this.fb.array([]),
  //   departments: this.fb.array([])
  // }),
  status: ['', [Validators.required]]
});
  }

  optionalFileValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      // If there's no file, treat the control as valid
      return null;
    }
    // Run your existing validators only if a file is present
    const fileTypeError = this.fileValidationService.validateFileType(control);
    const fileSizeError = this.fileValidationService.validateFileSize(control);

    // Combine errors if any
    return fileTypeError || fileSizeError || null;
  };
}
}
