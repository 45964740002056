import { Component, Input, forwardRef, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDropdownComponent),
      multi: true
    }
  ]
})
export class MultiSelectDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() controlName!: string;
  @Input() id!: string;
  @Input() label!: string;
  // @Input() options!: Array<{ value: any, text: string }>;
  @Input() placeholder!: string;
  @Input() tooltipContent!: string;
  @Input() formControl!: FormControl;
  @Input() validationMessages: { [key: string]: string } = {};
  @Output() valueChange = new EventEmitter<any>();
  @Input() groupBy?: string;
  @Output() selectionChanged = new EventEmitter<{ value: string; text: string; group?: string; siteCode?: string; }[]>();
  @Input() options!: Array<{ value: any; text: string; group?: string; siteCode?: string; disabled?: boolean }>;
  @Input() loading!: boolean;
  onChange: (value: any[]) => void = () => { };
  onTouched: () => void = () => { };

  ngOnInit(): void {
    // Initialize form control value if needed
    if (!this.formControl.value) {
      this.formControl.setValue([]);
    }

  }
  //   ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['options']) {
  //     console.log('Options changed:', this.options);
  //     // If the options have changed, we might need to update the selected values
  //     this.updateSelectedValues();
  //   }
  // }
  private updateSelectedValues(): void {
    if (this.formControl && this.options) {
      const currentValue = this.formControl.value || [];
      const validValues = this.options.map(option => option.value);
      const updatedValue = currentValue.filter((value: any) => validValues.includes(value));

      if (updatedValue.length !== currentValue.length) {
        this.formControl.setValue(updatedValue);
        this.valueChange.emit(updatedValue);
        this.selectionChanged.emit(updatedValue);
      }
    }
  }

  onCheckboxChange(value: any, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const checked = inputElement.checked;

    const currentValue = this.formControl.value || [];
    if (inputElement.disabled) {
      return; // Do nothing if the option is disabled
    }

    if (checked) {
      // Add the selected value
      this.formControl.setValue([...currentValue, value]);
    } else {
      // Remove the unselected value
      this.formControl.setValue(currentValue.filter((v: any) => v !== value));
    }

    // Emit the change if necessary
    this.valueChange.emit(this.formControl.value);

    // Emit the selectionChanged event with the updated selectedOptions
    this.selectionChanged.emit(this.formControl.value);
    console.log('Selection changed:', this.formControl.value);

  }

  // onCheckboxChange(value: { value: string, text: string, group?: string, siteCode?: string }, event: Event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   const checked = inputElement.checked;

  //   const currentValue = this.formControl.value || [];

  //   if (checked) {
  //     // Add the selected value with siteCode
  //     const selectedOption = { value: value.value, siteCode: value.group };
  //     this.formControl.setValue([...currentValue, selectedOption]);
  //   } else {
  //     // Remove the unselected value
  //     this.formControl.setValue(currentValue.filter((v: { value: string; }) => v.value !== value.value));
  //   }

  //   // Emit the change if necessary
  //   this.valueChange.emit(this.formControl.value);

  //   // Emit the selectionChanged event with the updated selectedOptions
  //   this.selectionChanged.emit(this.formControl.value);
  //   console.log('Selection changed:', this.formControl.value);
  // }



  writeValue(value: any[]): void {
    if (value !== this.formControl.value) {
      this.formControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: any[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onValueChange(value: any[]) {
    this.onChange(value);
    this.onTouched();
    this.updateSelectedValues();
  }
}