<div>
    <div class="table-responsive col-12">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Line </th>
                    <th>ItemID                    </th>
                    <th>SupplierItemID </th>
                    <th>OrderID </th>
                    <th>Description</th>
                    <th>Lot Tracked </th>
                    <th>UoM </th>
                    <th>Items Received</th>
                    <th>Item Quantity </th>


                </tr>
            </thead>
            <tbody>
                @for (item of lineItemData; track item) {

                <tr>
                    <td>{{ item?.line }}</td>
                    <td>{{ item?.itemId }}</td>

                    <td>{{ item?.supplierItemId }}</td>
                    <td>{{ item?.orderId }}</td>
                    <td>{{ item?.description }}</td>
                    <td>{{ item?.lotTracked }}</td>
                    <td>{{ item?.uom }}</td>
                    <td>{{ item?.itemReceived }}</td>
                    <td>{{ item?.itemQuantity }}</td>
                </tr>
                }
            </tbody>
        </table>


    </div>


</div>