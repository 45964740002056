import { Component } from '@angular/core';
import { GlobalConstants } from '../../GlobalConstants'

@Component({
  selector: 'app-layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrl: './layout-footer.component.css',
})
export class LayoutFooterComponent {
  appVersion = GlobalConstants.appInfo.appVersion;
  appTitle = GlobalConstants.appInfo.footer_appTitle;
  appYear: number = new Date().getFullYear();
  // ImagePath: string;
  constructor(){
    // this.ImagePath = "assets/images/logo-hsm-white.png";
  }
}