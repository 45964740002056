import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Report } from '../../model/report.model';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrl: './report-table.component.css'
})
export class ReportTableComponent implements OnChanges {

  property: string = '';
  direction: string = '';

  totalItems = 0;
  pageSize = 15;
  currentPage = 1;
  // requisationByPagination: Report[] = [];
  // @Input() requisitionData: Report[] = [];
  requisationByPagination: any[] = [];
  @Input() requisitionData: any[] = [];
  @Input() siteName: string = '';
  @Input() departmentName: string = '';
  constructor() {

  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['requisitionData']) {
      this.requisitionData = changes['requisitionData'].currentValue;
      this.totalItems = this.requisitionData?.length;
      this.updatePagination();
    }
  }

  onPageChanged(page: number) {
    this.currentPage = page;
    this.updatePagination()
  }


  updatePagination() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.requisationByPagination = this.requisitionData.slice(start, end);
  }


  onSort(event: { property: string; direction: string }) {
    const { property, direction } = event;
    this.direction = event.direction;
    this.property = event.property;
    this.requisitionData = this.sortData(this.requisitionData, this.property, this.direction);
    console.log("requisitionData", this.requisitionData)
    this.updatePagination()
  }

  compareValues(valueA: any, valueB: any, isDesc: boolean): number {

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.localeCompare(valueB) * (isDesc ? -1 : 1);
    }
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return (valueA - valueB) * (isDesc ? -1 : 1);
    }

    if (valueA instanceof Date && valueB instanceof Date) {
      return (valueA.getTime() - valueB.getTime()) * (isDesc ? -1 : 1);
    }

    return 0;
  }


  sortData(data: any[], sortProperty: string, sortDirection: string): any[] {
    if (!sortProperty) {
      return data;
    }
    let sortedData = data.sort((a, b) => {
      const valueA = this.getPropertyValue(a, sortProperty);
      const valueB = this.getPropertyValue(b, sortProperty);

      return this.compareValues(valueA, valueB, sortDirection === "asc" ? false : true);
    });
    return sortedData
    
  }

  getPropertyValue(item: any, property: string): any {
    if (property === 'parQuantity' || property === 'reorderQuantity' || property === 'quantity') {
      return item[property];
    } else {
      return (item[property] as string)
    }
  }

  compare(v1: string | number, v2: string | number): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

}
