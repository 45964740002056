import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-layout-template',
  templateUrl: './layout-template.component.html',
  styleUrl: './layout-template.component.css',
  host: {'class': 'd-flex flex-column vh-100'} //Styles needed for layout
})
export class LayoutTemplateComponent implements OnInit {
  showHeader: boolean = true;
  showFooter: boolean = true;
  constructor(private route: ActivatedRoute, private router: Router) { }
  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.showHeader = data['showHeader'] !== false;
    });
  }

}