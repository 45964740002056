import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-lineitem-table',
  templateUrl: './lineitem-table.component.html',
  styleUrl: './lineitem-table.component.css'
})
export class LineitemTableComponent implements OnChanges  {
  @Input() lineItemData: any[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['lineItemData']) {
      this.lineItemData = changes['lineItemData'].currentValue;
     
    }
  }

}
