import { Component } from '@angular/core';
import { IdlelogoutService } from './services/IdleService/idlelogout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = ''; // Empty title or change it to something else

  constructor(private idleLogoutService: IdlelogoutService) {}

  ngOnInit(): void {
    console.log("test")
    this.idleLogoutService.startMonitoring();
  }
}
