import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.css'
})
export class ButtonComponent implements OnChanges {
  @Input() label: string = 'Submit';
  @Input() customClass!: string;
  @Input() customStyles!: { [key: string]: string };
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;
  constructor() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isLoading']) {
      this.isLoading = changes['isLoading'].currentValue
    }
  }
}
